import produce from 'immer';
import { endpoints } from 'api';
import { SiteCollection } from 'models';
// import siteDevicesResponse from 'api/test/site-devices'

const initialState = {
  collection: new SiteCollection(),
  isFetching: false,
  fetchError: null,
  siteTimezones: {},
};

export const MODULE_NAME = 'sites';

const RESET = 'SITES_RESET';
const SEARCH_CLEARED = 'SITES_SEARCH_CLEARED';
const REQUESTED = 'SITES_REQUESTED';
const FETCHED = 'SITES_FETCHED';
const UPDATED_MODEL = 'SITES_UPDATED_MODEL';
const FAILED = 'SITES_FAILED';
const SET_TIMEZONE = 'SITE_SET_TIMEZONE';

// receive can take FETCHED, REPLACED actions
const receive = (action, data) => ({ type: action, payload: data });
const reset = () => ({ type: RESET });
const clearSearch = () => ({ type: SEARCH_CLEARED });
const request = () => ({ type: REQUESTED });
// const updateModel = model => ({ type: UPDATED_MODEL, payload: model })
const fail = (error) => ({ type: FAILED, payload: error });
const setTimezone = (siteId, timezone) => ({ type: SET_TIMEZONE, payload: { siteId, timezone } });

/**
 * @see api/test/site-devices
 *
 */
const fetchAll = (apiClient, queryType, query) => {
  return (dispatch, getState) => {
    dispatch(request());

    let data = {
      // without this flag, endpoint will return flat array of site ids
      with_context: true,
      // without this flag, endpoint will NOT return any "reporting: 'icm'" systems
      reporting: 'icm,beacon,unknown',
    };

    if (query) {
      data = Object.assign({ [queryType]: query }, data);
    }

    apiClient
      .get({
        path: endpoints.sites,
        data,
      })
      .then((response) => {
        dispatch(receive(FETCHED, response));
      })
      .catch((error) => {
        dispatch(fail(error.message || error));
      });
  };
};

const ACTION_HANDLERS = {
  [RESET]: (state, action) => initialState,
  [SEARCH_CLEARED]: (state, action) => ({
    ...state,
    collection: new SiteCollection(),
    isFetching: false,
    fetchError: null,
  }),
  [REQUESTED]: (state, action) => ({
    ...state,
    isFetching: true,
    fetchError: null,
  }),
  [FETCHED]: (state, action) => ({
    ...state,
    collection: new SiteCollection(action.payload),
    isFetching: false,
    fetchError: null,
  }),
  [UPDATED_MODEL]: (state, action) => ({
    ...state,
    collection: state.collection.updateOrAddModel(action.payload),
  }),
  [FAILED]: (state, action) => ({
    ...state,
    fetchError: action.payload,
    isFetching: false,
  }),
  [SET_TIMEZONE]: produce((state, { payload }) => {
    state.siteTimezones[payload.siteId] = payload.timezone;
  }),
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export const actions = {
  reset,
  clearSearch,
  fetchAll,
  setTimezone,
};
