import { ComponentType } from 'react';
import { colors, SvgIconProps } from '@material-ui/core';
import {
  PURPLE_INDEX,
  BLUE_INDEX,
  GREEN_INDEX,
  RED_INDEX,
  ORANGE_INDEX,
  YELLOW_INDEX,
} from 'theme';
import BeaconIcon from '@material-ui/icons/SettingsInputAntenna';
import InverterIcon from '@material-ui/icons/SwapCalls';
import ICMIcon from '@material-ui/icons/WebAsset';
import RGMIcon from '@material-ui/icons/Dock';
import BatteryIcon from '@material-ui/icons/BatteryFull';
import PVLIcon from '@material-ui/icons/DeviceHub';
import GeneratorIcon from '@material-ui/icons/Power';
import UnknownIcon from '@material-ui/icons/HelpOutline';
import { DeviceType } from 'types/Device';
import { FirmwareType } from 'types/Firmware';

interface Color {
  [value: number]: string;
}

interface Definition {
  name: string;
  icon: ComponentType<SvgIconProps>;
  firmwareTypes: FirmwareType[];
  color: Color;
  colorIndex: Color[];
}

const DeviceTypeDefinitions: Record<DeviceType, Definition> = {
  [DeviceType.BEACON]: {
    name: 'Beacon',
    icon: BeaconIcon,
    firmwareTypes: [],
    color: colors.purple['A700'],
    colorIndex: PURPLE_INDEX,
  },
  [DeviceType.INVERTER]: {
    name: 'Inverter',
    icon: InverterIcon,
    firmwareTypes: [FirmwareType.URC],
    color: colors.lightBlue['A700'],
    colorIndex: BLUE_INDEX,
  },
  [DeviceType.ICM]: {
    name: 'ICM',
    icon: ICMIcon,
    firmwareTypes: [FirmwareType.ICM],
    color: colors.green['A700'],
    colorIndex: GREEN_INDEX,
  },
  [DeviceType.BATTERY]: {
    name: 'Battery',
    icon: BatteryIcon,
    firmwareTypes: [
      FirmwareType.BHC,
      FirmwareType.DCB,
      FirmwareType.DLG,
      FirmwareType.ZM2,
      FirmwareType.SUN,
      FirmwareType.BAK,
    ],
    color: colors.pink['A400'],
    colorIndex: RED_INDEX,
  },
  [DeviceType.PVL]: {
    name: 'PVL',
    icon: PVLIcon,
    firmwareTypes: [FirmwareType.PVL],
    color: colors.orange['A400'],
    colorIndex: ORANGE_INDEX,
  },
  [DeviceType.RGM]: {
    name: 'RGM',
    icon: RGMIcon,
    firmwareTypes: [],
    color: colors.lime[400],
    colorIndex: GREEN_INDEX,
  },
  [DeviceType.GENERATOR]: {
    name: 'Generator',
    icon: GeneratorIcon,
    firmwareTypes: [FirmwareType.END, FirmwareType.EGC, FirmwareType.EDC, FirmwareType.EEC],
    color: colors.yellow['700'],
    colorIndex: YELLOW_INDEX,
  },
  [DeviceType.UNKNOWN]: {
    name: 'Unknown device',
    icon: UnknownIcon,
    firmwareTypes: [],
    color: colors.blueGrey[600],
    colorIndex: BLUE_INDEX,
  },
};

export default DeviceTypeDefinitions;
