import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  IconButton,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RemoveIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { KEY_ENTER } from 'utils/keyboard';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 480,
    flexBasis: 480,
  },
  paper: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    flexGrow: 1,
  },
}));

export const SearchInput = ({
  className,
  defaultInput,
  inputLabel,
  helperText,
  onSearch,
  ...rest
}) => {
  const classes = useStyles();
  const [searchInput, setSearchInput] = React.useState(defaultInput);
  const [hasSearchedInput, setHasSearchedInput] = React.useState(Boolean(defaultInput));

  function handleKeyPress(event) {
    if (event.key !== KEY_ENTER) {
      return;
    }

    onSearch(searchInput);
    if (searchInput) {
      setHasSearchedInput(true);
    }
  }

  function handleClearSearch() {
    setSearchInput('');
    onSearch('');
    setHasSearchedInput(false);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Paper className={classes.paper} elevation={1}>
        <FormControl className={classes.formControl}>
          <Input
            placeholder={inputLabel}
            value={searchInput}
            disableUnderline
            onChange={event => {
              setSearchInput(event.target.value);
            }}
            onKeyPress={handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                {!hasSearchedInput ? (
                  <IconButton
                    aria-label={inputLabel}
                    disabled={!searchInput}
                    onKeyPress={event => {
                      event.preventDefault();
                      event.target.blur();
                      handleKeyPress(event);
                    }}
                    onClick={() => {
                      onSearch(searchInput);
                      setHasSearchedInput(true);
                    }}
                  >
                    <SearchIcon color={searchInput ? 'primary' : 'inherit'} />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label={`clear ${inputLabel}`}
                    onKeyPress={handleClearSearch}
                    onClick={handleClearSearch}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </Paper>
    </div>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  defaultInput: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  defaultInput: '',
  inputLabel: 'Search',
};
