import { isEmpty, difference, sortBy, includes } from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UpdateIcon from '@material-ui/icons/SystemUpdateAlt';
import { Typography, Button, Box, Divider, Paper, Select, MenuItem } from '@material-ui/core';
import ReviewIcon from '@material-ui/icons/Beenhere';
import { Label } from 'components';
import SetpointHeader from './SetpointHeader';
import SetpointEditsTable from './SetpointEditsTable';
import { DeviceMapModel, LiveDevicesModel, DeviceSetpointCollection } from 'models';
import { BRIGHT_BLUE, DARK_GREY, BRIGHT_GREEN } from 'theme';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(3, 0),
  },
  tableBox: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  editContainer: {
    paddingLeft: theme.spacing(2),
    borderLeft: `4px solid ${BRIGHT_GREEN}`,
    marginBottom: theme.spacing(3),
  },
  maskContainer: {
    paddingLeft: theme.spacing(2),
    borderLeft: `4px solid ${BRIGHT_BLUE}`,
    marginBottom: theme.spacing(2),
  },
}));

function prepareSetJSON(
  hostRcpn,
  originalSetpointCollection,
  editedSetpointCollection,
  tableResets
) {
  let points = [];
  let tableDefaultCommands = [];

  if (editedSetpointCollection.size() > 0) {
    points = editedSetpointCollection.models.map((spt) => {
      return {
        table: spt.get('table'),
        index: spt.get('index'),
        value: spt.signedValue,
      };
    });
  }

  if (!isEmpty(tableResets)) {
    tableDefaultCommands = tableResets.map((table) => {
      const { tableNumber, indexes: indexesToReset } = table;

      // get full list of this table's setpoint indexes
      const tableSetpointCollection = originalSetpointCollection.filter(
        (spt) => spt.get('table') === tableNumber
      );
      const tableSetpointIndexesArray = tableSetpointCollection.models.map((spt) =>
        spt.get('index')
      );
      // only include indexes that are NOT selected in tableResets
      const maskedIndexes = difference(tableSetpointIndexesArray, indexesToReset);

      return {
        table: table.tableNumber,
        mask: maskedIndexes,
      };
    });
  }
  return {
    host_rcpn: hostRcpn,
    points: points,
    table_default_commands: tableDefaultCommands,
  };
}

function SetpointReviewer(props) {
  const {
    deviceMapModel,
    liveDevicesModel,
    originalSetpointCollection,
    editedSetpointCollection,
    tableResets,
    onCancelReview,
    onCreatePutJob,
  } = props;
  const classes = useStyles();
  // if we have a live devices list, use those Inverters
  // if not, fallback to device map inverters
  let inverterCollection = Boolean(liveDevicesModel.get('inverters').size())
    ? liveDevicesModel.get('inverters')
    : deviceMapModel.get('inverters');
  // grab the "freshest" inverter (most recently updated)
  const freshInverter = inverterCollection.getFreshest();
  const showInverterSelection = inverterCollection.size() > 1;
  const [hostRcpn, setHostRcpn] = React.useState(freshInverter ? freshInverter.id() : null);
  const tableOptions = originalSetpointCollection.tableOptions;
  const putJobData = prepareSetJSON(
    hostRcpn,
    originalSetpointCollection,
    editedSetpointCollection,
    tableResets
  );

  function handleHostRcpnChange(event) {
    setHostRcpn(event.target.value);
  }

  function handleUpdateSetpointsClick() {
    onCreatePutJob(putJobData);
  }

  return (
    <Fragment>
      <SetpointHeader
        title="Reviewing Setpoint Updates"
        subtitle="Changes will not be applied until you click Set"
        icon={<ReviewIcon />}
        iconColor={BRIGHT_BLUE}
        action={
          <Button variant="outlined" onClick={onCancelReview} size="small">
            <ArrowBackIcon className="space-right-smallest" />
            Back to editor
          </Button>
        }
      />
      {tableOptions.map((tableNumber, index) => {
        const tableReset = tableResets.find((tr) => tr.tableNumber === tableNumber);
        const editedSetpoints = sortBy(
          editedSetpointCollection.models.filter((spt) => spt.get('table') === tableNumber),
          (spt) => spt.get('index')
        );
        const hasEdits = Boolean(tableReset || !isEmpty(editedSetpoints));

        if (!hasEdits) {
          return (
            <Box key={`table_${tableNumber}`} component={Paper} className={classes.tableBox}>
              <Typography variant="h4" gutterBottom>
                Table {tableNumber}
              </Typography>
              <Label color={DARK_GREY} variant="outlined">
                No updates
              </Label>
            </Box>
          );
        }

        const tableSetpointCollection = originalSetpointCollection.filter(
          (spt) => spt.get('table') === tableNumber
        );
        // get full list of this table's setpoint indexes
        const tableSetpointIndexesArray = tableSetpointCollection.models.map((spt) =>
          spt.get('index')
        );
        let maskedIndexes = [];
        let maskedSetpoints = [];

        if (tableReset) {
          const { indexes: indexesToReset } = tableReset;
          // only include indexes that are NOT selected in tableResets
          // special case to remove index zero
          maskedIndexes = difference(tableSetpointIndexesArray, indexesToReset).filter(
            (i) => i !== 0
          );
          // also filter out setpoints included in edit
          maskedSetpoints = tableSetpointCollection.models.filter((spt) => {
            const edit = editedSetpoints.find((e) => e.get('index') === spt.get('index'));
            if (edit) {
              return false;
            } else if (includes(maskedIndexes, spt.get('index'))) {
              return true;
            }

            return false;
          });
        }

        return (
          <Box key={`table_${tableNumber}`} component={Paper} className={classes.tableBox}>
            <Typography variant="h4" gutterBottom>
              Table {tableNumber}
            </Typography>
            {!isEmpty(editedSetpoints) && (
              <div className={classes.editContainer}>
                <Box my={2}>
                  <Typography variant="h6">
                    Updating the following indexes with new values
                  </Typography>
                </Box>
                <SetpointEditsTable setpoints={editedSetpoints} type="edit" />
              </div>
            )}
            {tableReset && (
              <div className={classes.maskContainer}>
                <Box my={2}>
                  <Typography variant="h6">
                    Resetting {!isEmpty(editedSetpoints) ? 'all other' : 'all'} indexes to default{' '}
                    {!isEmpty(maskedSetpoints) ? 'with the following exceptions' : ''}
                  </Typography>
                </Box>
                {!isEmpty(maskedSetpoints) && (
                  <SetpointEditsTable setpoints={maskedSetpoints} type="mask" />
                )}
              </div>
            )}
          </Box>
        );
      })}
      <Divider />
      <Box my={3}>
        <Typography variant="h4" gutterBottom>
          JSON Request Body for spt_put
        </Typography>
        <Box
          p={3}
          mx={3}
          mb={3}
          bgcolor="background.default"
          style={{ overflow: 'auto' }}
          component="pre"
        >
          {JSON.stringify(putJobData, null, 4)}
        </Box>
      </Box>
      <Box my={3}>
        <div>
          {showInverterSelection && (
            <Box my={3}>
              <Typography variant="h4" gutterBottom>
                Select a Host RCPn
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                This system has multiple Inverter devices. Select the correct Inverter RCPn below.
              </Typography>
              <Select value={hostRcpn} onChange={handleHostRcpnChange}>
                {inverterCollection.models.map((inverterModel) => {
                  const id = inverterModel.id();
                  return (
                    <MenuItem key={id} value={id}>
                      {id} ({inverterModel.get('name')})
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          )}
          {Boolean(!showInverterSelection && freshInverter) && (
            <Box my={3}>
              <Typography variant="h4" gutterBottom>
                Host RCPn
              </Typography>
              <Typography variant="h6">
                {freshInverter.id()} ({freshInverter.get('name')})
              </Typography>
            </Box>
          )}
        </div>
      </Box>
      <Divider />
      <Box mt={3}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleUpdateSetpointsClick}
        >
          <UpdateIcon className="space-right-smallest" />
          Update Setpoints
        </Button>
      </Box>
    </Fragment>
  );
}

SetpointReviewer.propTypes = {
  // -- passed props
  deviceMapModel: PropTypes.instanceOf(DeviceMapModel).isRequired,
  liveDevicesModel: PropTypes.instanceOf(LiveDevicesModel).isRequired,
  originalSetpointCollection: PropTypes.instanceOf(DeviceSetpointCollection).isRequired,
  editedSetpointCollection: PropTypes.instanceOf(DeviceSetpointCollection).isRequired,
  tableResets: PropTypes.array,
  onCancelReview: PropTypes.func.isRequired,
  onCreatePutJob: PropTypes.func.isRequired,
};

SetpointReviewer.defaultProps = {
  tableResets: [],
  deviceMapModel: new DeviceMapModel(),
  liveDevicesModel: new LiveDevicesModel(),
};

export default SetpointReviewer;
