export * from './formulas';
export * from './time';
export * from './rcpn';

export function insertStringAt(
  parentString,
  childString,
  position,
  addLeadingComma,
  addTrailingComma
) {
  return `${parentString.slice(0, position)}${addLeadingComma ? ',' : ''}${childString}${
    addTrailingComma ? ',' : ''
  }${parentString.slice(position)}`;
}

/**
 * Pluralizes a string based on given amount
 * Obviously only works for simple cases where 's' is appended
 *
 * Examples:
 * (1, 'minute') returns 'minute'
 * (2, 'minute') returns 'minutes'
 */
export function pluralize(value, word) {
  let pluralizedWord;

  if (value === 1) {
    pluralizedWord = word;
  } else {
    pluralizedWord = word + 's';
  }

  return value + ' ' + pluralizedWord;
}

/**
 * Sorting for Tables
 * @see https://material-ui.com/components/tables/
 */
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
