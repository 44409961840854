import React from 'react';
import { colors } from '@material-ui/core';
import { Card, CardHeader, Box, Typography } from '@material-ui/core';
import { Label, TimeagoTooltip } from 'components';
import { CampaignStatus } from 'types/Campaign';

interface Props {
  campaignStatus: CampaignStatus;
}

export const CampaignStatusInfo = (props: Props) => {
  const { campaignStatus } = props;

  return (
    <Card>
      <CardHeader
        title="Campaign Status"
        subheader={
          <Box display="flex" alignContent="center" mt={1}>
            <Box mr={1}>
              <Typography>Started</Typography>
            </Box>
            <TimeagoTooltip timestamp={campaignStatus.startedDate} />
          </Box>
        }
        action={
          <Box mt={2} mr={3}>
            <Label color={colors.green['A700']}>{campaignStatus.campaignState}</Label>
          </Box>
        }
      />
    </Card>
  );
};
