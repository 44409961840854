import React, { useState, useEffect } from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { defaultRegistrationCode, generateRegistrationCode } from './hasher';

export const RegistrationLookup = () => {
  const [registrationCode, setRegistrationCode] = useState(defaultRegistrationCode);
  const [serialNumber, setSerialNumber] = useState('');

  const handleSerialNumberUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value.toUpperCase());
  };

  useEffect(() => {
    setRegistrationCode(generateRegistrationCode(serialNumber));
  }, [serialNumber]);

  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Typography variant="h5">Registration Lookup</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <TextField label="Serial Number" value={serialNumber} onChange={handleSerialNumberUpdate} />
        <TextField disabled variant="filled" value={registrationCode} />
      </CardActions>
    </Card>
  );
};
