import DataUnitDefinitions from 'data/data-units';
import { DataUnit } from 'types/DataUnit';
import { fixToDecimal } from 'utils';

// -- Device Event Data
export const EVENT_TIME = 'time';
export const EVENT_STATE = 'state';
export const EVENT_STATE_NAME = 'state_name';
export const EVENT_POWER = 'power';
export const EVENT_ENERGY = 'energy';
export const EVENT_VOLTAGE = 'voltage';
export const EVENT_CURRENT = 'current';
export const EVENT_REBUS_BITS = 'rebus_bits';
export const EVENT_TEMPERATURE = 'temperature';
export const EVENT_OTHERDATA_O0 = 'o0';
export const EVENT_OTHERDATA_O1 = 'o1';
export const EVENT_OTHERDATA_O2 = 'o2';
export const EVENT_OTHERDATA_O3 = 'o3';
export const EVENT_OTHERDATA_O4 = 'o4';
export const EVENT_OTHERDATA_O5 = 'o5';
export const EVENT_OTHERDATA_O6 = 'o6';
export const EVENT_OTHERDATA_O7 = 'o7';
export const EVENT_OTHERDATA_O8 = 'o8';
export const EVENT_OTHERDATA_O9 = 'o9';
export const EVENT_OTHERDATA_OA = 'oA';
export const EVENT_OTHERDATA_OB = 'oB';
export const EVENT_OTHERDATA_OC = 'oC';
export const EVENT_OTHERDATA_OD = 'oD';
export const EVENT_OTHERDATA_OE = 'oE';
export const EVENT_OTHERDATA_OF = 'oF';
export const EVENT_OTHERDATA_OG = 'oG';

// these are in spreadsheet column order
export const map = {
  [EVENT_TIME]: {
    name: 'Time',
    ...DataUnitDefinitions[DataUnit.TIME],
  },
  [EVENT_STATE]: {
    name: 'State',
    ...DataUnitDefinitions[DataUnit.CATEGORY],
    formatValue: DataUnitDefinitions[DataUnit.BITFIELD].formatValue,
  },
  [EVENT_STATE_NAME]: {
    name: 'State Name',
    ...DataUnitDefinitions[DataUnit.CATEGORY],
  },
  [EVENT_POWER]: {
    name: 'Power',
    ...DataUnitDefinitions[DataUnit.POWER_WATTS],
  },
  [EVENT_ENERGY]: {
    name: 'Energy',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [EVENT_VOLTAGE]: {
    name: 'Voltage',
    ...DataUnitDefinitions[DataUnit.VOLTAGE],
    // @note: the voltage value here needs to be adjusted for some reason
    formatValue: function (value) {
      return fixToDecimal(value / 10, 1);
    },
  },
  [EVENT_CURRENT]: {
    name: 'Current',
    ...DataUnitDefinitions[DataUnit.CURRENT_AMPS],
    // @note: the current value here needs to be adjusted for some reason
    formatValue: function (value) {
      return fixToDecimal(value / 100, 2);
    },
  },
  [EVENT_REBUS_BITS]: {
    name: 'REbus Bits',
    ...DataUnitDefinitions[DataUnit.CATEGORY],
    formatValue: DataUnitDefinitions[DataUnit.BITFIELD].formatValue,
  },
  [EVENT_TEMPERATURE]: {
    name: 'Temperature',
    ...DataUnitDefinitions[DataUnit.TEMPERATURE_CELSIUS],
    // @note: the temperature value here needs to be adjusted for some reason
    formatValue: function (value) {
      return fixToDecimal(value / 10, 1);
    },
  },
  [EVENT_OTHERDATA_O0]: {
    name: 'o0',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O1]: {
    name: 'o1',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O2]: {
    name: 'o2',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O3]: {
    name: 'o3',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O4]: {
    name: 'o4',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O5]: {
    name: 'o5',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O6]: {
    name: 'o6',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O7]: {
    name: 'o7',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O8]: {
    name: 'o8',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_O9]: {
    name: 'o9',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OA]: {
    name: 'oA',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OB]: {
    name: 'oB',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OC]: {
    name: 'oC',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OD]: {
    name: 'oD',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OE]: {
    name: 'oE',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OF]: {
    name: 'oF',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
  [EVENT_OTHERDATA_OG]: {
    name: 'oG',
    ...DataUnitDefinitions[DataUnit.OTHER_DATA],
  },
};
