import React, { useContext } from 'react';
import { APIClientContext, APIClient, endpoints } from 'api';
import { ConfigContext, Config } from 'application-config';

const Logout: React.FunctionComponent = () => {
  const apiClient: APIClient = useContext(APIClientContext);
  const config: Config = useContext(ConfigContext);

  const redirectHost = `?redirect=${config.domainName}`;
  const logoutUrl = apiClient.urlCreator.createUrl({
    path: endpoints.proxy.logout + redirectHost,
  });
  window.location.href = logoutUrl;
  return null;
};

export default Logout;
