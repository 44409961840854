export enum FirmwareType {
  BHC = 'bhc',
  DCB = 'dcb',
  DLG = 'dlg',
  ZM2 = 'zm2',
  SUN = 'sun',
  BAK = 'bak',
  ICM = 'icm',
  PVL = 'pvl',
  URC = 'urc',
  END = 'end',
  EGC = 'egc',
  EDC = 'edc',
  EEC = 'eec',
  UNKNOWN = '',
}

export const parseFirmwareString = (input: string): FirmwareType => {
  const enumKeys = (Object.values(FirmwareType) as unknown) as string[];
  if (enumKeys.includes(input)) {
    return input as FirmwareType;
  } else {
    return FirmwareType.UNKNOWN;
  }
};
