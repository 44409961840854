import { includes, isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody } from '@material-ui/core';
import SetpointTableHead from './SetpointTableHead';
import SetpointTableRow from './SetpointTableRow';
import SetpointTableRowDisabled from './SetpointTableRowDisabled';
import { DeviceSetpointCollection } from 'models';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

function SetpointTable({
  tableNumber,
  setpointCollection,
  editCollection,
  resetIndexes,
  isEditing,
  showTableColumn,
  showEditsOnly,
  onSetpointEdit,
  onSetpointDiscardEdit,
  onTableResetChange,
  onSetpointResetChange,
}) {
  const classes = useStyles();
  let rowModels = setpointCollection.models;
  let isResetting = !isEmpty(resetIndexes);

  return (
    <React.Fragment>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size="small">
          <SetpointTableHead
            tableNumber={tableNumber}
            showTableColumn={showTableColumn}
            resetIndexes={resetIndexes}
            totalRowCount={rowModels.length}
            isEditing={isEditing}
            onTableResetChange={onTableResetChange}
          />
          <TableBody>
            {rowModels.map((rowModel, index) => {
              // if an edited version of the model exists,
              // we want to use it here instead
              const editModel = editCollection.findById(rowModel.id());
              const isEdited = Boolean(editModel);
              const isDisabled = Boolean(rowModel.get('index') === 0);
              const isSelected = includes(resetIndexes, rowModel.get('index'));

              if (showEditsOnly && !isEdited) {
                return null;
              } else {
                if (isDisabled) {
                  return (
                    <SetpointTableRowDisabled
                      key={rowModel.id()}
                      setpointModel={isEdited ? editModel : rowModel}
                      isEditing={isEditing}
                      showTableColumn={showTableColumn}
                    />
                  );
                } else {
                  return (
                    <SetpointTableRow
                      key={rowModel.id()}
                      setpointModel={isEdited ? editModel : rowModel}
                      onEdit={onSetpointEdit}
                      onDiscardEdit={onSetpointDiscardEdit}
                      onSelectedChange={onSetpointResetChange}
                      isResetting={isResetting}
                      isSelected={isSelected}
                      isEdited={isEdited}
                      isEditing={isEditing}
                      showTableColumn={showTableColumn}
                    />
                  );
                }
              }
            })}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

SetpointTable.propTypes = {
  // -- passed props
  tableNumber: PropTypes.number.isRequired,
  setpointCollection: PropTypes.instanceOf(DeviceSetpointCollection).isRequired,
  editCollection: PropTypes.instanceOf(DeviceSetpointCollection).isRequired,
  resetIndexes: PropTypes.array,
  isEditing: PropTypes.bool.isRequired,
  showTableColumn: PropTypes.bool.isRequired,
  showEditsOnly: PropTypes.bool.isRequired,
  onSetpointEdit: PropTypes.func.isRequired,
  onSetpointDiscardEdit: PropTypes.func.isRequired,
  onTableResetChange: PropTypes.func,
  onSetpointResetChange: PropTypes.func,
};

SetpointTable.defaultProps = {
  isEditing: false,
  showTableColumn: false,
  showEditsOnly: false,
};

export default SetpointTable;
