/**
 * Model Base
 *
 * @see http://k33g.github.io/2014/07/05/ES6-IN-ACTION-WITH-MODELS.html
 */

import { cloneDeep } from 'lodash';

export class Model {
  constructor(data = {}) {
    this.className = this.constructor.name || null;
    this.fields = data ? cloneDeep(data) : {};
    this.cacheData = {};
  }

  clone() {
    return cloneDeep(this);
  }

  id() {
    return this.get('id');
  }

  get(fieldName) {
    return this.fields[fieldName];
  }

  set(fields) {
    this.fields = Object.assign(this.fields, fields);
  }

  removeField(field) {
    delete this.fields[field];
  }

  cache() {
    this.cacheData = cloneDeep(this.fields);
  }

  applyCache() {
    this.fields = cloneDeep(this.cacheData);
  }

  fieldName(key) {
    if (!this.fieldMap && !this.constructor.fieldMap) {
      console.error('This model does not have a fieldMap');
      return null;
    }

    if (this.fieldMap) {
      return this.fieldMap[key] || key;
    } else if (this.constructor.fieldMap) {
      return this.constructor.fieldMap[key] || key;
    } else {
      return key;
    }
  }

  // -- api calls

  // async fetch() {
  // 	try {
  // 		const response = await postgrest.fetch(this.endpoint, this.id())
  // 		return this.constructor(response)
  // 	} catch (error) {
  // 		throw error
  // 	}
  // }
  //
  // async create() {
  // 	try {
  // 		const response = await postgrest.create(this.endpoint, this.fields)
  // 		return this.constructor(response)
  // 	} catch (error) {
  // 		throw error
  // 	}
  // }
  //
  // // @todo only patch updated fields
  // async save() {
  // 	try {
  // 		const response = await postgrest.save(
  // 			this.endpoint,
  // 			this.id(),
  // 			this.fields
  // 		)
  // 		return this.constructor(response)
  // 	} catch (error) {
  // 		throw error
  // 	}
  // }
  //
  // async destroy() {
  // 	try {
  // 		await postgrest.destroy(this.endpoint, this.id())
  // 		return this.id()
  // 	} catch (error) {
  // 		throw error
  // 	}
  // }
}
