import { find, map, merge, cloneDeep } from 'lodash';
import { Collection } from './Collection';
import { JobEventModel } from './JobEventModel';
import { JobEventType } from 'types/Job';
import { JobType } from 'types/Job';
import { endpoints } from 'api';
import { DeviceSetpointCollection } from './DeviceSetpointCollection';

// used to determine whether to poll the Job for Event updates
// if any of these Events exist on the Job, the Job is considered "ended unsuccessfully" (not "completed")
export const endedUnsuccessfullyStates = [JobEventType.ERROR, JobEventType.CANCEL];
// endedSuccessfullyStates only applies to spt_put jobs (for now)
// spt_get jobs require further logic for SUCCESS && SPT_PARTIAL && SPT_METADATA
export const endedSuccessfullyStates = [JobEventType.SUCCESS];
// all setpointEvents will contain a Payload with setpoints data, hw_version, sw_version, etc...
export const setpointEventTypes = [
  JobEventType.SETPOINTS_RETRIEVED,
  JobEventType.SETPOINTS_PARTIAL,
  JobEventType.SETPOINTS_METADATA,
];

export class JobEventCollection extends Collection {
  constructor(data) {
    super(data, JobEventModel);

    this.endpoint = endpoints.firmware.job.events;
  }

  // -- api calls

  async fetch(apiClient, jobId) {
    if (!jobId) {
      console.error('JobEventCollection needs a jobId to fetch');
    }

    try {
      const response = await apiClient.get({
        path: this.endpoint,
        ids: jobId,
      });
      if (!response.success) {
        throw Error('Could not get Job Events');
      }
      return new this.constructor(response.events);
    } catch (error) {
      throw error;
    }
  }

  // --

  /**
   * check if a job has ended, regardless of success, success + setpoints, or error/cancel states
   * this check requires a jobType because each jobType has different rules for success states
   *
   * all types:
   * Ended unsuccessfully: "error" || "cancel"
   *
   * spt_put, firmware_update:
   * Ended successfully: "success"
   *
   * spt_get:
   * Ended successfully (legacy): "spt_retrieved"
   * Ended successfully: "spt_partial" && "spt_metadata"
   *
   */
  hasEnded(jobType) {
    if (this.size()) {
      if (this.hasEndedUnsuccessfully) {
        return true;
      }

      switch (jobType) {
        case JobType.SPT_GET:
          return this.hasEndedSuccessfullyWithSetpointData;
        case JobType.FIRMWARE_UPDATE:
        case JobType.SPT_PUT:
          return this.hasEndedSuccessfully;
        default:
          return this.hasEndedSuccessfully;
      }
    }

    return false;
  }

  get hasEndedUnsuccessfully() {
    if (this.size()) {
      const event = this.models.find((m) => endedUnsuccessfullyStates.indexOf(m.get('Type')) > -1);
      return Boolean(event);
    }

    return false;
  }

  get hasEndedSuccessfully() {
    if (this.size()) {
      const event = this.models.find((m) => endedSuccessfullyStates.indexOf(m.get('Type')) > -1);
      return Boolean(event);
    }

    return false;
  }

  /**
   * spt_get job types are considered "complete" once setpoint data is available:
   *
   * (spt_partial + spt_metadata) are the most recent iteration of spt data events
   * spt_retrieved is legacy and needs to be supported for backwards compatibility for existing jobs
   */
  get hasEndedSuccessfullyWithSetpointData() {
    if (this.size()) {
      const retrievedEvent = this.models.find(
        (m) => m.get('Type') === JobEventType.SETPOINTS_RETRIEVED
      );

      if (retrievedEvent) {
        // legacy support for existing firmware_update and spt_get jobs
        return true;
      } else {
        // new format
        const partialEvent = this.models.find(
          (m) => m.get('Type') === JobEventType.SETPOINTS_PARTIAL
        );
        const metadataEvent = this.models.find(
          (m) => m.get('Type') === JobEventType.SETPOINTS_METADATA
        );

        if (partialEvent && metadataEvent) {
          return true;
        }
      }
    }

    return false;
  }

  // --

  // if spt_retrieved exists, return collection of those points
  // if spt_partial + spt_metadata exists, merge point data objects and return as colleciton
  get setpointCollection() {
    if (this.size()) {
      // if there is an spt_retrieved event, we don't need to do any merging
      // just grab the setpoints data and return it
      const sptRetrievedEvent = this.models.find(
        (m) => m.get('Type') === JobEventType.SETPOINTS_RETRIEVED
      );

      if (sptRetrievedEvent) {
        return new DeviceSetpointCollection(sptRetrievedEvent.setpoints);
      }

      const sptPartialEvent = this.models.find(
        (model) => model.get('Type') === JobEventType.SETPOINTS_PARTIAL
      );
      const sptMetadataEvent = this.models.find(
        (model) => model.get('Type') === JobEventType.SETPOINTS_METADATA
      );

      if (sptPartialEvent && sptMetadataEvent) {
        const sptPartialEventSetpoints = cloneDeep(sptPartialEvent.setpoints);
        const sptMetadataEventSetpoints = cloneDeep(sptMetadataEvent.setpoints);

        const mergedPoints = map(sptPartialEventSetpoints, function (partialPoint) {
          return merge(
            partialPoint,
            find(
              sptMetadataEventSetpoints,
              (metaPoint) =>
                metaPoint.table === partialPoint.table && metaPoint.index === partialPoint.index
            )
          );
        });

        return new DeviceSetpointCollection(mergedPoints);
      }

      return null;
    }

    return null;
  }

  /**
   * finds the first spt_* event (spt_partial, spt_metadata, spt_retrieved)
   * and returns the hw_version or sw_version
   */
  get firstSetpointEvent() {
    if (this.size()) {
      const setpointEvent = this.models.find((m) => setpointEventTypes.indexOf(m.get('Type')) > -1);
      return setpointEvent || null;
    }

    return null;
  }

  get hwVersion() {
    const setpointEvent = this.firstSetpointEvent;
    return setpointEvent ? setpointEvent.hwVersion : null;
  }

  get swVersion() {
    const setpointEvent = this.firstSetpointEvent;
    return setpointEvent ? setpointEvent.swVersion : null;
  }

  // --

  get currentEventType() {
    if (this.size()) {
      return this.currentEvent.get('Type');
    }
    return null;
  }

  // get the most recent Event that is not of Type === 'meta'
  get currentEvent() {
    if (this.size()) {
      return this.models.find((model) => model.get('Type') !== JobEventType.META);
    }
    return null;
  }

  get errorEvent() {
    if (this.size()) {
      const errorEvent = this.models.find((model) => model.get('Type') === JobEventType.ERROR);
      return errorEvent ? errorEvent : null;
    }
    return null;
  }
}
