import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { Alert, TextTooltip, ComplianceReportList } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { APIClientContext, endpoints } from 'api';
import {
  GridProfileListItem,
  GridProfileListProfiles,
  GridProfileListResponse,
  GridProfileResponse,
  GridProfilePatchResponse,
} from 'types/GridProfile';

interface Props {
  siteId: string;
  deviceId: string;
  refresh: boolean;
  swVersion: string;
}

export const GridProfileLabel = (props: Props) => {
  const { siteId, deviceId } = props;

  const apiClient = useContext(APIClientContext);
  const [gridProfiles, setGridProfiles] = useState<GridProfileListProfiles>();
  const [gridProfileIdx, setGridProfileIdx] = useState<number | null>();
  const [newGridProfileIdx, setNewGridProfileIdx] = useState<number | null>();
  const [value, setFullValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getError, setGetError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const setValue = (index: number | null, label: string) => {
    setGridProfileIdx(index);
    if (index != null) {
      if (label !== '') {
        setFullValue(`${index} - ${label}`);
      } else {
        setFullValue(`${index}`);
      }
    } else {
      setFullValue('');
    }
  };

  const updateGridProfile = async (newIndex: number, newLabel: string) => {
    try {
      const response: GridProfilePatchResponse = (await apiClient.patch({
        path: endpoints.site.setGridProfile,
        ids: {
          siteId,
          deviceId,
        },
        data: {
          index: newIndex,
        },
      })) as GridProfilePatchResponse;
      if (response.success) {
        setValue(newIndex, newLabel);
        setError('');
        handleClose();
      } else {
        setError(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === 'Conflict') {
          setError('Inverter should be disabled before changing the grid profile.');
        } else {
          setError(String(error));
        }
      } else {
        setError(String(error));
      }
    }
  };

  const handleSubmit = () => {
    if (newGridProfileIdx != null && gridProfiles) {
      updateGridProfile(newGridProfileIdx, gridProfiles[newGridProfileIdx].label);
    }
  };

  useEffect(() => {
    const fetchGridProfile = async () => {
      try {
        const response: GridProfileResponse = (await apiClient.get({
          path: endpoints.site.getGridProfile,
          ids: {
            siteId,
            deviceId,
          },
        })) as GridProfileResponse;

        setIsLoading(false);
        setGetError('');
        if (response.success) {
          setValue(response.index, response.label);
        } else {
          setValue(null, '');
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setGetError(error.toString());
        setValue(null, '');
      }
    };

    fetchGridProfile();
  }, [apiClient, siteId, deviceId, props.refresh, refresh]);

  useEffect(() => {
    const fetchGridProfiles = async () => {
      try {
        const response: GridProfileListResponse = (await apiClient.get({
          path: endpoints.gridProfiles,
        })) as GridProfileListResponse;

        if (gridProfileIdx != null) {
          if (Object.keys(response.profiles)[gridProfileIdx] === undefined) {
            console.log('Index %d missing from profiles', gridProfileIdx);
            const item = {
              id: gridProfileIdx,
              value: gridProfileIdx,
              name: 'Unknown',
              label: 'Unknown',
              desc: 'Unknown currently selected profile.',
              sw_ver: [],
            };
            response.profiles[gridProfileIdx] = item;
          }
        }

        setGridProfiles(response.profiles);
      } catch (error) {
        console.error(error);
      }
    };

    if (open) {
      fetchGridProfiles();
    }
  }, [apiClient, open, gridProfileIdx]);

  const handleChange = (event: React.SyntheticEvent<{}>) => {
    const value = (event.target as HTMLInputElement).value;
    setNewGridProfileIdx(Number(value));
  };

  const handleClickOpen = () => {
    setNewGridProfileIdx(gridProfileIdx);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const isProfileAvailable = (profile: GridProfileListItem) => {
    if (!props.swVersion) {
      return true;
    }

    if (!profile.sw_ver || profile.sw_ver.length !== 2) {
      return true;
    }

    const op = profile.sw_ver[0];
    const cmpSwVersion = profile.sw_ver[1];
    const swVersion = Number(props.swVersion);

    if (op === '==') {
      return swVersion === cmpSwVersion;
    } else if (op === '<') {
      return swVersion < cmpSwVersion;
    } else if (op === '>=') {
      return swVersion >= cmpSwVersion;
    }

    return true;
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Grid Profile</Typography>
            <div className="flex-grow" />
            <IconButton onClick={handleClose} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Box my={1}>
              {/* @ts-ignore */}
              <Alert variant="error" message={error} />
            </Box>
          )}
          <FormControl fullWidth>
            <InputLabel id="grid-profile-select-label">Grid Profile</InputLabel>
            <Select
              labelId="grid-profile-select-label"
              id="grid-profile-select"
              value={newGridProfileIdx}
              label="Grid Profile"
              onChange={handleChange}
            >
              {gridProfiles &&
                Object.values(gridProfiles).map((profile) => {
                  return (
                    <MenuItem
                      key={profile.value}
                      value={profile.value}
                      disabled={!isProfileAvailable(profile)}
                    >
                      {profile.value} - {profile.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>

        <DialogContent>
          <ComplianceReportList siteId={siteId} deviceId={deviceId} />
        </DialogContent>
      </Dialog>

      <TextTooltip
        typographyProps={{ variant: 'h6', onClick: handleClickOpen }}
        text={value}
        tooltip="Click to edit"
      />
      {isLoading && <CircularProgress size={20} />}
      {getError && (
        <Tooltip title={getError}>
          <ErrorOutlineIcon onClick={handleRefresh} />
        </Tooltip>
      )}
    </Fragment>
  );
};
