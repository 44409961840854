import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';

export const TextTooltip = props => {
  const { text, tooltip, tooltipProps, typographyProps, ...rest } = props;

  return (
    <Tooltip title={tooltip} placement="top" {...rest} {...tooltipProps}>
      <Typography {...typographyProps} className="dashed-underline">
        {text}
      </Typography>
    </Tooltip>
  );
};

TextTooltip.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
  typographyProps: PropTypes.object,
};

TextTooltip.defaultProps = {
  text: '',
  tooltip: '',
};
