import { ComponentType } from 'react';
import { colors, SvgIconProps } from '@material-ui/core';
import FirmwareIcon from '@material-ui/icons/Memory';
import GetIcon from '@material-ui/icons/GetApp';
import PutIcon from '@material-ui/icons/Publish';
import { JobType } from 'types/Job';

interface Definition {
  name: string;
  icon: ComponentType<SvgIconProps>;
  color: string;
}

const JobTypeDefinitions: Record<JobType, Definition> = {
  [JobType.FIRMWARE_UPDATE]: {
    name: 'Firmware Update',
    icon: FirmwareIcon,
    color: colors.lightBlue['A700'],
  },
  [JobType.SPT_GET]: {
    name: 'Get Setpoints',
    icon: GetIcon,
    color: colors.lightBlue['A700'],
  },
  [JobType.SPT_PUT]: {
    name: 'Put Setpoints',
    icon: PutIcon,
    color: colors.lightBlue['A700'],
  },
};

export default JobTypeDefinitions;

export const jobTypesList = [JobType.FIRMWARE_UPDATE, JobType.SPT_GET, JobType.SPT_PUT];
