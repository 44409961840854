import moment from 'moment';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { UTC } from 'data/timezones';
import { formatTimestamp, getTimezoneOffset, TIMESTAMP_FORMAT_TO_MINUTES } from 'utils';

const pickerProps = {
  ampm: false,
  disableFuture: true,
  margin: 'normal',
  format: TIMESTAMP_FORMAT_TO_MINUTES,
};

const useStyles = makeStyles(theme => ({
  input: {
    width: 180,
  },
}));

export const DateRangeInput = ({
  timezone,
  defaultFromValue,
  defaultToValue,
  fromInputLabel,
  toInputLabel,
  isClearable,
  onUpdate,
  ...rest
}) => {
  const classes = useStyles();
  // @todo "hasApplied" should be set based on existence of incoming defaultValues
  const [hasApplied, setHasApplied] = React.useState(true);
  const [fromValue, setFromValue] = React.useState(moment.tz(defaultFromValue, timezone));
  const [toValue, setToValue] = React.useState(moment.tz(defaultToValue, timezone));

  function handleFromDateChange(date) {
    setFromValue(moment.tz(date, timezone));
    setHasApplied(false);
  }

  function handleToDateChange(date) {
    setToValue(moment.tz(date, timezone));
    setHasApplied(false);
  }

  function handleApply() {
    onUpdate(fromValue, toValue, true);
    setHasApplied(true);
  }

  function handleClear() {
    setFromValue(null);
    setToValue(null);
    onUpdate(null, null, false);
    setHasApplied(true);
  }

  // needs a start and end date to be valid
  const isValid = Boolean(fromValue && toValue);

  return (
    <Box>
      <Box>
        <Typography variant="caption" component="p" gutterBottom>
          Timezone: {timezone} {getTimezoneOffset(timezone)}
        </Typography>
        <Typography variant="caption" component="p">
          Format: {TIMESTAMP_FORMAT_TO_MINUTES}
        </Typography>
      </Box>
      <Box>
        <Box mr={3} component="span">
          <KeyboardDateTimePicker
            {...pickerProps}
            clearable={isClearable}
            className={classes.input}
            id="from_date"
            label={fromInputLabel}
            value={fromValue}
            onChange={handleFromDateChange}
            labelFunc={date => formatTimestamp(date, timezone, TIMESTAMP_FORMAT_TO_MINUTES)}
          />
        </Box>
        <Box component="span">
          <KeyboardDateTimePicker
            {...pickerProps}
            clearable={isClearable}
            className={classes.input}
            id="to_date"
            label={toInputLabel}
            value={toValue}
            minDate={fromValue}
            onChange={handleToDateChange}
            labelFunc={date => formatTimestamp(date, timezone, TIMESTAMP_FORMAT_TO_MINUTES)}
          />
        </Box>
      </Box>
      <Box mt={4} display="flex">
        {isClearable && (fromValue || toValue) && (
          <Fragment>
            <Button size="small" onClick={handleClear}>
              Clear
            </Button>
            <div className="flex-grow" />
          </Fragment>
        )}
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleApply}
          disabled={hasApplied || !isValid}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

DateRangeInput.propTypes = {
  timezone: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultFromValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  defaultToValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fromInputLabel: PropTypes.string.isRequired,
  toInputLabel: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isClearable: PropTypes.bool.isRequired,
};

DateRangeInput.defaultProps = {
  timezone: UTC,
  name: 'date',
  isClearable: false,
  defaultFromValue: null,
  defaultToValue: null,
  fromInputLabel: 'From',
  toInputLabel: 'To',
};
