import { colors } from '@material-ui/core';

// neutral = grey
export const DEVICE_STATE_DISABLED = 'disabled';
export const DEVICE_STATE_INITIALIZING = 'initializing';
export const DEVICE_STATE_WAITING = 'waiting';
export const DEVICE_STATE_OFFLINE = 'device offline';
export const DEVICE_STATE_DEFAULT = 'default';

// something = yellow
export const DEVICE_STATE_LOW_SUN = 'low sun';
export const DEVICE_STATE_TESTING_INPUT = 'testing input';

// bad = red
export const DEVICE_STATE_GRID_INTERRUPT_FAULT = 'interrupt fault';
export const DEVICE_STATE_REBUS_FAULT = 'REbus fault';

// good = green
export const DEVICE_STATE_RUNNING = 'running';
export const DEVICE_STATE_MAKING_POWER = 'making power';
export const DEVICE_STATE_GRID_CONNECTED = 'grid connected';
export const DEVICE_STATE_STANDBY = 'standby';

export const map = {
  [DEVICE_STATE_INITIALIZING]: {
    color: colors.teal[400],
  },
  [DEVICE_STATE_RUNNING]: {
    color: colors.blue['A700'],
  },
  [DEVICE_STATE_MAKING_POWER]: {
    color: colors.teal['A700'],
  },
  [DEVICE_STATE_GRID_CONNECTED]: {
    color: colors.green['A700'],
  },
  [DEVICE_STATE_WAITING]: {
    color: colors.yellow[700],
  },
  [DEVICE_STATE_STANDBY]: {
    color: colors.grey[600],
  },
  [DEVICE_STATE_LOW_SUN]: {
    color: colors.amber[600],
  },
  [DEVICE_STATE_DISABLED]: {
    color: colors.grey[400],
  },
  [DEVICE_STATE_TESTING_INPUT]: {
    color: colors.indigo[600],
  },
  [DEVICE_STATE_GRID_INTERRUPT_FAULT]: {
    color: colors.red[600],
  },
  [DEVICE_STATE_REBUS_FAULT]: {
    color: colors.red[600],
  },
  [DEVICE_STATE_DEFAULT]: {
    color: colors.grey[500],
  },
};

export default [
  DEVICE_STATE_INITIALIZING,
  DEVICE_STATE_RUNNING,
  DEVICE_STATE_MAKING_POWER,
  DEVICE_STATE_GRID_CONNECTED,
  DEVICE_STATE_WAITING,
  DEVICE_STATE_STANDBY,
  DEVICE_STATE_DISABLED,
  DEVICE_STATE_GRID_INTERRUPT_FAULT,
  DEVICE_STATE_REBUS_FAULT,
];
