import { Model } from './Model';
import { Collection } from './Collection';
import {
  BeaconModel,
  InverterModel,
  ICMModel,
  RGMModel,
  BatteryModel,
  PVLModel,
  GeneratorModel,
} from './DeviceModel';

// @todo refactor this with LiveDevicesModel Collection
class MapDeviceCollection extends Collection {
  // in the case of multiple devices
  // return the most recently updated device model
  getFreshest() {
    if (this.size() === 0) {
      return null;
    }
    if (this.size() > 1) {
      // need to inverse to have highest number (most recent date) at index 0
      return this.sortBy((m) => m.get('last_update') * -1).getAt(0);
    } else {
      return this.getAt(0);
    }
  }
}

export class DeviceMapModel extends Model {
  constructor(fields = {}) {
    super({
      beacons: new MapDeviceCollection(fields.beacons || [], BeaconModel),
      inverters: new MapDeviceCollection(fields.inverters || [], InverterModel),
      icms: new MapDeviceCollection(fields.icms || [], ICMModel),
      batteries: new MapDeviceCollection(fields.batteries || [], BatteryModel),
      pvls: new MapDeviceCollection(fields.pvls || [], PVLModel),
      rgms: new MapDeviceCollection(fields.rgms || [], RGMModel),
      generators: new MapDeviceCollection(fields.generators || [], GeneratorModel),
    });
  }

  findById(id) {
    return this.deviceModels.find((model) => model.id() === id) || null;
  }

  get beaconId() {
    const beacon = this.get('beacons').getFreshest();
    if (beacon) {
      return beacon.id();
    } else {
      return null;
    }
  }

  get deviceModels() {
    return [
      ...this.get('beacons').models,
      ...this.get('inverters').models,
      ...this.get('icms').models,
      ...this.get('batteries').models,
      ...this.get('pvls').models,
      ...this.get('rgms').models,
      ...this.get('generators').models,
    ];
  }

  get deviceCount() {
    return this.deviceModels.length;
  }

  get lastUpdate() {
    const beacon = this.get('beacons').getFreshest();
    if (beacon) {
      return beacon.get('last_update');
    } else {
      return null;
    }
  }

  /**
   * returns a simple array of device objects containing
   * the device ids and names
   */
  get deviceList() {
    const deviceModels = this.deviceModels;

    return deviceModels.map((model) => ({
      id: model.id(),
      name: model.get('name'),
    }));
  }
}
