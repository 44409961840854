import { Model } from './Model';

const defaultFields = {
  id: null, // string (not really using id yet)
  version: null, // string
  type: null, // string
};

export class FirmwareVersionModel extends Model {
  constructor(fields = defaultFields) {
    super(fields);
  }

  /**
   * @see https://pika-energy.atlassian.net/browse/ST-407
   *
   * examples of version paths:
   * "dev/hexes/icm/310/ICM_12021_310.hex"
   * "dev/hexes/dcb/DCB105 - 1610.hexe"
   *
   * this method will split out the hardware version, ("310"), if available
   * and filename, ("ICM_12021_310.hex"), into an object
   */
  get versionPathComponents() {
    let hardwareVersion = null;
    let filename = null;

    const version = this.get('version');
    // get everything past the `/{firmware_type}/` string
    const firmwareTypeString = `/${this.get('type')}/`;
    const firmwareTypeIndex = version.indexOf(firmwareTypeString);
    // if the filename still contains a "/", everything before the "/" is the hardware version
    const hardwareVersionWithFilename = version.substr(
      firmwareTypeIndex + firmwareTypeString.length,
      version.length
    );
    const slashIndex = hardwareVersionWithFilename.indexOf('/');
    if (slashIndex > -1) {
      hardwareVersion = hardwareVersionWithFilename.substr(0, slashIndex);
      filename = hardwareVersionWithFilename.substr(
        slashIndex + 1,
        hardwareVersionWithFilename.length
      );
    } else {
      filename = hardwareVersionWithFilename;
    }

    return {
      hardwareVersion,
      filename,
    };
  }
}
