import { colors } from '@material-ui/core';
import { ActionState } from 'types/Campaign';

interface Definition {
  name: string;
  color: string;
}

// @todo define actual colors here - these are temporary
const CampaignActionStateDefinitions: Record<ActionState, Definition> = {
  [ActionState.EMPTY_ACTION_STATE]: {
    name: 'None',
    color: colors.grey[500],
  },
  [ActionState.WAITING]: {
    name: 'Waiting',
    color: colors.grey[500],
  },
  [ActionState.RUNNING]: {
    name: 'Running',
    color: colors.blue['A700'],
  },
  [ActionState.COMPLETED]: {
    name: 'Completed',
    color: colors.green['A700'],
  },
  [ActionState.FAILED]: {
    name: 'Failed',
    color: colors.red[400],
  },
  [ActionState.ROLLING_BACK]: {
    name: 'Rolling back',
    color: colors.purple[400],
  },
  [ActionState.SUCCESSFUL_ROLLBACK]: {
    name: 'Rolling back',
    color: colors.purple[400],
  },
  [ActionState.UNSUCCESSFUL_ROLLBACK]: {
    name: 'Rolling back',
    color: colors.purple[400],
  },
  [ActionState.CANCELED]: {
    name: 'Canceled',
    color: colors.grey[400],
  },
};

export default CampaignActionStateDefinitions;
