import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Popover } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { DeviceSetpointModel } from 'models';

const useStyles = makeStyles(theme => ({
  arrowIcon: {
    display: 'block',
    margin: theme.spacing(0, 1),
    fontSize: '13px',
    color: grey[500],
  },
  popoverArrowIcon: {
    display: 'block',
    margin: theme.spacing(1, 0.5),
    fontSize: '13px',
    color: grey[500],
  },
  boldValue: {
    fontWeight: 500,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function SetpointValuesTooltip(props) {
  const { setpoint: spt, type } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const afterValueClasses = type === 'edit' ? classes.boldValue : null;

  return (
    <Fragment>
      <Box
        display="inline-flex"
        alignItems="center"
        onMouseEnter={event => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        className="dashed-underline"
        px={1}
      >
        <Typography>{spt.initialSignedValue}</Typography>
        <ArrowForwardIcon className={classes.arrowIcon} />
        <Typography className={afterValueClasses}>{spt.signedValue}</Typography>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: -10,
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        elevation={6}
      >
        <Box display="flex" alignItems="center">
          <Box mr={1} textAlign="right">
            <Typography>{spt.initialSignedValue}</Typography>
            <Typography>{spt.initialUnsignedValue}</Typography>
            <Typography>{spt.initialHexValue}</Typography>
          </Box>
          <Box mr={1}>
            <ArrowForwardIcon className={classes.popoverArrowIcon} />
            <ArrowForwardIcon className={classes.popoverArrowIcon} />
            <ArrowForwardIcon className={classes.popoverArrowIcon} />
          </Box>
          <Box>
            <Typography className={afterValueClasses}>{spt.signedValue}</Typography>
            <Typography className={afterValueClasses}>{spt.unsignedValue}</Typography>
            <Typography className={afterValueClasses}>{spt.hexValue}</Typography>
          </Box>
        </Box>
      </Popover>
    </Fragment>
  );
}

SetpointValuesTooltip.propTypes = {
  // -- passed props
  setpoint: PropTypes.instanceOf(DeviceSetpointModel),
  type: PropTypes.oneOf(['edit', 'mask']).isRequired,
};

SetpointValuesTooltip.defaultProps = {
  type: 'edit',
};

export default SetpointValuesTooltip;
