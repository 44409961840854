/**
 * Select dropdown of Firmware Versions fetched from the API
 */
import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { actions as firmwareVersionsActions } from 'state/modules/firmware-versions';
import { APIClientContext } from 'api';
import { deviceTypeByRcpn } from 'utils';
import DeviceTypeDefinitions from 'data/device-types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
}));

export const FirmwareVersionSelect = (props) => {
  const {
    firmwareVersionValue,
    firmwareTypeValue,
    hardwareVersionValue,
    rcpnValue,
    onChange,
    onFirmwareTypeChange,
  } = props;
  const apiClient = useContext(APIClientContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const firmwareVersionCollection = useSelector((state) => state.firmwareVersions.collection);
  // const firmwareTypesLoading = useSelector(state => state.firmwareVersions.isFetching);
  const firmwareTypesError = useSelector((state) => state.firmwareVersions.fetchError);

  // fetch firmware versions when this component is loaded
  useEffect(() => {
    dispatch(firmwareVersionsActions.fetchAll(apiClient));
  }, [dispatch, apiClient]);

  const handleFirmwareTypeChange = (event) => {
    onFirmwareTypeChange(event.target.value);
  };

  const firmwareTypes = firmwareVersionCollection.firmwareTypes || [];
  let filteredFirmwareTypes = firmwareTypes;
  const deviceType = deviceTypeByRcpn(rcpnValue);

  // validate RCPn:FirmwareType first
  if (deviceType) {
    const deviceTypeDefinition = DeviceTypeDefinitions[deviceType];
    if (deviceTypeDefinition) {
      // filter to available FirmwareTypes
      filteredFirmwareTypes = firmwareTypes.filter((type) =>
        deviceTypeDefinition.firmwareTypes.includes(type)
      );
    }
  }

  const firmwareVersionMatches = firmwareVersionCollection.filter((model) => {
    const { hardwareVersion } = model.versionPathComponents;

    // if there is no hardware version subdirectory in the version path
    // it should always be available
    if (!hardwareVersion) {
      return true;
    } else {
      return Boolean(hardwareVersion === hardwareVersionValue);
    }
  });

  // only filter available firmware versions if user has selected a firmware type
  const filteredFirmwareVersionCollection = firmwareTypeValue
    ? firmwareVersionCollection.filter((model) => model.get('type') === firmwareTypeValue)
    : firmwareVersionCollection;

  return (
    <Fragment>
      <Box mb={3}>
        <FormControl className={classes.formControl} disabled={Boolean(firmwareTypesError)}>
          <InputLabel htmlFor="firmware-type">Firmware Type</InputLabel>
          <Select
            value={firmwareTypeValue}
            onChange={handleFirmwareTypeChange}
            inputProps={{
              name: 'firmwareType',
              id: 'firmware-type',
            }}
          >
            {firmwareTypes.map((firmwareType) => {
              const isEnabled = filteredFirmwareTypes.indexOf(firmwareType) > -1;
              return (
                <MenuItem key={firmwareType} value={firmwareType} disabled={!isEnabled}>
                  {firmwareType}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl
          className={classes.formControl}
          disabled={!firmwareTypeValue || Boolean(firmwareTypesError)}
        >
          <InputLabel htmlFor="firmware-version">Firmware Version</InputLabel>
          <Select
            value={firmwareVersionValue}
            onChange={onChange}
            inputProps={{
              name: 'firmwareVersion',
              id: 'firmware-version',
            }}
          >
            {filteredFirmwareVersionCollection.models.map((model) => {
              const versionPathComponents = model.versionPathComponents;
              let isEnabled = hardwareVersionValue
                ? Boolean(
                    firmwareVersionMatches.models.find(
                      (match) => match.get('version') === model.get('version')
                    )
                  )
                : true;

              // if there is no hardware version subdirectory in the version path
              // it should just be enabled by default (if its parent firmware type is enabled also)
              if (!versionPathComponents.hardwareVersion) {
                isEnabled = true;
              }

              return (
                <MenuItem key={model.id()} value={model.get('version')} disabled={!isEnabled}>
                  {model.get('version')}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {firmwareTypesError && (
        <Box mt={3}>
          <Typography variant="body2" color="error">
            Could not load firmware versions.
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

FirmwareVersionSelect.propTypes = {
  // -- passed props
  firmwareVersionValue: PropTypes.string,
  firmwareTypeValue: PropTypes.string,
  hardwareVersionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rcpnValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFirmwareTypeChange: PropTypes.func.isRequired,
};

FirmwareVersionSelect.defaultProps = {
  firmwareVersionValue: '',
  firmwareTypeValue: '',
  hardwareVersionValue: null,
  rcpnValue: '',
};
