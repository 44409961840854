import React, { useState } from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
  DialogTitle,
  Dialog,
  Box,
} from '@material-ui/core';
import { Label, CampaignPlanActionTypeAvatar } from 'components';
import { ActionStatus, ActionState } from 'types/Campaign';
import CampaignActionTypeDefinitions from 'data/campaign-action-types';
import CampaignActionStateDefinitions from 'data/campaign-action-states';
import { deviceTypeByRcpn } from 'utils/rcpn';
import { formatTimestamp } from 'utils';
// @todo rebuild this file in as typescript Definition file like data/campaign-action-types
import DeviceTypeDefinitions from 'data/device-types';
import { ActionType, ActionDeliverFirmwareData } from 'types/Campaign';
import { SetpointWriteData } from 'types/Setpoint';

interface Props {
  actionStatus: ActionStatus;
}

export const CampaignPlanActionStatusItem = (props: Props) => {
  const { actionStatus } = props;
  const { rcpn, actionType, actionState, startedDate, endedDate, message } = actionStatus;
  const deviceType = deviceTypeByRcpn(rcpn);
  const deviceTypeDefinition = deviceType ? DeviceTypeDefinitions[deviceType] : null;
  const actionTypeDefinition = CampaignActionTypeDefinitions[actionType];
  const actionStateDefinition = CampaignActionStateDefinitions[actionState];

  const [setpointsDialogIsOpen, setSetpointsDialogIsOpen] = useState<boolean>(false);

  const handleViewSetpointsData = (event: React.MouseEvent) => {
    setSetpointsDialogIsOpen(true);
  };

  const handleCloseDialog = () => {
    setSetpointsDialogIsOpen(false);
  };

  return (
    <>
      <ListItem disabled={actionState === ActionState.WAITING}>
        <ListItemAvatar>
          <CampaignPlanActionTypeAvatar actionType={actionType} actionState={actionState} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="h6">{actionTypeDefinition.name}</Typography>}
          secondary={
            <>
              <Typography variant="body2">RCPn: {rcpn}</Typography>
              <Typography variant="body2">
                Device type: {deviceTypeDefinition ? deviceTypeDefinition.name : 'Unknown'}
              </Typography>
              {actionType === ActionType.DELIVER_FIRMWARE && (
                <Typography variant="body2">
                  Hex file path: {(message?.data as ActionDeliverFirmwareData).hexFilePath}
                </Typography>
              )}
              {startedDate && (
                <Typography variant="body2">
                  Started date: {formatTimestamp(startedDate)}
                </Typography>
              )}
              {endedDate && (
                <Typography variant="body2">Ended date: {formatTimestamp(endedDate)}</Typography>
              )}
              {startedDate && endedDate && <Typography variant="body2">Duration: 137s</Typography>}
              {actionType === ActionType.WRITE_SETPOINTS && (
                <Button size="small" variant="outlined" onClick={handleViewSetpointsData}>
                  View Setpoints Data
                </Button>
              )}
            </>
          }
          disableTypography
        />
        <ListItemSecondaryAction>
          <Label color={actionStateDefinition.color}>{actionStateDefinition.name}</Label>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog open={setpointsDialogIsOpen} onClose={handleCloseDialog}>
        <>
          <DialogTitle>Write Setpoints Data</DialogTitle>
          <Box
            p={3}
            mx={3}
            mb={3}
            bgcolor="background.default"
            style={{ overflow: 'auto' }}
            component="pre"
          >
            {JSON.stringify(message?.data as SetpointWriteData, null, 4)}
          </Box>
        </>
      </Dialog>
    </>
  );
};
