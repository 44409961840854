import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, CircularProgress } from '@material-ui/core';
import CampaignActionTypeDefinitions from 'data/campaign-action-types';
import CampaignActionStateDefinitions from 'data/campaign-action-states';
import { ActionState, ActionType } from 'types/Campaign';

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
  },
  icon: {
    fontSize: 20,
  },
  loader: {
    position: 'absolute',
  },
}));

interface Props {
  actionType: ActionType;
  actionState: ActionState;
  className?: string;
}

export const CampaignPlanActionTypeAvatar = (props: Props) => {
  const classes = useStyles();

  const { actionType, actionState, className, ...rest } = props;
  const actionTypeDefinition = CampaignActionTypeDefinitions[actionType];
  const actionStateDefinition = CampaignActionStateDefinitions[actionState];

  const { icon: Icon, name } = actionTypeDefinition;
  const { color } = actionStateDefinition;

  return (
    <Box position="relative">
      {actionState === ActionState.RUNNING && (
        <CircularProgress className={classes.loader} thickness={2} color="secondary" />
      )}
      <Avatar
        {...rest}
        className={clsx(classes.avatar, className)}
        alt={name}
        style={{ borderColor: color, color: color }}
      >
        <Icon className={classes.icon} />
      </Avatar>
    </Box>
  );
};
