import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

export const RefreshButton = props => {
  const { buttonText, isLoading, onClick } = props;

  return (
    <Button size="small" color="secondary" onClick={onClick} disabled={isLoading}>
      <Fragment>
        {buttonText}
        {isLoading ? (
          <CircularProgress size={14} className="space-left-small" />
        ) : (
          <RefreshIcon className="space-left-smallest" fontSize="small" />
        )}
      </Fragment>
    </Button>
  );
};

RefreshButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

RefreshButton.defaultProps = {
  isLoading: false,
};
