import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import DeviceTypeDefinitions from 'data/device-types';
import { DeviceType } from 'types/Device';

const useStyles = makeStyles((theme) => ({
  root: {
    //--
  },
}));

export const DeviceIcon = ({ type, showTooltip, tooltipProps, iconStyle, className, ...rest }) => {
  const classes = useStyles();
  const deviceTypeData = DeviceTypeDefinitions[type];

  if (!deviceTypeData) {
    return null;
  }

  const { icon: Icon, color, name } = deviceTypeData;

  const style = Object.assign({}, { color }, iconStyle);

  const iconComponent = (
    <Icon {...rest} className={clsx(classes.root, className)} alt={name} style={style} />
  );

  if (showTooltip) {
    return (
      <Tooltip title={name} {...tooltipProps}>
        {iconComponent}
      </Tooltip>
    );
  } else {
    return iconComponent;
  }
};

DeviceIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(DeviceType)),
  showTooltip: PropTypes.bool,
  tooltipProps: PropTypes.object,
  iconStyle: PropTypes.object,
  className: PropTypes.string,
};
