export enum SunspecModelId {
  // all types
  COMMON = 1,
  // inverter
  INVERTER_PWRCELL_CONFIG = 64206,
  INVERTER_SPLIT_PHASE = 102,
  INVERTER_ARBITRAGE_CONTROL = 64202,
  INVERTER_RECONNECT_CONTROL = 64203,
  INVERTER_STATUS = 64208,
}

export interface SunspecModelListItem {
  id: number;
  name: string;
  label: string;
  description: string;
  writeable: boolean;
}

export interface SunspecModelListResponse {
  models: SunspecModelListItem[];
}

export interface SunspecModelDefinition {
  id: SunspecModelId | number;
  group: {
    desc: string;
    label: string;
    name: string;
    type: SunspecGroupType;
    points: SunspecPoint[];
    groups: SunspecGroup[];
  };
}

export interface SunspecGroup {
  count: number;
  name: string;
  points: SunspecPoint[];
}

export interface SunspecPoint {
  name: SunspecPointName | string;
  type: SunspecPointType;
  mandatory: string;
  symbols?: SunspecPointSymbol[];
  access?: string;
  desc?: string;
  label?: string;
  static?: string;
  value?: number;
  units?: string;
}

export interface SunspecPointSymbol {
  name: string;
  value: number;
  label?: string;
  desc?: string;
  detail?: string;
}

export enum SunspecPointType {
  PAD = 'pad',
  BITFIELD16 = 'bitfield16',
  BITFIELD32 = 'bitfield32',
  INT16 = 'int16',
  UINT16 = 'uint16',
  ENUM16 = 'enum16',
  SUNSSF = 'sunssf', // sunspec scale factor
  // @todo fill out the rest here
}

export enum SunspecGroupType {
  GROUP = 'group',
  // @todo fill out the rest here
}

export enum SunspecPointName {
  ID = 'ID',
  L = 'L',
}
