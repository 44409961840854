/**
 * Excel JS
 *
 * Read/Write for XLSX files
 *
 * @see https://github.com/exceljs/exceljs
 * @see https://github.com/exceljs/exceljs/issues/354
 */

// @see https://github.com/guyonroche/exceljs/blob/master/spec/browser/exceljs.spec.js
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import FileSaver from 'file-saver';

export const CSV = 'csv';
export const XLSX = 'xlsx';

const FILE_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const FILE_EXTENSION_XLSX = 'xlsx';

const FILE_TYPE_CSV = 'text/csv;charset=utf-8;';
const FILE_EXTENSION_CSV = 'csv';

export const alphabetArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
export const frozenHeaderViewConfig = { views: [{ state: 'frozen', ySplit: 1 }] };

/**
 * Converts a column number (1) to column letter (A)
 * @see https://stackoverflow.com/questions/21229180/convert-column-index-into-corresponding-column-letter
 */
export function columnToLetter(column) {
  let temp,
    letter = '';
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
}

export function createWorkbook() {
  return new ExcelJS.Workbook();
}

/**
 * Writes file and uses FileSaver lib to auto-download
 * @param {ExcelJS.Workbook} workbook
 * @param {String} filename
 */
export function saveWorkbookToExcel(workbook, filename) {
  return new Promise(resolve => {
    workbook.xlsx.writeBuffer({ useStyles: true }).then(data => {
      var blob = new Blob([data], { type: FILE_TYPE_XLSX });
      FileSaver.saveAs(blob, `${filename}.${FILE_EXTENSION_XLSX}`);
      resolve();
    });
  });
}

/**
 * Writes file and uses FileSaver lib to auto-download
 * @param {ExcelJS.Workbook} workbook
 * @param {String} filename
 */
export function saveWorkbookToCSV(workbook, filename) {
  return new Promise(resolve => {
    workbook.csv.writeBuffer().then(data => {
      var blob = new Blob([data], { type: FILE_TYPE_CSV });
      FileSaver.saveAs(blob, `${filename}.${FILE_EXTENSION_CSV}`);
      resolve();
    });
  });
}

export function styleHeaderCells(firstRow, options = {}) {
  let _options = Object.assign(
    {
      fillColor: 'ffc7f1f3',
      borderColor: '80000000',
    },
    options
  );

  firstRow.eachCell((cell, colNumber) => {
    cell.fill = { type: 'pattern', pattern: 'solid' };
    cell.fill.fgColor = { argb: _options.fillColor };
    cell.border = {
      top: { style: 'thin', color: { argb: _options.borderColor } },
      left: { style: 'thin', color: { argb: _options.borderColor } },
      bottom: { style: 'thin', color: { argb: _options.borderColor } },
      right: { style: 'thin', color: { argb: _options.borderColor } },
    };
  });
}
