import { includes } from 'lodash';
import moment from 'moment';
import { Model } from './Model';
import { ApiQueryGranularity } from 'types/ApiQuery';
import { JobEventType, JobType } from 'types/Job';
import * as Fields from 'data/device-fields';
import { DeviceType } from 'types/Device';
import { endpoints } from 'api';

// fields that exist on all Device Types
const baseFieldMap = {
  ...Fields.ID,
  ...Fields.MANUFACTURER,
  ...Fields.NAME,
  ...Fields.VERSION,
};

export const EVENTS_DEFAULT_QUERY = {
  start: null,
  end: null,
  sort: 'time:desc',
};

export const UPDATES_DEFAULT_QUERY = {
  start: null,
  end: null,
  sort: 'time:desc',
  granularity: ApiQueryGranularity.HOURS,
  interval: 1,
};

export const DCB_DATA_DEFAULT_QUERY = {
  start: null,
  end: null,
};

export class DeviceModel extends Model {
  get hwVersion() {
    const version = this.get('version');

    if (version && includes(version, '_')) {
      return version.split('_')[0];
    } else {
      return null;
    }
  }

  get swVersion() {
    const version = this.get('version');

    // if no underscore is present on "version", all contents are attributed to sw
    // @see https://pika-energy.atlassian.net/browse/TOOL-136
    if (version) {
      if (includes(version, '_')) {
        return version.split('_')[1];
      } else {
        return version;
      }
    } else {
      return null;
    }
  }

  async fetchEvents(apiClient, options) {
    if (!this.id()) {
      console.error('DeviceModel needs an id to fetch events');
    }

    let data = Object.assign({}, EVENTS_DEFAULT_QUERY, options);

    // convert start and end to correct unix timestamp format
    if (data.start) {
      data.start = moment(data.start).unix();
    } else {
      // if there is no start date, set default to 2 days before now
      // data.start = getUnixTime(subDays(new Date(), 2))
      throw new Error('Device Events query needs a start date');
    }

    if (data.end) {
      data.end = moment(data.end).unix();
    } else {
      // if there is no end date, set default to right now
      // data.end = getUnixTime(new Date())
      throw new Error('Device Events query needs an end date');
    }

    try {
      const response = await apiClient.get({
        path: endpoints.devices.events,
        ids: this.id(),
        data,
      });
      if (response.success === false) {
        throw Error('Could not get Device Events');
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  async fetchUpdates(apiClient, options) {
    if (!this.id()) {
      console.error('DeviceModel needs an id to fetch updates');
    }

    let data = Object.assign({}, UPDATES_DEFAULT_QUERY, options);

    // convert start and end to correct unix timestamp format
    if (data.start) {
      data.start = moment(data.start).unix();
    } else {
      // if there is no start date, set default to 2 days before now
      // data.start = getUnixTime(subDays(new Date(), 2))
      throw new Error('Device Updates query needs a start date');
    }

    if (data.end) {
      data.end = moment(data.end).unix();
    } else {
      // if there is no end date, set default to right now
      // data.end = getUnixTime(new Date())
      throw new Error('Device Updates query needs an end date');
    }

    try {
      const response = await apiClient.get({
        path: endpoints.devices.updates,
        ids: this.id(),
        data,
      });
      if (response.success === false) {
        throw Error('Could not get Device Updates');
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  async fetchLastUpdate(apiClient, options) {
    if (!this.id()) {
      throw Error('DeviceModel needs an id to fetch updates');
    }

    try {
      const response = await apiClient.get({
        path: endpoints.devices.updates,
        ids: this.id(),
        data: {
          limit: 1,
        },
      });
      if (response.success === false) {
        throw Error('Could not get Device Updates');
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  async fetchDCBData(apiClient, options) {
    if (!this.id()) {
      console.error('DeviceModel needs an id to fetch DCB Data');
    }

    let data = Object.assign({}, DCB_DATA_DEFAULT_QUERY, options);

    // convert start and end to correct unix timestamp format
    if (data.start) {
      data.start = moment(data.start).unix();
    } else {
      // if there is no start date, set default to 2 days before now
      // data.start = getUnixTime(subDays(new Date(), 2))
      throw new Error('Device DCB Data query needs a start date');
    }

    if (data.end) {
      data.end = moment(data.end).unix();
    } else {
      // if there is no end date, set default to right now
      // data.end = getUnixTime(new Date())
      throw new Error('Device DCB Data query needs an end date');
    }

    try {
      const response = await apiClient.get({
        path: endpoints.devices.dcbData,
        ids: this.id(),
        data,
      });
      if (response.success === false) {
        throw Error('Could not get Device DCB Data');
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  // setpoints

  async fetchRecentSetpointsJob(apiClient) {
    if (!this.id()) {
      console.error('DeviceModel needs an id to fetch recent setpoints');
    }

    try {
      const jobResponse = await apiClient.get({
        path: endpoints.device.jobs,
        data: {
          rcpn: this.id(),
          jobType: JobType.SPT_GET,
          status: JobEventType.SUCCESS,
          limit: 1,
        },
      });
      if (Array.isArray(jobResponse.jobs) && jobResponse.jobs.length) {
        return jobResponse.jobs[0].job_id;
      } else {
        throw Error('Could not get recent setpoints job');
      }
    } catch (error) {
      throw error;
    }
  }

  async fetchLiveSetpointsJob(apiClient) {
    if (!this.id()) {
      console.error('DeviceModel needs an id to fetch setpoints');
    }

    try {
      const response = await apiClient.get({
        path: endpoints.devices.getSetpoints,
        ids: this.id(),
      });
      if (response.success === false) {
        throw Error('Could not create get setpoints job');
      } else {
        return response.job_id;
      }
    } catch (error) {
      throw error;
    }
  }
}

// --

export class BeaconModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("0001001200AD")
      manufacturer: null, // string ("Pika")
      name: null, // string ("REbus Beacon")
      version: null, // string ("100_11320")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.BEACON;
    this.fieldMap = {
      ...baseFieldMap,
    };
  }
}

export class InverterModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      w_rtg: null, // number (7600)
      a_rtg: null, // number (32)
      va_rtg: null, // number (7600)
      id: null, // string ("000100070408")
      serial_number: null, // string ("X7602-01952")
      manufacturer: null, // string ("Pika")
      name: null, // string ("X7602 Islanding Inverter")
      version: null, // string ("151_22594")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.INVERTER;
    this.fieldMap = {
      ...baseFieldMap,
      ...Fields.W_RTG,
      ...Fields.A_RTG,
      ...Fields.VA_RTG,
    };
  }
}

export class ICMModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("000100070408")
      manufacturer: null, // string ("Pika")
      name: null, // string ("X7602 Islanding Inverter")
      version: null, // string ("151_22594")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.ICM;
    this.fieldMap = {
      ...baseFieldMap,
    };
  }
}

export class BatteryModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      ah_rtg: null, // number (53),
      wh_rtg: null, // number (14000)
      w_cha_max: null, // number (10944)
      w_discha_max: null, // number (10944)
      soc_max: null, // number (100)
      soc_min: null, // number (10)
      soc_rsv_max: null, // number(100)
      soc_rsv_min: null, // number (50)
      id: null, // string ("000100080175")
      manufacturer: null, // string ("Pika")
      name: null, // string ("Smart Battery")
      version: null, // string ("1215_12594")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.BATTERY;
    this.fieldMap = {
      ...baseFieldMap,
      ...Fields.AH_RTG,
      ...Fields.WH_RTG,
      ...Fields.W_CHA_MAX,
      ...Fields.W_DISCHA_MAX,
      ...Fields.SOC_MAX,
      ...Fields.SOC_MIN,
      ...Fields.SOC_RSV_MAX,
      ...Fields.SOC_RSV_MIN,
    };
  }
}

export class PVLModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      w_rtg: null, // number (2500)
      a_rtg: null, // number (13)
      id: null, // string ("0001000314F7")
      manufacturer: null, // string ("Pika")
      name: null, // string ("PV Link")
      version: null, // string ("574_13540")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.PVL;
    this.fieldMap = {
      ...baseFieldMap,
      ...Fields.W_RTG,
      ...Fields.A_RTG,
    };
  }
}
export class RGMModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("SAH4187AA1368")
      manufacturer: null, // string ("Generac")
      name: null, // string ("012.00020A.G")
      version: null, // string ("1.6.0")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.RGM;
    this.fieldMap = {
      ...baseFieldMap,
    };
  }
}

export class GeneratorModel extends DeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("0001000E14F7")
      manufacturer: null, // string ("Generac")
      name: null, // string ("Endure")
      version: null, // string ("101_025")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.GENERATOR;
    this.fieldMap = {
      ...baseFieldMap,
    };
  }
}
