import { includes } from 'lodash';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, MenuItem, Popover, CircularProgress } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TimeIcon from '@material-ui/icons/Schedule';
import HourglassIcon from '@material-ui/icons/HourglassEmpty';
import IntervalIcon from '@material-ui/icons/AvTimer';
import DownloadIcon from '@material-ui/icons/GetApp';
import { DateRangeInput, NumberInput } from 'components';
import { ApiQueryGranularityDefinitions, apiQueryGranularityList } from 'data/api-query-types';
import { ApiQueryType, ApiQueryGranularity } from 'types/ApiQuery';
import { ChartType } from 'types/Chart';
import { METRIC_BUCKET_ALL, METRIC_SOURCE_ALL } from 'data/site-metric-types';
import { formatTimestamp, TIMESTAMP_FORMAT_TO_MINUTES } from 'utils';
import { XLSX, CSV } from 'services/excel';
import { UTC } from 'data/timezones';
import moment from 'moment';

const BUCKET_DAY = 'BUCKET_DAY';
const BUCKET_WEEK = 'BUCKET_WEEK';
const BUCKET_MONTH = 'BUCKET_MONTH';
const BUCKET_YEAR = 'BUCKET_YEAR';

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    padding: theme.spacing(2),
  },
  bucketButton: {
    marginRight: 5,
  },
}));

export const QueryBuilderToolbar = (props) => {
  const {
    timezone,
    queryTypes,
    chartType,
    queryData,
    onQueryUpdate,
    onExport,
    onExportXLSX,
    onExportCSV,
    isExporting,
  } = props;

  const classes = useStyles();
  const [popoverData, setPopoverData] = useState({
    anchorEl: null,
    type: null,
    data: null,
  });
  const hasTimeframe = Boolean(queryData.start || queryData.end);

  const handleBucketClick = (bucketType) => {
    if (chartType === ChartType.DEVICE_EVENTS || chartType === ChartType.DCB_DATA) {
      switch (bucketType) {
        case BUCKET_DAY:
          onQueryUpdate({
            start: moment().subtract(24, 'h'), //24 hours ago
            end: moment(),
          });
          break;
        case BUCKET_WEEK:
          onQueryUpdate({
            start: moment().subtract(7, 'd'), //1 week ago
            end: moment(),
          });
          break;
        case BUCKET_MONTH:
          onQueryUpdate({
            start: moment().subtract(1, 'M'), //~ 1 month ago
            end: moment(),
          });
          break;
        case BUCKET_YEAR:
          onQueryUpdate({
            start: moment().subtract(12, 'M'), //1 year ago
            end: moment(),
          });
          break;
        default:
          break;
      }
    } else if (chartType === ChartType.DEVICE_UPDATES) {
      switch (bucketType) {
        case BUCKET_DAY:
          onQueryUpdate({
            interval: 1,
            granularity: ApiQueryGranularity.MINUTES,
            start: moment().subtract(24, 'h'), //24 hours ago
            end: moment(),
          });
          break;
        case BUCKET_WEEK:
          onQueryUpdate({
            interval: 1,
            granularity: ApiQueryGranularity.HOURS,
            start: moment().subtract(7, 'd'), //1 week ago
            end: moment(),
          });
          break;
        case BUCKET_MONTH:
          onQueryUpdate({
            interval: 1,
            granularity: ApiQueryGranularity.HOURS,
            start: moment().subtract(1, 'M'), //~ 1 month ago
            end: moment(),
          });
          break;
        case BUCKET_YEAR:
          onQueryUpdate({
            interval: 1,
            granularity: ApiQueryGranularity.DAYS,
            start: moment().subtract(12, 'M'), //1 year ago
            end: moment(),
          });
          break;
        default:
          break;
      }
    } else if (chartType === ChartType.SITE_METRICS) {
      switch (bucketType) {
        case BUCKET_DAY:
          onQueryUpdate({
            offset: 0,
            granularity: ApiQueryGranularity.HOURS,
            interval: 1,
            source: METRIC_SOURCE_ALL,
            metric: METRIC_BUCKET_ALL,
            start: moment().subtract(24, 'h'), //24 hours ago
            end: moment(),
          });
          break;
        case BUCKET_WEEK:
          onQueryUpdate({
            offset: 0,
            granularity: ApiQueryGranularity.HOURS,
            interval: 1,
            source: METRIC_SOURCE_ALL,
            metric: METRIC_BUCKET_ALL,
            start: moment().subtract(7, 'd'), //1 week ago
            end: moment(),
          });
          break;
        case BUCKET_MONTH:
          onQueryUpdate({
            offset: 0,
            granularity: ApiQueryGranularity.HOURS,
            interval: 1,
            source: METRIC_SOURCE_ALL,
            metric: METRIC_BUCKET_ALL,
            start: moment().subtract(1, 'M'), //~ 1 month ago
            end: moment(),
          });
          break;
        case BUCKET_YEAR:
          onQueryUpdate({
            offset: 0,
            granularity: ApiQueryGranularity.DAYS,
            interval: 1,
            source: METRIC_SOURCE_ALL,
            metric: METRIC_BUCKET_ALL,
            start: moment().subtract(12, 'M'), //1 year ago
            end: moment(),
          });
          break;
        default:
          break;
      }
    }
  };

  function handleOpenPopover(event, type, data) {
    setPopoverData({
      anchorEl: event.currentTarget,
      type,
      data,
    });
  }

  function handleClosePopover() {
    setPopoverData({});
  }

  return (
    <Fragment>
      <Box display="flex" alignItems="center">
        <Box display="flex" flexWrap="wrap">
          {includes(queryTypes, ApiQueryType.TIMEFRAME) && (
            <Box mr={1}>
              <Button
                variant="outlined"
                color={hasTimeframe ? 'secondary' : 'default'}
                onClick={(event) => handleOpenPopover(event, ApiQueryType.TIMEFRAME)}
                disableRipple
              >
                <Fragment>
                  <TimeIcon className="space-right-smallest" fontSize="small" />
                  {hasTimeframe
                    ? `${formatTimestamp(
                        queryData.start,
                        timezone,
                        TIMESTAMP_FORMAT_TO_MINUTES
                      )} ... ${formatTimestamp(
                        queryData.end,
                        timezone,
                        TIMESTAMP_FORMAT_TO_MINUTES
                      )}`
                    : 'Timeframe'}
                  <ArrowDropDownIcon className="space-left-smallest" />
                </Fragment>
              </Button>
            </Box>
          )}
          {includes(queryTypes, ApiQueryType.GRANULARITY) && (
            <Box mr={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={(event) => handleOpenPopover(event, ApiQueryType.GRANULARITY)}
                disableRipple
              >
                <Fragment>
                  <HourglassIcon className="space-right-smallest" fontSize="small" />
                  {ApiQueryGranularityDefinitions[queryData.granularity].name}
                  <ArrowDropDownIcon className="space-left-smallest" />
                </Fragment>
              </Button>
            </Box>
          )}
          {includes(queryTypes, ApiQueryType.INTERVAL) && (
            <Box mr={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={(event) => handleOpenPopover(event, ApiQueryType.INTERVAL)}
                disableRipple
              >
                <Fragment>
                  <IntervalIcon className="space-right-smallest" fontSize="small" />
                  {`Interval by ${queryData.interval}`}
                  <ArrowDropDownIcon className="space-left-smallest" />
                </Fragment>
              </Button>
            </Box>
          )}
        </Box>
        <div className="flex-grow" />
        {onExportXLSX && (
          <Box mr={1}>
            <Button
              size="small"
              color="secondary"
              onClick={onExportXLSX}
              disableRipple
              disabled={Boolean(isExporting === XLSX)}
            >
              <Fragment>
                {isExporting === XLSX ? (
                  <CircularProgress size={14} className="space-right-small" />
                ) : (
                  <DownloadIcon className="space-right-smallest" fontSize="small" />
                )}
                XLSX
              </Fragment>
            </Button>
          </Box>
        )}
        {onExportCSV && (
          <Box>
            <Button
              size="small"
              color="secondary"
              onClick={onExportCSV}
              disableRipple
              disabled={Boolean(isExporting === CSV)}
            >
              <Fragment>
                {isExporting === CSV ? (
                  <CircularProgress size={14} className="space-right-small" />
                ) : (
                  <DownloadIcon className="space-right-smallest" fontSize="small" />
                )}
                CSV
              </Fragment>
            </Button>
          </Box>
        )}
        {onExport && (
          <Box>
            <Button size="small" color="secondary" onClick={onExport} disableRipple>
              <Fragment>
                {isExporting ? (
                  <CircularProgress size={14} className="space-right-small" />
                ) : (
                  <DownloadIcon className="space-right-smallest" fontSize="small" />
                )}
                Export
              </Fragment>
            </Button>
          </Box>
        )}
      </Box>
      <Popover
        id={popoverData.anchorEl ? 'query-data-popover' : undefined}
        open={Boolean(popoverData.anchorEl)}
        anchorEl={popoverData.anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -10, horizontal: 'left' }}
      >
        <Fragment>
          {popoverData.type === ApiQueryType.TIMEFRAME && (
            <div className={classes.popoverContent}>
              <DateRangeInput
                timezone={timezone}
                fromInputLabel="Start"
                toInputLabel="End"
                defaultFromValue={queryData.start}
                defaultToValue={queryData.end}
                name="from_date"
                onUpdate={(fromDate, toDate) => {
                  handleClosePopover();
                  onQueryUpdate({
                    start: fromDate,
                    end: toDate,
                  });
                }}
              />
            </div>
          )}
          {popoverData.type === ApiQueryType.GRANULARITY && (
            <Fragment>
              {apiQueryGranularityList.map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  onClick={() => {
                    handleClosePopover();
                    onQueryUpdate({ granularity: type });
                  }}
                  disableRipple
                >
                  {ApiQueryGranularityDefinitions[type].name}
                </MenuItem>
              ))}
            </Fragment>
          )}
          {popoverData.type === ApiQueryType.INTERVAL && (
            <div className={classes.popoverContent}>
              <NumberInput
                label=""
                inputProps={{ min: 1 }}
                value={queryData.interval}
                onUpdate={(value) => {
                  handleClosePopover();
                  onQueryUpdate({ interval: parseInt(value, 10) });
                }}
                validate={(value) => Boolean(value && value > 0)}
              />
            </div>
          )}
        </Fragment>
      </Popover>
      <Box marginTop={1} mt={1}>
        <Button
          className={classes.bucketButton}
          onClick={() => handleBucketClick(BUCKET_DAY)}
          size="small"
          color="secondary"
        >
          Last Day
        </Button>
        <Button
          className={classes.bucketButton}
          onClick={() => handleBucketClick(BUCKET_WEEK)}
          size="small"
          color="secondary"
        >
          Last Week
        </Button>
        <Button
          className={classes.bucketButton}
          onClick={() => handleBucketClick(BUCKET_MONTH)}
          size="small"
          color="secondary"
        >
          Last Month
        </Button>
        <Button
          className={classes.bucketButton}
          onClick={() => handleBucketClick(BUCKET_YEAR)}
          size="small"
          color="secondary"
        >
          Last Year
        </Button>
      </Box>
    </Fragment>
  );
};

QueryBuilderToolbar.propTypes = {
  timezone: PropTypes.string.isRequired,
  queryTypes: PropTypes.array.isRequired,
  chartType: PropTypes.number,
  queryData: PropTypes.object.isRequired,
  onQueryUpdate: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  onExportXLSX: PropTypes.func,
  onExportCSV: PropTypes.func,
  isExporting: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

QueryBuilderToolbar.defaultProps = {
  timezone: UTC,
  queryTypes: [],
};
