import React from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { APIClientContext, APIClient } from 'api';
import { createBrowserHistory } from 'history';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ScrollReset } from 'components';
import store from './state';
import theme from 'theme';
import { ConfigContext, Config } from './application-config';
import routes from 'routes';
import './assets/scss/index.css';

const history = createBrowserHistory();

const App: React.FunctionComponent<Config> = (config: Config) => {
  const apiClient = new APIClient(config);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConfigContext.Provider value={config}>
            <APIClientContext.Provider value={apiClient}>
              <Router history={history}>
                <ScrollReset />
                {renderRoutes(routes)}
              </Router>
            </APIClientContext.Provider>
          </ConfigContext.Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
