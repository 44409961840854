/* eslint-disable react/no-multi-comp */
import React, { Fragment, useContext, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  IconButton,
  MenuItem,
  Popover,
  colors,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { NavItem, RegistrationLookup } from 'components';
import { navigationConfig } from 'navigation-config';
import { testRoleAccess } from 'role-config';
import { ConfigContext } from 'application-config';
import { APIClientContext, endpoints } from 'api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    // cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
}));

function renderNavItems({
  // eslint-disable-next-line react/prop-types
  items,
  subheader,
  key,
  ...rest
}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, userRole, pathname, item, depth = 0 }) {
  // [role-access]
  // check for user's role before allowing nav buttons to render
  const hasAccess = item.roles ? testRoleAccess(item.href, item.roles, userRole) : true;
  // features can be enabled/disabled via public/pwrtoolsConfig.json and
  const isDisabled = item.disabled === true;

  if (hasAccess && !isDisabled) {
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false,
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={item.href}
          label={item.label}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            userRole,
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

export const ToolsNav = ({ openMobile, onMobileClose, className, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const apiClient = useContext(APIClientContext);
  const appConfig = useContext(ConfigContext);
  const navigationItems = navigationConfig(appConfig);
  const session = useSelector((state) => state.session);
  const { user: userModel, userRole } = session;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const imageSrc = apiClient.urlCreator.createUrl({
    path: endpoints.proxy.sessionPhoto,
    ids: {},
  });

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navigationItems.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
            userRole,
          })
        )}
      </nav>
      <RegistrationLookup />
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <Avatar alt={userModel.fullName} className={classes.avatar} src={imageSrc} />
        <div className={classes.details}>
          <Typography variant="h5" color="textPrimary">
            {userModel.fullName}
          </Typography>
          <Typography variant="overline">{userRole}</Typography>
        </div>
        <IconButton className={classes.moreButton} size="small" onClick={handleOpenMenu}>
          <MoreIcon />
        </IconButton>
        <Popover
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MenuItem component={RouterLink} to="/logout">
            Sign out
          </MenuItem>
        </Popover>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

ToolsNav.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};
