import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Avatar, Typography } from '@material-ui/core';
import { GREY } from 'theme';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function SetpointHeader(props) {
  const { title, subtitle, icon, iconColor, action } = props;
  const classes = useStyles();

  return (
    <Box mb={5} display="flex" alignItems="center">
      {icon && (
        <Avatar className={classes.avatar} style={{ backgroundColor: iconColor }} variant="rounded">
          {icon}
        </Avatar>
      )}
      <Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
      {action && (
        <Fragment>
          <div className="flex-grow" />
          {action}
        </Fragment>
      )}
    </Box>
  );
}

SetpointHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconColor: PropTypes.string.isRequired,
  action: PropTypes.node,
};

SetpointHeader.defaultProps = {
  iconColor: GREY,
};

export default SetpointHeader;
