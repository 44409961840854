import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

const SIZE_SMALL = 'small';
const SIZE_MEDIUM = 'medium';

export const RefreshIconButton = props => {
  const { className, size, isLoading, onClick, tooltipText, tooltipPlacement } = props;

  if (isLoading) {
    return (
      <IconButton className={className} size={size} color="secondary" disabled>
        <CircularProgress color="secondary" thickness={3} size={24} />
      </IconButton>
    );
  } else {
    const buttonComponent = (
      <IconButton className={className} size={size} color="secondary" onClick={onClick}>
        <RefreshIcon fontSize="default" />
      </IconButton>
    );

    if (tooltipText) {
      return (
        <Tooltip title={tooltipText} placement={tooltipPlacement}>
          {buttonComponent}
        </Tooltip>
      );
    } else {
      return buttonComponent;
    }
  }
};

RefreshIconButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE_SMALL, SIZE_MEDIUM]),
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

RefreshIconButton.defaultProps = {
  size: SIZE_MEDIUM,
  isLoading: false,
  tooltipPlacement: 'top',
};
