import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { OverlayLoader } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: 400,
  },
}));

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

export const PageBody = (props: Props) => {
  const classes = useStyles();
  const { isLoading, children, ...rest } = props;

  return (
    <Box className={classes.container} {...rest}>
      <OverlayLoader loading={isLoading} showBackground={false} showSpinner />
      {children}
    </Box>
  );
};
