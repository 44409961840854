import { includes } from 'lodash';
import { Model } from './Model';

export class LiveDeviceCommonModel extends Model {
  constructor(fields) {
    const defaultFields = {
      created_at: null, // unix timestamp
      model_name: null, // string
      model_id: null, // number
      update: {
        fixed: {
          Mn: null, // string ("Pika")
          Md: null, // string ("REbus Beacon")
          Vr: null, // string ("100_11330")
          SN: null, // string (""0001001200C1"")
        },
      },
    };

    super(Object.assign(defaultFields, fields));
  }

  get fixedData() {
    return this.get('update') && this.get('update').fixed ? this.get('update').fixed : {};
  }

  get hwVersion() {
    const fixedData = this.fixedData;

    if (fixedData.Vr && includes(fixedData.Vr, '_')) {
      return fixedData.Vr.split('_')[0];
    } else {
      return null;
    }
  }

  get swVersion() {
    const fixedData = this.fixedData;

    // if no underscore is present on "Vr", all contents are attributed to sw
    // @see https://pika-energy.atlassian.net/browse/TOOL-136
    if (fixedData.Vr) {
      if (includes(fixedData.Vr, '_')) {
        return fixedData.Vr.split('_')[1];
      } else {
        return fixedData.Vr;
      }
    } else {
      return null;
    }
  }
}
