import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography, Paper, Button, Box } from '@material-ui/core';
import { TimeagoTooltip } from 'components';
import { Campaign } from 'types/Campaign';
import { BRIGHT_RED, WHITE } from 'theme';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
  },
  scrubButton: {
    backgroundColor: BRIGHT_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: BRIGHT_RED,
    },
  },
}));

interface Props {
  campaign: Campaign;
}

export const CampaignInfo = (props: Props) => {
  const classes = useStyles();
  const { campaign } = props;

  return (
    <Paper className={classes.paper}>
      <List>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Campaign Id</Typography>
          <Typography variant="h6">{campaign.id}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Name</Typography>
          <Typography variant="h6">{campaign.name}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Created by</Typography>
          <Typography variant="h6">{campaign.createdBy}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Created at</Typography>
          <TimeagoTooltip typographyProps={{ variant: 'h6' }} timestamp={campaign.createdDate} />
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Start date</Typography>
          <TimeagoTooltip
            typographyProps={{ variant: 'h6' }}
            timestamp={campaign.campaignStartDate}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="subtitle2">Scheduling Profile</Typography>
          <Typography variant="h6">{campaign.schedulingProfile[0]}</Typography>
        </ListItem>
      </List>
      <Box>
        <Button className={classes.scrubButton}>Cancel Campaign</Button>
      </Box>
    </Paper>
  );
};
