/**
 * @see https://docs.google.com/document/d/1ZjS4qVpFiuNjdn5HazTpaD0Wx2lS8Udi9AS-019i9PI/edit
 */

import DataUnitDefinitions from 'data/data-units';
import { DataUnit } from 'types/DataUnit';

// -- BUCKET TYPES
export const METRIC_BUCKET_ALL = 'all';
export const METRIC_BUCKET_ENERGY = 'energy';
export const METRIC_BUCKET_POWER = 'power';

export const bucketMap = {
  [METRIC_BUCKET_ALL]: 'All',
  [METRIC_BUCKET_ENERGY]: 'Energy',
  [METRIC_BUCKET_POWER]: 'Power',
};

export const bucketList = [METRIC_BUCKET_ALL, METRIC_BUCKET_ENERGY, METRIC_BUCKET_POWER];

// -- SOURCE TYPES
export const METRIC_SOURCE_ALL = 'all';
export const METRIC_SOURCE_INVERTER = 'inverter';
export const METRIC_SOURCE_BATTERY = 'battery';
export const METRIC_SOURCE_CONSUMPTION = 'consumption';
export const METRIC_SOURCE_GENERATION = 'generation';

export const sourceMap = {
  [METRIC_SOURCE_ALL]: 'All',
  [METRIC_SOURCE_INVERTER]: 'Inverter',
  [METRIC_SOURCE_BATTERY]: 'Battery',
  [METRIC_SOURCE_CONSUMPTION]: 'Consumption',
  [METRIC_SOURCE_GENERATION]: 'Generation',
};

export const sourceList = [
  METRIC_SOURCE_ALL,
  METRIC_SOURCE_INVERTER,
  METRIC_SOURCE_BATTERY,
  METRIC_SOURCE_CONSUMPTION,
  METRIC_SOURCE_GENERATION,
];

// -- METRIC TYPES
// -- returned with METRIC_BUCKET_ENERGY
export const METRIC_TYPE_BATTERY_ENERGY_IN = 'battery_energy_in';
export const METRIC_TYPE_BATTERY_ENERGY_OUT = 'battery_energy_out';
export const METRIC_TYPE_CONSUMPTION_ENERGY = 'consumption_energy';
export const METRIC_TYPE_GENERATION_ENERGY = 'generation_energy';
export const METRIC_TYPE_INVERTER_ENERGY_IN = 'inverter_energy_in';
export const METRIC_TYPE_INVERTER_ENERGY_OUT = 'inverter_energy_out';
export const METRIC_TYPE_NET_ENERGY_IN = 'net_energy_in';
export const METRIC_TYPE_NET_ENERGY_OUT = 'net_energy_out';
// -- returned with METRIC_BUCKET_POWER
export const METRIC_TYPE_BATTERY_POWER = 'battery_power';
export const METRIC_TYPE_CONSUMPTION_POWER = 'consumption_power';
export const METRIC_TYPE_GENERATION_POWER = 'generation_power';
export const METRIC_TYPE_INVERTER_POWER = 'inverter_power';
export const METRIC_TYPE_NET_POWER = 'net_power';
// -- always returned
export const METRIC_TYPE_CREATED_AT = 'created_at';
export const METRIC_TYPE_NOMINAL_CREATED_AT = 'nominal_created_at';
export const METRIC_TYPE_START_TIME = 'start_time';
export const METRIC_TYPE_NOMINAL_START_TIME = 'nominal_start_time';
export const METRIC_TYPE_BATTERY_SOC = 'battery_soc';
export const METRIC_TYPE_SYSMODE = 'sysmode'; // non-numerical
export const METRIC_TYPE_ALARMS = 'alarms'; // non-numerical

// these are in spreadsheet column order
export const map = {
  // -- metadata
  [METRIC_TYPE_CREATED_AT]: {
    name: 'Created at',
    ...DataUnitDefinitions[DataUnit.TIME],
  },
  [METRIC_TYPE_NOMINAL_CREATED_AT]: {
    name: 'Nominal created at',
    ...DataUnitDefinitions[DataUnit.TIME],
  },
  [METRIC_TYPE_START_TIME]: {
    name: 'Start time',
    ...DataUnitDefinitions[DataUnit.TIME],
  },
  [METRIC_TYPE_NOMINAL_START_TIME]: {
    name: 'Nominal start time',
    ...DataUnitDefinitions[DataUnit.TIME],
  },
  [METRIC_TYPE_SYSMODE]: {
    name: 'System Mode',
    ...DataUnitDefinitions[DataUnit.CATEGORY],
  },
  [METRIC_TYPE_ALARMS]: {
    name: 'Alarms',
    ...DataUnitDefinitions[DataUnit.CATEGORY],
  },
  [METRIC_TYPE_BATTERY_SOC]: {
    name: 'Battery SOC',
    ...DataUnitDefinitions[DataUnit.CHARGE_STATE],
  },
  // -- energy
  [METRIC_TYPE_BATTERY_ENERGY_IN]: {
    name: 'Battery energy in',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_BATTERY_ENERGY_OUT]: {
    name: 'Battery energy out',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_CONSUMPTION_ENERGY]: {
    name: 'Consumption energy',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_GENERATION_ENERGY]: {
    name: 'Generation energy',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_INVERTER_ENERGY_IN]: {
    name: 'Inverter energy in',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_INVERTER_ENERGY_OUT]: {
    name: 'Inverter energy out',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_NET_ENERGY_IN]: {
    name: 'Net energy in',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  [METRIC_TYPE_NET_ENERGY_OUT]: {
    name: 'Net energy out',
    ...DataUnitDefinitions[DataUnit.ENERGY_KILOWATT_HOURS],
  },
  // -- power
  [METRIC_TYPE_BATTERY_POWER]: {
    name: 'Battery power',
    ...DataUnitDefinitions[DataUnit.POWER_WATTS],
  },
  [METRIC_TYPE_CONSUMPTION_POWER]: {
    name: 'Consumption power',
    ...DataUnitDefinitions[DataUnit.POWER_WATTS],
  },
  [METRIC_TYPE_GENERATION_POWER]: {
    name: 'Generation power',
    ...DataUnitDefinitions[DataUnit.POWER_WATTS],
  },
  [METRIC_TYPE_INVERTER_POWER]: {
    name: 'Inverter power',
    ...DataUnitDefinitions[DataUnit.POWER_WATTS],
  },
  [METRIC_TYPE_NET_POWER]: {
    name: 'Net power',
    ...DataUnitDefinitions[DataUnit.POWER_WATTS],
  },
};

export const metricList = [
  METRIC_TYPE_BATTERY_ENERGY_IN,
  METRIC_TYPE_BATTERY_ENERGY_OUT,
  METRIC_TYPE_BATTERY_SOC,
  METRIC_TYPE_CONSUMPTION_ENERGY,
  METRIC_TYPE_GENERATION_ENERGY,
  METRIC_TYPE_INVERTER_ENERGY_IN,
  METRIC_TYPE_INVERTER_ENERGY_OUT,
  METRIC_TYPE_NET_ENERGY_IN,
  METRIC_TYPE_NET_ENERGY_OUT,
  METRIC_TYPE_BATTERY_POWER,
  METRIC_TYPE_CONSUMPTION_POWER,
  METRIC_TYPE_GENERATION_POWER,
  METRIC_TYPE_INVERTER_POWER,
  METRIC_TYPE_NET_POWER,
  METRIC_TYPE_SYSMODE,
];

/* value examples
alarms: []
battery_energy_in: null
battery_energy_out: null
battery_power: null
battery_soc: 0
consumption_energy: 575772288
consumption_power: -3
created_at: 1563746340
generation_energy: 317815200
generation_power: 0
inverter_energy_in: 2447512560
inverter_energy_out: 7451896592
inverter_power: -66
net_energy_in: 2936601616
net_energy_out: 7365213360
net_power: -62
nominal_created_at: 1563746400
nominal_start_time: 1563742800
start_time: 1563742740
sysmode: "Priority Backup"
*/
