/**
 * @see https://material-ui.com/components/tables/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import { MonoTableCell } from 'components';

export const SimpleTableCell = ({ content, options }) => {
  const { monospace, ...rest } = options;

  return monospace ? (
    <MonoTableCell {...rest}>{content}</MonoTableCell>
  ) : (
    <TableCell {...rest}>{content}</TableCell>
  );
};

SimpleTableCell.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  options: PropTypes.object.isRequired,
};

SimpleTableCell.defaultProps = {
  options: {},
  content: '',
};
