import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { TimeagoTooltip } from 'components';
import { useInterval } from 'hooks';
import { BRIGHT_GREEN, BRIGHT_RED } from 'theme';

const DEFAULT_LABEL = 'Last fetched';

const useStyles = makeStyles(theme => ({
  successIcon: {
    color: BRIGHT_GREEN,
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  warningIcon: {
    color: BRIGHT_RED,
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
}));

export const TimeagoLabel = props => {
  const { timestamp, showWarning, label } = props;
  const classes = useStyles();
  const [resetCount, setResetCount] = useState(0);

  // this will ensure rerendering of the timeago value to keep it updated
  useInterval(() => {
    setResetCount(resetCount + 1);
  }, 5000);

  if (!timestamp) {
    return null;
  }

  let isWarning = moment.utc(timestamp).isBefore(moment.utc().subtract(1, 'hour'));

  return (
    <Box display="flex" alignItems="center" mt={1}>
      {Boolean(showWarning && isWarning) && <WarningIcon className={classes.warningIcon} />}
      {Boolean(showWarning && !isWarning) && <SuccessIcon className={classes.successIcon} />}
      <Box>
        <Typography variant="caption">{`${label} `}</Typography>
        <TimeagoTooltip timestamp={timestamp} typographyProps={{ variant: 'caption' }} />
      </Box>
    </Box>
  );
};

TimeagoLabel.propTypes = {
  timestamp: PropTypes.number,
  showWarning: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

TimeagoLabel.defaultProps = {
  showWarning: true,
  label: DEFAULT_LABEL,
};
