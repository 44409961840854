import { isNil } from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeviceSetpointModel } from 'models';
import { TextTooltip } from 'components';

const useStyles = makeStyles(theme => ({
  boldValue: {
    fontWeight: 500,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function SetpointDefaultValuesTooltip(props) {
  const { defaultValue } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (isNil(defaultValue)) {
    return <TextTooltip text="n/a" tooltip="No default value data available" />;
  }

  // create new Setpoint model to convert values
  const spt = new DeviceSetpointModel({ value: defaultValue, default: defaultValue });

  return (
    <Fragment>
      <Box
        display="inline-flex"
        alignItems="center"
        onMouseEnter={event => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        className="dashed-underline"
        px={0.5}
      >
        <Typography className={classes.boldValue}>{spt.get('default')}</Typography>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: -15,
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        elevation={6}
      >
        <Box>
          <Typography className={classes.boldValue}>{spt.initialSignedValue}</Typography>
          <Typography className={classes.boldValue}>{spt.initialUnsignedValue}</Typography>
          <Typography className={classes.boldValue}>{spt.initialHexValue}</Typography>
        </Box>
      </Popover>
    </Fragment>
  );
}

SetpointDefaultValuesTooltip.propTypes = {
  // -- passed props
  defaultValue: PropTypes.number,
};

export default SetpointDefaultValuesTooltip;
