import { uniq } from 'lodash';
import { Collection } from './Collection';
import { DeviceSetpointModel } from './DeviceSetpointModel';

export class DeviceSetpointCollection extends Collection {
  constructor(data) {
    super(data, DeviceSetpointModel);
  }

  get tableOptions() {
    return uniq(this.models.map(model => model.get('table')));
  }

  /**
   * Creates a count of how many setpoints within a given "Table" have non-default values
   */
  get nonDefaultValuesMap() {
    let nonDefaultValuesMap = {};
    this.tableOptions.forEach(tableOption => {
      let count = 0;
      this.models
        .filter(model => model.get('table') === tableOption)
        .forEach(model => {
          if (!model.isDefault && model.get('index') !== 0) {
            count++;
          }
        });
      nonDefaultValuesMap[tableOption] = count;
    });

    return nonDefaultValuesMap;
  }
}
