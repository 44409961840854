import { combineReducers } from '@reduxjs/toolkit';
import session from './modules/session';
import firmwareVersions from './modules/firmware-versions';
import jobs from './modules/jobs';
import sites from './modules/sites';
import chartSettings from './modules/chart-settings';
import campaign from './modules/campaign';
import campaigns from './modules/campaigns';

const rootReducer = combineReducers({
  session,
  firmwareVersions,
  jobs,
  sites,
  chartSettings,
  campaign,
  campaigns,
});

export default rootReducer;
