export enum ApiQueryType {
  TIMEFRAME = 'timeframe',
  GRANULARITY = 'granularity',
  INTERVAL = 'interval',
}

export enum ApiQueryGranularity {
  YEARS = 'years',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}
