import produce from 'immer';
import { createReducer, ActionType, getType } from 'typesafe-actions';
import type { Campaign, CampaignStatus } from 'types/Campaign';
import * as actions from './actions';

export interface CampaignState {
  campaign: Campaign | null;
  campaignStatus: CampaignStatus | null;
  isFetchingCampaign: boolean;
  isFetchingCampaignStatus: boolean;
  campaignError: Error | null;
  campaignStatusError: Error | null;
}

const INITIAL_STATE: CampaignState = {
  campaign: null,
  campaignStatus: null,
  isFetchingCampaign: false,
  isFetchingCampaignStatus: false,
  campaignError: null,
  campaignStatusError: null,
};

export type CampaignActions = ActionType<typeof actions>;

export default createReducer<CampaignState, CampaignActions>(INITIAL_STATE, {
  [getType(actions.fetchCampaign.request)]: produce(
    (state: CampaignState, action: ReturnType<typeof actions.fetchCampaign.request>) => {
      state.isFetchingCampaign = true;
      state.campaignError = null;
    }
  ),
  [getType(actions.fetchCampaign.success)]: produce(
    (state: CampaignState, action: ReturnType<typeof actions.fetchCampaign.success>) => {
      state.isFetchingCampaign = false;
      state.campaignError = null;
      state.campaign = action.payload;
    }
  ),
  [getType(actions.fetchCampaign.failure)]: produce(
    (state: CampaignState, action: ReturnType<typeof actions.fetchCampaign.failure>) => {
      state.isFetchingCampaign = false;
      state.campaignError = action.payload;
    }
  ),
  [getType(actions.fetchCampaignStatus.request)]: produce(
    (state: CampaignState, action: ReturnType<typeof actions.fetchCampaignStatus.request>) => {
      state.isFetchingCampaignStatus = true;
      state.campaignStatusError = null;
    }
  ),
  [getType(actions.fetchCampaignStatus.success)]: produce(
    (state: CampaignState, action: ReturnType<typeof actions.fetchCampaignStatus.success>) => {
      state.isFetchingCampaignStatus = false;
      state.campaignStatusError = null;
      state.campaignStatus = action.payload;
    }
  ),
  [getType(actions.fetchCampaignStatus.failure)]: produce(
    (state: CampaignState, action: ReturnType<typeof actions.fetchCampaignStatus.failure>) => {
      state.isFetchingCampaignStatus = false;
      state.campaignStatusError = action.payload;
    }
  ),
});
