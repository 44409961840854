import { isEmpty } from 'lodash';
import React from 'react';
import { Ace } from 'ace-builds';
import { makeStyles } from '@material-ui/styles';
import { Typography, Card, CardHeader, Box } from '@material-ui/core';
import { LiveDevicesModel } from 'models';
import { DeviceAvatar, CampaignPlanActionTemplateButton, TimeagoTooltip } from 'components';
import {
  Action,
  actionDeliverFirmwareTemplate,
  actionDisableDeviceTemplate,
  actionEnableDeviceTemplate,
  actionWriteSetpointsTemplate,
} from 'types/Campaign';

const useStyles = makeStyles((theme) => ({
  deviceAvatar: {
    width: 34,
    height: 34,
  },
}));

const actionTemplateButtonList: Array<{ title: string; template: Action }> = [
  {
    title: 'Firmware',
    template: actionDeliverFirmwareTemplate,
  },
  {
    title: 'Disable',
    template: actionDisableDeviceTemplate,
  },
  {
    title: 'Enable',
    template: actionEnableDeviceTemplate,
  },
  {
    title: 'Setpoints',
    template: actionWriteSetpointsTemplate,
  },
];

interface Props {
  liveDevicesModel: LiveDevicesModel;
  onActionClick: (deviceRcpn: string, template: Action) => void;
  jsonValidation: Ace.Annotation[];
}

export const CampaignPlanDevicesList = (props: Props) => {
  const { liveDevicesModel, onActionClick, jsonValidation } = props;
  const classes = useStyles();
  const hasValidationErrors = !isEmpty(jsonValidation);

  return (
    <>
      {liveDevicesModel.deviceModels.map((model, index) => {
        const deviceType = model.deviceType;
        return (
          <Card key={`device_${model.get('id')}_${index}`} className="space-bottom-small">
            <CardHeader
              avatar={<DeviceAvatar className={classes.deviceAvatar} type={deviceType} />}
              title={<Typography variant="body2">{model.get('name')}</Typography>}
              subheader={
                <>
                  <Typography variant="body2">{model.get('id')}</Typography>
                  <TimeagoTooltip
                    timestamp={model.get('last_heard')}
                    typographyProps={{ variant: 'body2' }}
                  />
                </>
              }
              disableTypography
              titleTypographyProps={{ variant: 'subtitle2' }}
              action={
                <Box display="flex" mt={2}>
                  {actionTemplateButtonList.map((button, index) => {
                    return (
                      <Box mr={1} key={`action_template_button_${index}`}>
                        <CampaignPlanActionTemplateButton
                          title={button.title}
                          template={button.template}
                          disabled={hasValidationErrors}
                          onClick={(template: Action) => {
                            onActionClick(model.get('id'), template);
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              }
            />
          </Card>
        );
      })}
    </>
  );
};
