import { compact, isEmpty, isNil } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Box, Typography } from '@material-ui/core';
import { APIClientContext, endpoints } from 'api';
import { OverlayLoader, SimpleTable, Label, Alert } from 'components';
import {
  SunspecModelDefinition,
  SunspecGroup,
  SunspecPoint,
  SunspecPointSymbol,
  SunspecPointType,
  SunspecModelId,
  SunspecPointName,
} from 'types/SunspecModel';
import { DARK_BLUE, GREY } from 'theme';

const tableHeaderRows = [
  { id: 'name', label: 'Name' },
  { id: 'label', label: 'Label' },
  { id: 'desc', label: 'Description' },
  { id: 'value', label: 'Value' },
  { id: 'units', label: 'Units' },
  { id: 'symbols', label: 'Symbols' },
  { id: 'type', label: 'Type' },
];

interface GroupTable {
  name: string;
  index: number | string;
  tableRows: Array<any>;
}

const hiddenSunspecPoints: Array<SunspecPointName | string> = [
  SunspecPointName.ID,
  SunspecPointName.L,
];

export const bitfieldSymbolsMap = (
  value: number,
  symbols: SunspecPointSymbol[],
  base: number = 16
): Array<number> => {
  let matches = [];
  for (let i = 0; i < base; i++) {
    if (value & (1 << i)) {
      matches.push(symbols[i].value);
    }
  }
  return matches;
};

const createSunspecTableRows = (
  points: SunspecPoint[],
  values: Record<string, any>
): Array<any> => {
  const rows = points.map((point: SunspecPoint) => {
    // hide certain sunspec points and points with 'pad' types
    if (hiddenSunspecPoints.includes(point.name) || point.type === SunspecPointType.PAD) {
      return null;
    }

    let actualValue: number | null = null;
    let bitfieldValueArray: Array<number> | null = null;
    let symbolsList: React.ReactNode | null = null;

    if (values) {
      actualValue = values[point.name];

      if (isNil(actualValue)) {
        return null;
      }
    }

    if (!isNil(actualValue) && point.symbols) {
      if (point.type === SunspecPointType.BITFIELD16) {
        bitfieldValueArray = bitfieldSymbolsMap(actualValue, point.symbols, 16);
      } else if (point.type === SunspecPointType.BITFIELD32) {
        bitfieldValueArray = bitfieldSymbolsMap(actualValue, point.symbols, 32);
      }
    }

    if (point.symbols) {
      symbolsList = (
        <Box>
          {point.symbols.map((symbol: SunspecPointSymbol, index: number) => {
            let selected: boolean = false;
            if (bitfieldValueArray) {
              selected = bitfieldValueArray.includes(index);
            } else {
              selected = actualValue === symbol.value;
            }
            return (
              <Box key={`symbol_${symbol.name}`}>
                <Label
                  variant={selected ? 'contained' : 'outlined'}
                  color={selected ? DARK_BLUE : GREY}
                >
                  {symbol.name}
                </Label>
              </Box>
            );
          })}
        </Box>
      );
    }

    return [
      { content: point.name },
      { content: point.label },
      { content: point.desc },
      { content: actualValue },
      { content: point.units },
      { content: symbolsList },
      { content: point.type },
    ];
  });

  // compact will remove nulls from an array
  return compact(rows);
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: 400,
  },
  selectFormControl: {
    maxWidth: 300,
  },
}));

interface Props {
  siteId: string;
  deviceId: string;
  sunspecModelId: SunspecModelId | number | null;
}

export const SunspecTable = (props: Props) => {
  const { siteId, deviceId, sunspecModelId } = props;

  const classes = useStyles();
  const apiClient = useContext(APIClientContext);

  const [sunspecDefinition, setSunspecDefinition] = useState<SunspecModelDefinition | null>(null);
  const [sunspecValues, setSunspecValues] = useState<any>({});
  const [loadingDefinition, setLoadingDefinition] = useState<boolean>(false);
  const [loadingValues, setLoadingValues] = useState<boolean>(false);
  const [definitionError, setDefinitionError] = useState<boolean>(false);
  const [valuesError, setValuesError] = useState<boolean>(false);

  useEffect(() => {
    if (!sunspecModelId) {
      return;
    }

    const fetchSunspecDefinition = async () => {
      setLoadingDefinition(true);

      try {
        const response = await apiClient.get({
          path: endpoints.sunspecModel.definition,
          ids: sunspecModelId,
        });
        setSunspecDefinition(response as SunspecModelDefinition);
        setDefinitionError(false);
      } catch (error) {
        console.error(error);
        setDefinitionError(true);
      } finally {
        setLoadingDefinition(false);
      }
    };

    const fetchSunspecValues = async () => {
      setLoadingValues(true);
      try {
        const response = await apiClient.get({
          path: endpoints.sunspecModel.values,
          ids: {
            siteId,
            deviceId,
            sunspecModelId,
          },
        });
        setSunspecValues(response);
        setValuesError(false);
      } catch (error) {
        console.error(error);
        setValuesError(true);
      } finally {
        setLoadingValues(false);
      }
    };

    fetchSunspecDefinition();
    fetchSunspecValues();
  }, [apiClient, deviceId, siteId, sunspecModelId]);

  // --

  const sunspecDefinitionPoints = sunspecDefinition?.group?.points || null;
  const sunspecValuesFixed = sunspecValues?.update?.fixed || null;

  let tableRows: any = [];
  if (sunspecDefinitionPoints) {
    tableRows = createSunspecTableRows(sunspecDefinitionPoints, sunspecValuesFixed);
  }

  const sunspecDefinitionGroups = sunspecDefinition?.group?.groups || null;
  let groupTables: GroupTable[] = [];

  if (sunspecDefinitionGroups) {
    sunspecDefinitionGroups.forEach((group: SunspecGroup, index: number) => {
      const { name, points } = group;
      var valueGroup = sunspecValues?.update?.[name] || null;
      if (!valueGroup) {
        valueGroup = sunspecValues?.update?.['repeating'] || null;
      }
      if (!valueGroup) {
        return;
      }
      const valueGroupKeys = Object.keys(valueGroup);

      valueGroupKeys.forEach((valueGroupKey) => {
        const values = valueGroup[valueGroupKey];
        const tableRows = createSunspecTableRows(points, values);
        groupTables.push({
          name,
          index: valueGroupKey,
          tableRows,
        });
      });
    });
  }

  return (
    <div className={classes.container}>
      <OverlayLoader loading={loadingDefinition || loadingValues} showSpinner />
      {definitionError && (
        <Box my={1}>
          {/* @ts-ignore */}
          <Alert variant="error" message="Error loading Sunspec Model" />
        </Box>
      )}
      {valuesError && (
        <Box my={1}>
          {/* @ts-ignore */}
          <Alert variant="error" message="Error loading Sunspec Model Values" />
        </Box>
      )}
      {!definitionError && (
        <>
          <Paper>
            <SimpleTable headRows={tableHeaderRows} rows={tableRows} size="small" width="100%" />
          </Paper>
          {!isEmpty(groupTables) &&
            groupTables.map((groupTable) => {
              const { name, index, tableRows } = groupTable;
              return (
                <Box my={3} key={`group_table_${index}`}>
                  <Paper>
                    <Box p={2}>
                      <Typography variant="h6" gutterBottom>
                        {name}: {index}
                      </Typography>
                    </Box>
                    <SimpleTable
                      headRows={tableHeaderRows}
                      rows={tableRows}
                      size="small"
                      width="100%"
                    />
                  </Paper>
                </Box>
              );
            })}
        </>
      )}
    </div>
  );
};
