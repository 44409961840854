import SearchIcon from '@material-ui/icons/Search';
import FirmwareIcon from '@material-ui/icons/Memory';
import { ROLE_ADMIN } from 'role-config';
import type { Config } from 'application-config';

// [role-access]
// @todo typescript out structure of navConfig return type
export const navigationConfig = (config: Config) => {
  return [
    {
      subheader: 'Tools',
      items: [
        {
          title: 'Systems',
          href: '/systems',
          icon: SearchIcon,
        },
        {
          title: 'Device Firmware Updater',
          href: '/dfu',
          icon: FirmwareIcon,
          roles: [ROLE_ADMIN],
          items: [
            {
              title: 'Campaigns',
              href: '/dfu/campaigns',
              disabled: !config.enableCampaignsFeature,
            },
            {
              title: 'Jobs',
              href: '/dfu/jobs',
            },
          ],
        },
      ],
    },
  ];
};
