import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Tooltip,
  TextField,
  TableRow,
  TableCell,
  Checkbox,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { DeviceSetpointModel } from 'models';
import SetpointDefaultValuesTooltip from './SetpointDefaultValuesTooltip';
import { LIGHT_ORANGE, LIGHT_YELLOW, BRIGHT_RED } from 'theme';
import { KEY_ENTER } from 'utils/keyboard';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 80,
  },
  inputRoot: {
    ...theme.typography.body1,
  },
  previousValueText: {
    fontSize: 10,
  },
  tableCellNonDefault: {
    fontWeight: 'bold',
  },
  tableRowNonDefault: {
    backgroundColor: LIGHT_ORANGE,
  },
  tableRowEdited: {
    backgroundColor: LIGHT_YELLOW,
  },
  errorIcon: {
    color: BRIGHT_RED,
    marginTop: '5px',
  },
}));

function SetpointTableRow({
  setpointModel,
  isEdited,
  isEditing,
  isResetting,
  isSelected,
  showTableColumn,
  onEdit,
  onDiscardEdit,
  onSelectedChange,
}) {
  const classes = useStyles();
  const [fields, setFields] = React.useState({
    signedValue: 0,
    unsignedValue: 0,
    hexValue: 0,
  });
  const [boundsError, setBoundsError] = React.useState(null);
  const minBound = setpointModel.get('min');
  const maxBound = setpointModel.get('max');
  const isDefault = setpointModel.isDefault;
  const canEditFields = Boolean(!isSelected && isEditing);

  React.useEffect(() => {
    setFields({
      signedValue: setpointModel.signedValue,
      unsignedValue: setpointModel.unsignedValue,
      hexValue: setpointModel.hexValue,
    });

    // check min/max bounds
    setBoundsError(!setpointModel.isInBounds);
  }, [setpointModel]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleKeyPress(event) {
    if (event.key !== KEY_ENTER) {
      return;
    } else {
      onEdit(event, setpointModel);
    }
  }

  function handleBlur(event) {
    onEdit(event, setpointModel);
  }

  function handleInputChange(event) {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  }

  function handleSelectedChange(event, value) {
    onSelectedChange(setpointModel, value);
  }

  const textFieldProps = {
    className: classes.textField,
    InputProps: {
      classes: { root: classes.inputRoot },
    },
    inputProps: {
      autoComplete: 'off',
    },
    onChange: handleInputChange,
    onKeyPress: handleKeyPress,
    onBlur: handleBlur,
    error: boundsError,
  };

  let tableRowClasses = null;

  if (isEdited) {
    tableRowClasses = classes.tableRowEdited;
  } else if (!isDefault) {
    tableRowClasses = classes.tableRowNonDefault;
  }

  return (
    <TableRow tabIndex={-1} className={tableRowClasses}>
      {isEditing && (
        <TableCell padding="checkbox">
          {isEdited ? (
            <Tooltip title="Discard edit" placement="top">
              <IconButton aria-label="Discard Edit" onClick={() => onDiscardEdit(setpointModel)}>
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Checkbox
              checked={isSelected}
              onChange={handleSelectedChange}
              disabled={Boolean(!isResetting || isEdited)}
            />
          )}
        </TableCell>
      )}
      {showTableColumn && <TableCell>{setpointModel.get('table')}</TableCell>}
      <TableCell
        className={clsx({
          [classes.tableCellNonDefault]: !isDefault,
        })}
      >
        {setpointModel.get('index')}
      </TableCell>
      <TableCell
        className={clsx({
          [classes.tableCellNonDefault]: !isDefault,
        })}
      >
        {setpointModel.get('label')}
      </TableCell>
      <TableCell>
        {boundsError && (
          <Tooltip placement="top" title={`Value must be between ${minBound} and ${maxBound}`}>
            <ErrorIcon fontSize="small" className={classes.errorIcon} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {canEditFields ? (
          <TextField value={fields.signedValue} name="signedValue" {...textFieldProps} />
        ) : (
          <Typography variant="subtitle2">{fields.signedValue}</Typography>
        )}
      </TableCell>
      <TableCell>
        {canEditFields ? (
          <TextField value={fields.unsignedValue} name="unsignedValue" {...textFieldProps} />
        ) : (
          <Typography variant="subtitle2">{fields.unsignedValue}</Typography>
        )}
      </TableCell>
      <TableCell>
        {canEditFields ? (
          <TextField value={fields.hexValue} name="hexValue" {...textFieldProps} />
        ) : (
          <Typography variant="subtitle2">{fields.hexValue}</Typography>
        )}
      </TableCell>
      <TableCell
        className={clsx({
          [classes.tableCellNonDefault]: !isDefault,
        })}
      >
        <SetpointDefaultValuesTooltip defaultValue={setpointModel.get('default')} />
      </TableCell>
    </TableRow>
  );
}

SetpointTableRow.propTypes = {
  // -- passed props
  setpointModel: PropTypes.instanceOf(DeviceSetpointModel).isRequired,
  isEdited: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isResetting: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showTableColumn: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDiscardEdit: PropTypes.func.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
};

SetpointTableRow.defaultProps = {
  isEdited: false,
  isEditing: false,
  isResetting: false,
  isSelected: false,
  showTableColumn: false,
};

export default SetpointTableRow;
