export enum DeviceType {
  BEACON = 'beacon',
  INVERTER = 'inverter',
  ICM = 'icm',
  BATTERY = 'battery',
  PVL = 'pvl',
  RGM = 'rgm',
  GENERATOR = 'generator',
  UNKNOWN = 'unknown',
}
