import { uniq } from 'lodash';
import { Collection } from './Collection';
import { FirmwareVersionModel } from './FirmwareVersionModel';

/**
 * if data is coming directly from the api in object format
 * with firmware versions nested under firmware types this
 * data will be parsed to attach the firmware type to each
 * FirmwareVersionModel
 */
function parse(data) {
  const firmwareVersionModels = [];
  const firmwareTypes = Object.keys(data);

  firmwareTypes.forEach(firmwareType => {
    const firmwareVersions = data[firmwareType];

    if (firmwareVersions && Array.isArray(firmwareVersions)) {
      firmwareVersions.forEach(firmwareVersion => {
        firmwareVersionModels.push({
          id: firmwareVersion,
          version: firmwareVersion,
          type: firmwareType,
        });
      });
    }
  });

  return firmwareVersionModels;
}

// --

export class FirmwareVersionCollection extends Collection {
  constructor(data) {
    if (data && !Array.isArray(data)) {
      super(parse(data), FirmwareVersionModel);
    } else {
      super(data, FirmwareVersionModel);
    }
  }

  get firmwareTypes() {
    return uniq(this.models.map(model => model.get('type')));
  }
}
