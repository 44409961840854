import { Campaign, Plan, Action, ActionType, SchedulingProfile } from 'types/Campaign';

export const planActions: Action[] = [
  {
    actionType: ActionType.DELIVER_FIRMWARE,
    rcpn: '000100073534',
    data: {
      hexFilePath: 'dev/hexes/urc/1002/URC13430_1002_3P.hex',
    },
  },
  {
    actionType: ActionType.WRITE_SETPOINTS,
    rcpn: '000100073534',
    data: {
      points: [
        {
          table: 0,
          index: 1,
          value: 1,
        },
      ],
      table_default_commands: [
        {
          table: 0,
          mask: [1],
        },
      ],
    },
  },
  {
    actionType: ActionType.DISABLE_DEVICE,
    rcpn: '000100073534',
  },
  {
    actionType: ActionType.DISABLE_DEVICE,
    rcpn: '0001000806DA',
  },
  {
    actionType: ActionType.ENABLE_DEVICE,
    rcpn: '000100073534',
  },
  {
    actionType: ActionType.ENABLE_DEVICE,
    rcpn: '0001000806DA',
  },
];

export const plan: Plan = {
  systemId: 'a227217b-c4f6-4a7e-9fd2-59785fef30f6',
  actions: planActions,
};

export const campaign: Campaign = {
  name: 'Test Campaign',
  id: '1234567',
  createdBy: 'jared.weinstock@generac.com',
  createdDate: new Date(),
  campaignStartDate: new Date(),
  schedulingProfile: [SchedulingProfile.IMMEDIATE],
  plans: [plan],
};
