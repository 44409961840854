import { Model } from './Model';

export class SiteModel extends Model {
  constructor(fields = {}) {
    super(fields);
  }

  id() {
    return this.get('site_id');
  }
}
