import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

const DialogDeviceToggle = props => {
  const { titleText, cancelText, confirmText, contentText, isOpen, onClose, onConfirm } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h4">{titleText}</Typography>
      </DialogTitle>
      {contentText && (
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color="secondary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const sharedPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
};

DialogDeviceToggle.propTypes = {
  ...sharedPropTypes,
  titleText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
};

DialogDeviceToggle.defaultProps = {
  titleText: 'Are you sure you would like to toggle this device?',
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

export default DialogDeviceToggle;

// --

export const DialogDeviceDisable = props => {
  return (
    <DialogDeviceToggle
      titleText="Are you sure you would like to disable this device?"
      confirmText="Disable"
      {...props}
    />
  );
};

DialogDeviceDisable.propTypes = sharedPropTypes;

// --

export const DialogDeviceEnable = props => {
  return (
    <DialogDeviceToggle
      titleText="Are you sure you would like to enable this device?"
      confirmText="Enable"
      {...props}
    />
  );
};

DialogDeviceEnable.propTypes = sharedPropTypes;
