import { endpoints } from 'api';
import { FirmwareVersionCollection } from 'models';

const initialState = {
  collection: new FirmwareVersionCollection(),
  isFetching: false,
  fetchError: null,
};

const RESET = 'FIRMWARE_VERSIONS_RESET';
const REQUESTED = 'FIRMWARE_VERSIONS_REQUESTED';
const FETCHED = 'FIRMWARE_VERSIONS_FETCHED';
const FAILED = 'FIRMWARE_VERSIONS_FAILED';

// receive can take FETCHED, CREATED, DESTROYED, REPLACED actions
const receive = (action, fields) => ({ type: action, payload: fields });
const reset = () => ({ type: RESET });
const request = () => ({ type: REQUESTED });
const fail = (error) => ({ type: FAILED, payload: error });

// --

const fetchAll = (apiClient) => {
  return (dispatch, getState) => {
    dispatch(request());

    apiClient
      .get({ path: endpoints.firmware.versions })
      .then((response) => {
        if (!response.success) {
          throw Error('Could not load firmware versions');
        }
        dispatch(receive(FETCHED, response.versions));
      })
      .catch((error) => {
        dispatch(fail(error.message || error));
      });
  };
};

const ACTION_HANDLERS = {
  [RESET]: (state, action) => initialState,
  [REQUESTED]: (state, action) => ({
    ...state,
    isFetching: true,
    fetchError: null,
  }),
  [FETCHED]: (state, action) => ({
    ...state,
    collection: new FirmwareVersionCollection(action.payload),
    fetchError: null,
    isFetching: false,
  }),
  [FAILED]: (state, action) => ({
    ...state,
    fetchError: action.payload,
    isFetching: false,
  }),
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export const actions = {
  reset,
  fetchAll,
};
