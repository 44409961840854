import { Action, isActionOf } from 'typesafe-actions';
import { ActionsObservable, Epic, combineEpics } from 'redux-observable';
import { filter, mergeMap, catchError, map } from 'rxjs/operators';
import { from } from 'rxjs';
import type { CampaignsFetchResponse } from 'types/Campaign';
import type { RootAction } from 'state/rootAction';
import { fetchCampaigns } from './actions';
import { campaign as testCampaign } from 'api/test/campaign';

// @todo fetch campaigns instead of sites
const apiFetchCampaigns = async (): Promise<CampaignsFetchResponse> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        campaigns: [testCampaign, testCampaign, testCampaign, testCampaign],
      });
    }, 750);
  });
};

// @todo setup RootState in place of any
const fetchCampaignsEpic: Epic<RootAction, RootAction, any> = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    filter(isActionOf(fetchCampaigns.request)),
    mergeMap((action) => {
      return from(apiFetchCampaigns()).pipe(
        map(fetchCampaigns.success),
        catchError(async (e) => fetchCampaigns.failure(e))
      );
    })
  );
};

export default combineEpics(fetchCampaignsEpic);
