import moment from 'moment';
import {
  METRIC_TYPE_INVERTER_ENERGY_OUT,
  METRIC_TYPE_CONSUMPTION_ENERGY,
} from 'data/site-metric-types';
import { EVENT_POWER, EVENT_ENERGY, EVENT_VOLTAGE } from 'data/device-event-types';
import { DEFAULT_QUERY } from 'models/SiteMetricsModel';
import { UPDATES_DEFAULT_QUERY } from 'models/DeviceModel';

const DEFAULT_TIME_QUERY = {
  start: moment().subtract(7, 'd'), // 7 days ago
  end: moment(), // now
};

const initialState = {
  dcbChart: {
    selectedQuery: {
      ...DEFAULT_TIME_QUERY,
    },
  },
  eventsChart: {
    selectedQuery: {
      ...DEFAULT_TIME_QUERY,
    },
  },
  metricsChart: {
    selectedMetrics: [METRIC_TYPE_INVERTER_ENERGY_OUT, METRIC_TYPE_CONSUMPTION_ENERGY],
    selectedQuery: {
      ...DEFAULT_QUERY,
      ...DEFAULT_TIME_QUERY,
    },
  },
  updatesChart: {
    selectedMetrics: [EVENT_POWER, EVENT_ENERGY, EVENT_VOLTAGE],
    selectedQuery: {
      ...UPDATES_DEFAULT_QUERY,
      ...DEFAULT_TIME_QUERY,
    },
  },
};

const MODULE_NAME = 'chartSettings';

// -- selectors

export const dcbChartSelectedQuery = (state) => state[MODULE_NAME].dcbChart.selectedQuery;
export const eventsChartSelectedQuery = (state) => state[MODULE_NAME].eventsChart.selectedQuery;
export const metricsChartSelectedMetrics = (state) =>
  state[MODULE_NAME].metricsChart.selectedMetrics;
export const metricsChartSelectedQuery = (state) => state[MODULE_NAME].metricsChart.selectedQuery;
export const updatesChartSelectedMetrics = (state) =>
  state[MODULE_NAME].updatesChart.selectedMetrics;
export const updatesChartSelectedQuery = (state) => state[MODULE_NAME].updatesChart.selectedQuery;

// -- actions

const RESET = 'CHART_SETTINGS_RESET';
const SET_EVENTS_CHART_QUERY_DATA = 'SET_EVENTS_CHART_QUERY_DATA';
const SET_DCB_CHART_QUERY_DATA = 'SET_DCB_CHART_QUERY_DATA';
const SET_METRICS_CHART_METRIC_SELECTIONS = 'SET_METRICS_CHART_METRIC_SELECTIONS';
const SET_METRICS_CHART_QUERY_DATA = 'SET_METRICS_CHART_QUERY_DATA';
const SET_UPDATES_CHART_METRIC_SELECTIONS = 'SET_UPDATES_CHART_METRIC_SELECTIONS';
const SET_UPDATES_CHART_QUERY_DATA = 'SET_UPDATES_CHART_QUERY_DATA';

const reset = () => ({ type: RESET });

const setEventsChartQueryData = (data) => {
  return {
    type: SET_EVENTS_CHART_QUERY_DATA,
    payload: data,
  };
};

const setDcbChartQueryData = (data) => {
  return {
    type: SET_DCB_CHART_QUERY_DATA,
    payload: data,
  };
};

const setMetricsChartSelectedMetrics = (data) => {
  return {
    type: SET_METRICS_CHART_METRIC_SELECTIONS,
    payload: data,
  };
};

const setMetricsChartQueryData = (data) => {
  return {
    type: SET_METRICS_CHART_QUERY_DATA,
    payload: data,
  };
};

const setUpdatesChartSelectedMetrics = (data) => {
  return {
    type: SET_UPDATES_CHART_METRIC_SELECTIONS,
    payload: data,
  };
};

const setUpdatesChartQueryData = (data) => {
  return {
    type: SET_UPDATES_CHART_QUERY_DATA,
    payload: data,
  };
};

const actionHandlers = {
  [RESET]: (state, action) => initialState,
  [SET_DCB_CHART_QUERY_DATA]: (state, action) => ({
    ...state,
    dcbChart: {
      ...state.dcbChart,
      selectedQuery: { ...state.dcbChart.selectedQuery, ...action.payload },
    },
  }),
  [SET_EVENTS_CHART_QUERY_DATA]: (state, action) => ({
    ...state,
    eventsChart: {
      ...state.eventsChart,
      selectedQuery: { ...state.eventsChart.selectedQuery, ...action.payload },
    },
  }),
  [SET_METRICS_CHART_METRIC_SELECTIONS]: (state, action) => ({
    ...state,
    metricsChart: {
      ...state.metricsChart,
      selectedMetrics: action.payload,
    },
  }),
  [SET_METRICS_CHART_QUERY_DATA]: (state, action) => ({
    ...state,
    metricsChart: {
      ...state.metricsChart,
      selectedQuery: { ...state.metricsChart.selectedQuery, ...action.payload },
    },
  }),
  [SET_UPDATES_CHART_METRIC_SELECTIONS]: (state, action) => ({
    ...state,
    updatesChart: {
      ...state.updatesChart,
      selectedMetrics: action.payload,
    },
  }),
  [SET_UPDATES_CHART_QUERY_DATA]: (state, action) => ({
    ...state,
    updatesChart: {
      ...state.updatesChart,
      selectedQuery: { ...state.updatesChart.selectedQuery, ...action.payload },
    },
  }),
};

export default (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export const actions = {
  reset,
  setDcbChartQueryData,
  setEventsChartQueryData,
  setMetricsChartSelectedMetrics,
  setMetricsChartQueryData,
  setUpdatesChartSelectedMetrics,
  setUpdatesChartQueryData,
};
