import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 9,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  containerBackground: {
    backgroundColor: '#fff',
    opacity: 0.6,
  },
  center: {
    alignItems: 'center',
  },
  top: {
    paddingTop: theme.spacing(4),
    alignItems: 'top',
  },
}));

export const OverlayLoader = (props) => {
  const { loading, position, showBackground, showSpinner } = props;
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <div
      className={clsx(classes.container, {
        [classes.containerBackground]: showBackground,
        [classes.center]: Boolean(position === 'center'),
        [classes.top]: Boolean(position === 'top'),
      })}
    >
      {showSpinner && <CircularProgress size={40} color="secondary" />}
    </div>
  );
};

OverlayLoader.propTypes = {
  // -- passed props
  loading: PropTypes.bool,
  position: PropTypes.oneOf(['center', 'top']),
  showSpinner: PropTypes.bool,
  showBackground: PropTypes.bool,
};

OverlayLoader.defaultProps = {
  position: 'center',
  showSpinner: false,
  showBackground: true,
};
