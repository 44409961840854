// @note search all files for "[role-access]" to see implementations

import { isEmpty } from 'lodash';

/**
 * ROLES
 *
 * these are the roles managed for users on the server
 */
export const ROLE_ADMIN = 'admin';
export const ROLE_TECHNICIAN = 'technician';
export const ROLE_UNKNOWN = 'unknown';

export const roles = [ROLE_ADMIN, ROLE_TECHNICIAN, ROLE_UNKNOWN];

/**
 * FEATURES
 *
 * these are the features that require permissions
 * checks before access is allowed
 */
export const FEATURE_DEVICE_FIRMWARE_UPDATE = 'device_firmware_update';
export const FEATURE_DEVICE_ENABLE_TOGGLE = 'device_enable_toggle';
export const FEATURE_SITE_CLEAR_DEVICE_LIST = 'site_clear_device_list';
export const FEATURE_SITE_MODE_EDIT = 'site_mode_edit';
export const FEATURE_SITE_PROGRAM_SEARCH = 'site_program_search';
export const FEATURE_SETPOINTS_EDIT = 'setpoints_edit';

/**
 * ROLES + FEATURES MAP
 *
 * which roles should have access to which features
 */
const featureAccess = {
  [ROLE_ADMIN]: [
    FEATURE_DEVICE_FIRMWARE_UPDATE,
    FEATURE_DEVICE_ENABLE_TOGGLE,
    FEATURE_SITE_CLEAR_DEVICE_LIST,
    FEATURE_SITE_MODE_EDIT,
    FEATURE_SITE_PROGRAM_SEARCH,
    FEATURE_SETPOINTS_EDIT,
  ],
  [ROLE_TECHNICIAN]: [
    // no specific 'technician' features at the moment
  ],
  [ROLE_UNKNOWN]: [
    // no specific 'unknown' features at the moment
  ],
};

// @todo documenation for ROLES + ROUTES

// --

export function testRoleAccess(path, allowedRoles, userRole) {
  // console.log('!! Protected Route entered !!')
  // console.log('[role-config] testRoleAccess...')
  // console.log('path:', path)
  // console.log('allowedRoles:', allowedRoles)
  // console.log('userRole:', userRole)

  /**
   * if no roles are supplied OR
   * if the user's role is contained within the supplied role access array THEN
   * the user has access to this path
   */
  if (isEmpty(allowedRoles) || allowedRoles.indexOf(userRole) > -1) {
    // console.log('Protected Route allowed ✅')
    return true;
  }

  // user denied access to path and redirected to 404
  // console.log('Protected Route blocked ⛔️')
  return false;
}

// --

export function testFeatureAccess(feature, userRole) {
  // console.log('[role-config] testFeatureAccess...')
  // console.log('feature:', feature)
  // console.log('userRole:', userRole)

  /**
   * look through featureAccess map to check if given feature
   * exists on given userRole's allowed features
   */
  const userAllowedFeatures = featureAccess[userRole];

  if (isEmpty(userAllowedFeatures)) {
    // console.log('Protected Feature blocked ⛔️')
    return false;
  }

  // --

  if (userAllowedFeatures.indexOf(feature) > -1) {
    // console.log('Protected Feature allowed ✅')
    return true;
  }

  // user denied access to feature and it is not rendered in UI
  // console.log('Protected Feature blocked ⛔️')
  return false;
}
