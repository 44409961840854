import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import loadConfiguration from 'application-config';
import Error500 from 'views/Error500';
import packageJson from '../package.json';

// @todo add this to redux state?
// this is editable at System/Device context level
moment.tz.setDefault('UTC');

loadConfiguration().then(
  (config) => {
    console.log('-----');
    console.log('Generac | PWRtools');
    console.log('version: ', packageJson.version);
    console.log('using server: ', config.proxyTarget);
    console.log('-----');
    ReactDOM.render(<App {...config} />, document.getElementById('root'));
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  },
  (error) => {
    console.log('unable to load configuration');
    ReactDOM.render(<Error500 />, document.getElementById('root'));
  }
);
