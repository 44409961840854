import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextTooltip } from 'components';
import { useInterval } from 'hooks';
import { formatTimestamp, formatTimeago } from 'utils';

const DEFAULT_REFRESH_INTERVAL = 10000; // 10 seconds

export const TimeagoTooltip = (props) => {
  const { timestamp, timezone, refreshInterval, tooltipProps, typographyProps } = props;
  const [resetCount, setResetCount] = useState(0);

  // this will ensure rerendering of the timeago value to keep it updated
  useInterval(() => {
    setResetCount(resetCount + 1);
  }, refreshInterval);

  if (!timestamp) {
    return null;
  }

  return (
    <TextTooltip
      text={formatTimeago(timestamp, timezone)}
      tooltip={formatTimestamp(timestamp, timezone)}
      typographyProps={typographyProps}
      tooltipProps={tooltipProps}
    />
  );
};

TimeagoTooltip.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  timezone: PropTypes.string,
  refreshInterval: PropTypes.number.isRequired,
  tooltipProps: PropTypes.object,
  typographyProps: PropTypes.object,
};

TimeagoTooltip.defaultProps = {
  refreshInterval: DEFAULT_REFRESH_INTERVAL,
};
