import { CampaignState, CampaignStatus, PlanStatus, ActionType, ActionState } from 'types/Campaign';

export const planStatus: PlanStatus = {
  systemId: 'a227217b-c4f6-4a7e-9fd2-59785fef30f6',
  startedDate: new Date(),
  actionStatus: [
    {
      rcpn: '000100073534',
      actionType: ActionType.DELIVER_FIRMWARE,
      actionState: ActionState.COMPLETED,
      startedDate: new Date(),
      endedDate: new Date(),
      message: {
        success: 'Firmware has been delivered.',
        data: {
          hexFilePath: 'dev/hexes/urc/1002/URC13430_1002_3P.hex',
        },
      },
    },
    {
      rcpn: '000100073534',
      actionType: ActionType.WRITE_SETPOINTS,
      actionState: ActionState.COMPLETED,
      startedDate: new Date(),
      endedDate: new Date(),
      message: {
        success: 'Setpoints have been updated.',
        data: {
          points: [
            {
              table: 0,
              index: 1,
              value: 1,
            },
          ],
          table_default_commands: [
            {
              table: 0,
              mask: [1],
            },
          ],
        },
      },
    },
    {
      rcpn: '000100073534',
      actionType: ActionType.DISABLE_DEVICE,
      actionState: ActionState.RUNNING,
      startedDate: new Date(),
    },
    {
      rcpn: '0001000806DA',
      actionType: ActionType.DISABLE_DEVICE,
      actionState: ActionState.WAITING,
    },
    {
      rcpn: '000100073534',
      actionType: ActionType.ENABLE_DEVICE,
      actionState: ActionState.WAITING,
    },
    {
      rcpn: '0001000806DA',
      actionType: ActionType.ENABLE_DEVICE,
      actionState: ActionState.WAITING,
    },
  ],
};

export const campaignStatus: CampaignStatus = {
  campaignId: '1234567',
  campaignState: CampaignState.RUNNING,
  startedDate: new Date(),
  planStatus: [planStatus],
};
