import palette, { LIGHT_BRIGHT_GREEN, BRIGHT_GREEN } from './palette';
import typography from './typography';

export default {
  MuiButton: {
    contained: {
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
      backgroundColor: '#FFFFFF',
    },
    // contained: {
    // 	backgroundColor: palette.WHITE
    // }
  },
  MuiIconButton: {
    root: {
      // color: "#000"
    },
  },
  MuiLinearProgress: {
    colorSecondary: {
      backgroundColor: LIGHT_BRIGHT_GREEN,
    },
    barColorSecondary: {
      backgroundColor: BRIGHT_GREEN,
    },
  },
  MuiPaper: {
    root: {},
    elevation1: {
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
  },
  MuiTableCell: {
    root: {
      ...typography.body1,
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
};
