import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export const PageHeader = props => {
  const { className, title, titleComponent, subtitle, actions, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" variant="overline" gutterBottom>
            {subtitle}
          </Typography>
          {titleComponent}
          {!titleComponent && (
            <Typography component="h1" variant="h3">
              {title}
            </Typography>
          )}
        </Grid>
        {actions && <Grid item>{actions}</Grid>}
      </Grid>
    </div>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.node,
  subtitle: PropTypes.string,
  actions: PropTypes.node,
};
