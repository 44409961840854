import { isNil } from 'lodash';

export const valueToHexString = (value) => {
  if (isNil(value)) {
    return null;
  }

  return `0x${value.toString(16).toUpperCase().padStart(4, 0)}`;
};

export const kilowattSecondsToHours = (kWs, decimalPlaces = 1e3) => {
  return Math.round((kWs / 3.6e6) * decimalPlaces) / decimalPlaces;
};

export const fixToDecimal = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return (Math.round(value * multiplier) / multiplier).toFixed(precision);
};

export const valueToSigned = (value) => {
  return parseInt(value, 10);
};

export const valueToUnsigned = (value) => {
  return value & 0xffff;
};

export const valueToHex = (value) => {
  return valueToUnsigned(value).toString(16).padStart(4, '0').toUpperCase();
};
