import { Model } from './Model';
import { DeviceType } from 'types/Device';

export class LiveDeviceModel extends Model {
  fieldMap = {
    id: 'ID',
    state: 'State',
    last_heard: 'Last Heard',
    first_update: 'First Update',
    name: 'Name',
    power: 'DC Power (W)',
    type: 'Device Type',
    ct_power: 'CT Power (W)',
    ac_power: 'AC Power (W)',
    sysmode: 'System Mode',
    soc: 'State of Charge (%)',
    low_data_flag: 'Low Data Flag',
    next_change_at: 'Next Change At',
    next_sysmode: 'Next System Mode',
    sched_name: 'Schedule Name',
    tou_status: 'TOU Status',
    srd: 'Grid Profile',
  };
}

// --

export class LiveBeaconModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("1568862743000")
      state: null, // string ("disabled")
      last_heard: null, // unix timestamp (1568862740000)
      first_update: null,
      name: null, // string ("REbus Beacon")
      type: null, // string ("beacon") | ENUM types/Device
      low_data_flag: null, // number (0)
      next_change_at: null, // unix timestamp?
      next_sysmode: null, // string ("?")
      sched_name: null, // string ("?")
      tou_status: null, // string ("disabled")
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.BEACON;
    this.fieldMap = {
      id: 'ID',
      state: 'State',
      last_heard: 'Last Heard',
      first_update: 'First Update',
      name: 'Name',
      type: 'Device Type',
      low_data_flag: 'Low Data Flag',
      next_change_at: 'Next Change At',
      next_sysmode: 'Next System Mode',
      sched_name: 'Schedule Name',
      tou_status: 'TOU Status',
    };
  }
}

export class LiveInverterModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("000100070408")
      state: null, // string ("grid connected") | ENUM data/device-states
      last_heard: null, // unix timestamp (1568862743000)
      first_update: null,
      name: null, // string ("X7602 Islanding Inverter")
      power: null, // number (-12)
      type: null, // string ("inverter") | ENUM types/Device
      ct_power: null, // number (-57)
      sysmode: null, // string ("Grid Tie") | ENUM types/SiteMode
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.INVERTER;
    this.fieldMap = {
      id: 'ID',
      state: 'State',
      last_heard: 'Last Heard',
      first_update: 'First Update',
      name: 'Name',
      power: 'Power (W)',
      type: 'Device Type',
      ct_power: 'CT Power (W)',
      sysmode: 'System Mode',
      srd: 'Grid Profile',
    };
  }
}

export class LiveICMModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("000100070408")
      state: null, // string ("grid connected") | ENUM data/device-states
      last_heard: null, // unix timestamp (1568862743000)
      first_update: null,
      name: null, // string ("X7602 Islanding Inverter")
      power: null, // number (-12)
      type: null, // string ("inverter") | ENUM types/Device
      ct_power: null, // number (-57)
      sysmode: null, // string ("Grid Tie") | ENUM types/SiteMode
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.ICM;
    this.fieldMap = {
      id: 'ID',
      state: 'State',
      last_heard: 'Last Heard',
      first_update: 'First Update',
      name: 'Name',
      power: 'Power (W)',
      type: 'Device Type',
      ct_power: 'CT Power (W)',
      sysmode: 'System Mode',
    };
  }
}

export class LiveRGMModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {};

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.RGM;
    this.fieldMap = {};
  }
}
export class LiveBatteryModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("0001000801AC")
      state: null, // string ("standby")
      last_heard: null, // unix timestamp (1568862740000)
      first_update: null,
      name: null, // string ("Smart Battery")
      power: null, // number (0)
      type: null, // string ("battery") | ENUM types/Device
      soc: null, // number (98.8)
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.BATTERY;
    this.fieldMap = {
      id: 'ID',
      state: 'State',
      last_heard: 'Last Heard',
      first_update: 'First Update',
      name: 'Name',
      power: 'Power (W)',
      type: 'Device Type',
      soc: 'State of Charge (%)',
    };
  }
}

export class LivePVLModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("0001000314F7")
      state: null, // string ("disabled")
      last_heard: null, // unix timestamp (1568862740000)
      first_update: null,
      name: null, // string ("PV Link")
      power: null, // number (0)
      type: null, // string ("pvl") | ENUM types/Device
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.PVL;
    this.fieldMap = {
      id: 'ID',
      state: 'State',
      last_heard: 'Last Heard',
      first_update: 'First Update',
      name: 'Name',
      power: 'Power (W)',
      type: 'Device Type',
    };
  }
}

export class LiveGeneratorModel extends LiveDeviceModel {
  constructor(fields) {
    const defaultFields = {
      id: null, // string ("0001000E14F7")
      state: null, // string ("disabled")
      last_heard: null, // unix timestamp (1568862740000)
      first_update: null,
      name: null, // string ("Endure")
      power: null, // number (0)
      type: null, // string ("generator") | ENUM types/Device
    };

    super(Object.assign(defaultFields, fields));

    this.deviceType = DeviceType.GENERATOR;
    this.fieldMap = {
      id: 'ID',
      state: 'State',
      last_heard: 'Last Heard',
      first_update: 'First Update',
      name: 'Name',
      power: 'Power (W)',
      type: 'Device Type',
    };
  }
}
