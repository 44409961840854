import { Config } from '../application-config';

export interface URLTarget {
  path: string;
  ids?: string | Object;
}

export class URLCreator {
  readonly proxyTarget: string;

  constructor({ proxyTarget }: Config) {
    this.proxyTarget = proxyTarget;
  }

  createUrl(target: URLTarget): string {
    let url = this.proxyTarget + target.path;

    if (target.ids) {
      // if ids is not an object, make it into one
      let idObject = typeof target.ids === 'object' ? target.ids : { id: target.ids };
      Object.keys(idObject).forEach((key) => {
        url = url.replace(`:${key}`, Reflect.get(idObject, key));
      });
    }

    return url;
  }
}
