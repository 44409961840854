import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, List } from '@material-ui/core';
import { CampaignPlanActionStatusItem } from './CampaignPlanActionStatusItem';
import { PlanStatus, ActionStatus } from 'types/Campaign';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  planStatus: PlanStatus;
}

export const CampaignPlanStatusTree = (props: Props) => {
  const { planStatus } = props;
  const actionStatusTree: ActionStatus[] = planStatus.actionStatus;

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <List>
        {actionStatusTree.map((actionStatus, index) => {
          return (
            <CampaignPlanActionStatusItem
              key={`action_status_${index}`}
              actionStatus={actionStatus}
            />
          );
        })}
      </List>
    </Paper>
  );
};
