import { ComponentType } from 'react';
import { colors, SvgIconProps } from '@material-ui/core';
import FirmwareIcon from '@material-ui/icons/Memory';
import DisableIcon from '@material-ui/icons/ToggleOff';
import EnableIcon from '@material-ui/icons/ToggleOn';
import SetpointsIcon from '@material-ui/icons/SettingsInputComponent';
import { ActionType } from 'types/Campaign';

interface Definition {
  name: string;
  color: string;
  icon: ComponentType<SvgIconProps>;
}

// @todo define actual colors here - these are temporary
const CampaignActionTypeDefinitions: Record<keyof typeof ActionType, Definition> = {
  [ActionType.DELIVER_FIRMWARE]: {
    name: 'Deliver Firmware',
    color: colors.blueGrey[500],
    icon: FirmwareIcon,
  },
  [ActionType.DISABLE_DEVICE]: {
    name: 'Disable Device',
    color: colors.blueGrey[500],
    icon: DisableIcon,
  },
  [ActionType.ENABLE_DEVICE]: {
    name: 'Enable Device',
    color: colors.blueGrey[500],
    icon: EnableIcon,
  },
  [ActionType.WRITE_SETPOINTS]: {
    name: 'Write Setpoints',
    color: colors.blueGrey[500],
    icon: SetpointsIcon,
  },
};

export default CampaignActionTypeDefinitions;
