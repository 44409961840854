/**
 * @see https://material-ui.com/components/tables/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, Checkbox, FormControlLabel } from '@material-ui/core';

const ERROR_COLUMN_WIDTH = 60;
const tableColumn = { id: 'table', numeric: true, alignLeft: true, label: 'Table' };

const columns = [
  { id: 'index', numeric: true, alignLeft: true, label: 'Index' },
  { id: 'label', numeric: false, alignLeft: true, label: 'Label' },
  {
    id: 'errors',
    numeric: false,
    alignLeft: true,
    label: '',
    style: { width: ERROR_COLUMN_WIDTH },
  },
  { id: 'signed', numeric: true, alignLeft: true, label: 'Signed' }, // derived from 'value'
  { id: 'unsigned', numeric: true, alignLeft: true, label: 'Unsigned' }, // derived from 'value'
  { id: 'hex', numeric: true, alignLeft: true, label: 'Hex' }, // derived from 'value'
  { id: 'default', numeric: true, alignLeft: true, label: 'Default' },
];

function SetpointTableHead(props) {
  const {
    tableNumber,
    showTableColumn,
    resetIndexes,
    totalRowCount,
    isEditing,
    onTableResetChange,
  } = props;

  let _columns = showTableColumn ? [tableColumn, ...columns] : columns;
  // adjusting the row count to ignore Index 0
  let totalRowCountAdjusted = totalRowCount - 1;
  let resetCount = resetIndexes ? resetIndexes.length : 0;

  function handleResetToggleChange(event, value) {
    onTableResetChange(tableNumber);
  }

  return (
    <TableHead>
      <TableRow>
        {isEditing && (
          <TableCell padding="checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  indeterminate={resetCount > 0 && resetCount < totalRowCountAdjusted}
                  checked={totalRowCountAdjusted > 0 && resetCount === totalRowCountAdjusted}
                  onChange={handleResetToggleChange}
                />
              }
              label="Default"
            />
          </TableCell>
        )}
        {_columns.map((col, index) => (
          <TableCell
            style={col.style ? col.style : null}
            key={col.id}
            align={col.alignLeft === false ? 'right' : 'left'}
            padding={col.disablePadding ? 'none' : 'default'}
          >
            {col.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SetpointTableHead.propTypes = {
  tableNumber: PropTypes.number.isRequired,
  showTableColumn: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  resetIndexes: PropTypes.array,
  totalRowCount: PropTypes.number,
  onTableResetChange: PropTypes.func,
};

SetpointTableHead.defaultProps = {
  showTableColumn: false,
  isResetting: PropTypes.bool.isRequired,
  isEditing: false,
};

export default SetpointTableHead;
