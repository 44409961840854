import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';
import { DeviceSetpointModel } from 'models';
import SetpointDefaultValuesTooltip from './SetpointDefaultValuesTooltip';
import { LIGHT_GREY, DARK_GREY } from 'theme';

const useStyles = makeStyles(theme => ({
  tableRow: {
    backgroundColor: LIGHT_GREY,
  },
  tableCell: {
    color: DARK_GREY,
  },
}));

function SetpointTableRowDisabled({ setpointModel, isEditing, showTableColumn }) {
  const classes = useStyles();
  const [fields, setFields] = React.useState({});

  React.useEffect(() => {
    setFields({
      signedValue: setpointModel.signedValue,
      unsignedValue: setpointModel.unsignedValue,
      hexValue: setpointModel.hexValue,
    });
  }, [setpointModel]);

  return (
    <TableRow tabIndex={-1} className={classes.tableRow}>
      {isEditing && <TableCell padding="checkbox"></TableCell>}
      {showTableColumn && (
        <TableCell className={classes.tableCell}>{setpointModel.get('table')}</TableCell>
      )}
      <TableCell className={classes.tableCell}>{setpointModel.get('index')}</TableCell>
      <TableCell className={classes.tableCell}>{setpointModel.get('label')}</TableCell>
      <TableCell className={classes.tableCell}></TableCell>
      <TableCell className={classes.tableCell}>{fields.signedValue}</TableCell>
      <TableCell className={classes.tableCell}>{fields.unsignedValue}</TableCell>
      <TableCell className={classes.tableCell}>{fields.hexValue}</TableCell>
      <TableCell className={classes.tableCell}>
        <SetpointDefaultValuesTooltip defaultValue={setpointModel.get('default')} />
      </TableCell>
    </TableRow>
  );
}

SetpointTableRowDisabled.propTypes = {
  // -- passed props
  setpointModel: PropTypes.instanceOf(DeviceSetpointModel).isRequired,
  isEditing: PropTypes.bool.isRequired,
  showTableColumn: PropTypes.bool.isRequired,
};

SetpointTableRowDisabled.defaultProps = {
  isEditing: false,
  showTableColumn: false,
};

export default SetpointTableRowDisabled;
