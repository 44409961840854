/**
 * @see api/test/firmware-job-spt-full-event.js
 */

import { isNil } from 'lodash';
import { Model } from './Model';
import { valueToSigned, valueToUnsigned, valueToHex } from 'utils';

const defaultFields = {
  default: null, // number
  index: null, // number
  label: null, // string
  max: null, // number
  min: null, // number
  sig: null, // number
  table: null, // number
  value: null, // number
};

export class DeviceSetpointModel extends Model {
  constructor(fields = defaultFields) {
    super(fields);

    // saving the initial value for in case edits are discarded
    this.initialValue = fields.value;
    this.initialSignedValue = valueToSigned(fields.value);
    this.initialUnsignedValue = valueToUnsigned(fields.value);
    this.initialHexValue = valueToHex(fields.value);
  }

  id() {
    return `${this.get('table')}_${this.get('index')}_${this.get('label')}`;
  }

  get isDefault() {
    // @note it is possible that a setpoint will not contain default value data
    // if there is no default, always return true
    if (isNil(this.get('default'))) {
      return true;
    }

    return Boolean(this.get('default') === this.get('value'));
  }

  get isInBounds() {
    // @note it is possible that a setpoint will not contain min/max data
    // if the boundaries aren't known, always return true
    if (isNil(this.get('min')) || isNil(this.get('max'))) {
      return true;
    }

    const value = this.signedValue;
    return Boolean(value >= this.get('min') && value <= this.get('max'));
  }

  // updating value based on a given signed value (no conversion needed)
  set signedValue(value) {
    this.set({ value: parseInt(value, 10) });
  }

  // deriving a signed value from the base value (no conversion needed)
  get signedValue() {
    return this.get('value');
  }

  // converting a given unsigned value to the base value
  set unsignedValue(unsigned) {
    const value = (unsigned << 16) >> 16;
    this.set({ value: parseInt(value, 10) });
  }

  // deriving an unsigned value from the base value
  get unsignedValue() {
    return valueToUnsigned(this.get('value'));
  }

  // converting a given hex value to the base value
  set hexValue(hex) {
    const value = (parseInt(hex, 16) << 16) >> 16;
    this.set({ value: parseInt(value, 10) });
  }

  // deriving a hex value from the base value
  get hexValue() {
    return valueToHex(this.get('value'));
  }
}
