import moment from 'moment';

export const UTC = 'Universal';
export const ATLANTIC = 'Atlantic/Bermuda';
export const EASTERN = 'America/New_York';
export const CENTRAL = 'America/Chicago';
export const MOUNTAIN = 'America/Denver';
export const ARIZONA = 'America/Phoenix';
export const PACIFIC = 'America/Los_Angeles';
export const ALASKA = 'America/Anchorage';
export const HAWAII = 'Pacific/Honolulu';

const createTimezoneData = tz => ({
  title: `${tz.replace('_', ' ')} (${moment()
    .tz(tz)
    .format('z')})`,
  value: tz,
});

export const TZ_UNIVERSAL = createTimezoneData(UTC);
export const TZ_ATLANTIC = createTimezoneData(ATLANTIC);
export const TZ_EASTERN = createTimezoneData(EASTERN);
export const TZ_CENTRAL = createTimezoneData(CENTRAL);
export const TZ_MOUNTAIN = createTimezoneData(MOUNTAIN);
export const TZ_ARIZONA = createTimezoneData(ARIZONA);
export const TZ_PACIFIC = createTimezoneData(PACIFIC);
export const TZ_ALASKA = createTimezoneData(ALASKA);
export const TZ_HAWAII = createTimezoneData(HAWAII);

export default [
  TZ_UNIVERSAL,
  TZ_ATLANTIC,
  TZ_EASTERN,
  TZ_CENTRAL,
  TZ_MOUNTAIN,
  TZ_ARIZONA,
  TZ_PACIFIC,
  TZ_ALASKA,
  TZ_HAWAII,
];
