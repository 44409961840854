export enum JobType {
  FIRMWARE_UPDATE = 'firmware_update',
  SPT_GET = 'spt_get',
  SPT_PUT = 'spt_put',
}

export enum JobEventType {
  META = 'meta',
  START = 'start',
  PROGRESS = 'progress',
  DEVICE_INFO = 'device_info',
  SETPOINTS_RETRIEVED = 'spt_retrieved',
  SETPOINTS_PARTIAL = 'spt_partial',
  SETPOINTS_METADATA = 'spt_metadata',
  SUCCESS = 'success',
  ERROR = 'error',
  QUEUE = 'queue',
  CREATE = 'create',
  CANCEL = 'cancel',
  UNKNOWN = 'unknown',
}
