import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography, Paper, Link } from '@material-ui/core';
import { BRIGHT_RED, WHITE } from 'theme';
import { SystemDetails } from 'types/System';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
  },
  scrubButton: {
    backgroundColor: BRIGHT_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: BRIGHT_RED,
    },
  },
}));

interface Props {
  systemDetails: SystemDetails;
}

export const SystemInfo = (props: Props) => {
  const classes = useStyles();
  const { systemDetails } = props;
  const {
    site_id: systemId,
    inverter_rcpn: inverterRcpn,
    beacon_rcpn: beaconRcpn,
    user_email: userEmail,
  } = systemDetails;

  return (
    <Paper className={classes.paper}>
      <List>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">System Id</Typography>
          <Typography variant="h6">
            <Link component={RouterLink} to={`/systems/${systemId}`}>
              {systemId}
            </Link>
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Owner email</Typography>
          <Typography variant="h6">{userEmail}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Timezone</Typography>
          <Typography variant="h6">{systemDetails.timezone || '--'}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Reporting by</Typography>
          <Typography variant="h6">{systemDetails.reporting}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Inverter serial</Typography>
          <Typography variant="h6">{systemDetails.serial_number || '--'}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} divider>
          <Typography variant="subtitle2">Inverter RCPn</Typography>
          <Typography variant="h6">
            {inverterRcpn ? (
              <Link component={RouterLink} to={`/systems/${systemId}/devices/${inverterRcpn}`}>
                {inverterRcpn}
              </Link>
            ) : (
              '--'
            )}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Typography variant="subtitle2">Beacon RCPn</Typography>
          <Typography variant="h6">
            {beaconRcpn ? (
              <Link component={RouterLink} to={`/systems/${systemId}/devices/${beaconRcpn}`}>
                {beaconRcpn}
              </Link>
            ) : (
              '--'
            )}
          </Typography>
        </ListItem>
      </List>
    </Paper>
  );
};
