import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const BadgeTabs = withStyles({
  root: {
    // --
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export const BadgeTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    overflow: 'visible',
    fontWeight: theme.typography.fontWeightRegular,
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      // --
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);
