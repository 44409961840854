import React, { useState, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { APIClientContext } from 'api';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import WaitIcon from '@material-ui/icons/FiberManualRecord';
import { SiteCollection, SiteModel } from 'models';
import { SiteMode } from 'types/SiteMode';
import { pluralize } from 'utils';
import { BRIGHT_GREEN, BRIGHT_RED } from 'theme';
import { endpoints } from 'api';

const BATCH_COUNT = 5;
const STATE_WAITING = 'waiting';
const STATE_LOADING = 'loading';
const STATE_SUCCESS = 'success';
const STATE_ERROR = 'error';

const SiteRow = (props) => {
  const { siteModel, siteMode, batchIndex, currentBatchIndex, onCallCompleted } = props;
  const [loadingState, setLoadingState] = useState(STATE_WAITING);
  const apiClient = useContext(APIClientContext);
  const siteId = siteModel.id();
  let iconComponent = null;

  useEffect(() => {
    const updateSiteMode = async () => {
      try {
        await apiClient.patch({
          path: endpoints.site.mode,
          ids: siteId,
          data: { ModeName: siteMode },
        });
        setLoadingState(STATE_SUCCESS);
        onCallCompleted(siteId);
      } catch (error) {
        setLoadingState(STATE_ERROR);
        onCallCompleted(siteId);
      }
    };

    if (currentBatchIndex === batchIndex && loadingState === STATE_WAITING) {
      setLoadingState(STATE_LOADING);
      updateSiteMode();
    }
  }, [currentBatchIndex, batchIndex, siteId, loadingState, onCallCompleted, siteMode, apiClient]);

  switch (loadingState) {
    case STATE_WAITING:
      iconComponent = <WaitIcon />;
      break;
    case STATE_LOADING:
      iconComponent = <CircularProgress thickness={2} size={24} />;
      break;
    case STATE_SUCCESS:
      iconComponent = <CheckIcon style={{ color: BRIGHT_GREEN }} />;
      break;
    case STATE_ERROR:
      iconComponent = <ErrorIcon style={{ color: BRIGHT_RED }} />;
      break;
    default:
      break;
  }

  return (
    <ListItem>
      <ListItemIcon>{iconComponent}</ListItemIcon>
      <ListItemText
        primary={`${siteModel.get('user_email')}`}
        secondary={`${siteModel.get('beacon_rcpn')} ${
          loadingState === STATE_ERROR ? '| Could not update sysmode' : ''
        }`}
        secondaryTypographyProps={loadingState === STATE_ERROR ? { color: 'error' } : null}
      />
    </ListItem>
  );
};

SiteRow.propTypes = {
  siteModel: PropTypes.instanceOf(SiteModel),
  siteMode: PropTypes.oneOf(Object.values(SiteMode)),
  batchIndex: PropTypes.number,
  currentBatchIndex: PropTypes.number,
};

// --

export const DialogBulkSiteModeUpdater = (props) => {
  const { isOpen, onClose, siteMode, siteCollection, onUpdatesCompleted } = props;
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  // for some insane reason this never worked with useState
  // ended up needing useReducer for the first time.
  // @see https://reactjs.org/docs/hooks-reference.html#usereducer
  const [completedSites, dispatchCompletedSites] = useReducer((state, siteId) => {
    return [...state, siteId];
  }, []);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const completedCount = completedSites.length;

    if (!completedSites.length) {
      return;
    }

    if (completedCount === siteCollection.size()) {
      setCompleted(true);
    } else if (completedCount === BATCH_COUNT * currentBatchIndex) {
      setCurrentBatchIndex(currentBatchIndex + 1);
    }
  }, [completedSites, currentBatchIndex, onUpdatesCompleted, siteCollection]);

  const handleConfirm = () => {
    setCurrentBatchIndex(1);
  };

  const handleCallCompleted = (siteId) => {
    dispatchCompletedSites(siteId);
  };

  const handleDoneClicked = () => {
    onUpdatesCompleted();
  };

  const isLoading = currentBatchIndex > 0;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
      disableBackdropClick
    >
      <DialogTitle disableTypography>
        {!isLoading && !completed && (
          <Typography>
            This will update the following Systems to <strong>Sysmode: {siteMode}</strong>
          </Typography>
        )}
        {isLoading && !completed && (
          <Typography color="textSecondary">System updates in progress...</Typography>
        )}
        {completed && (
          <Typography variant="h6">
            System updates complete. Click "Done" to return to System Dashboard
          </Typography>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {siteCollection.models.map((siteModel, index) => {
            const adjustedIndex = index + 1;
            const batchIndex =
              adjustedIndex < BATCH_COUNT ? 1 : Math.ceil(adjustedIndex / BATCH_COUNT);
            return (
              <SiteRow
                key={`site_${index}_${siteModel.id()}`}
                siteModel={siteModel}
                batchIndex={batchIndex}
                currentBatchIndex={currentBatchIndex}
                onCallCompleted={handleCallCompleted}
                siteMode={siteMode}
              />
            );
          })}
        </List>
      </DialogContent>
      {currentBatchIndex === 0 && (
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="secondary">
            Update {pluralize(siteCollection.size(), 'system')}
          </Button>
        </DialogActions>
      )}
      {completed && (
        <DialogActions>
          <Button onClick={handleDoneClicked} color="secondary">
            Done
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

DialogBulkSiteModeUpdater.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  siteMode: PropTypes.oneOf(Object.values(SiteMode)),
  siteCollection: PropTypes.instanceOf(SiteCollection).isRequired,
  onUpdatesCompleted: PropTypes.func.isRequired,
};

DialogBulkSiteModeUpdater.defaultProps = {
  isOpen: false,
};
