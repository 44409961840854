/**
 * @see https://material-ui.com/components/tables/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import { SimpleTableCell } from './SimpleTableCell';

export const SimpleTableRow = ({ cells }) => {
  return (
    <TableRow>
      {cells.map((cell, index) => (
        <SimpleTableCell key={`cell_${index}`} {...cell} />
      ))}
    </TableRow>
  );
};

SimpleTableRow.propTypes = {
  cells: PropTypes.array.isRequired,
};

SimpleTableRow.defaultProps = {
  cells: [],
};
