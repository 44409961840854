/**
 * Create a new Firmware Job
 */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Typography,
  TextField,
  // CircularProgress
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/SystemUpdateAlt';

import { FirmwareVersionSelect } from 'components';
import { JobModel, DeviceMapModel, LiveDevicesModel } from 'models';
import { APIClientContext } from 'api';
import DeviceTypeDefinitions from 'data/device-types';
import { FirmwareType } from 'types/Firmware';

const useStyles = makeStyles((theme) => ({
  form: {
    // --
  },
  textField: {
    width: 300,
  },
  inverterButton: {
    margin: theme.spacing(1),
  },
}));

export const JobCreateForm = (props) => {
  const { deviceId, hardwareVersion, deviceMapModel, liveDevicesModel, onCreated } = props;
  const apiClient = useContext(APIClientContext);
  const classes = useStyles();
  // if we have a live devices list, use those Inverters
  // if not, fallback to device map inverters
  let inverterCollection = Boolean(liveDevicesModel.get('inverters').size())
    ? liveDevicesModel.get('inverters')
    : deviceMapModel.get('inverters');
  const deviceModel = deviceMapModel.findById(deviceId);
  const deviceType = deviceModel.deviceType;
  const deviceTypeDefinition = DeviceTypeDefinitions[deviceType];

  // grab the "freshest" inverter (most recently updated)
  const freshInverter = inverterCollection.getFreshest();
  const showInverterSelection = inverterCollection.size() > 1;

  const [validation, setValidation] = useState({
    isValid: false,
    message: null,
  });

  const [fields, setFields] = useState({
    rcpn: deviceId,
    host_rcpn: freshInverter ? freshInverter.id() : '',
    firmwareVersion: '',
    hardwareVersion: hardwareVersion,
    firmwareType: '',
  });

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  function validate() {
    let isValid = true;
    let message = null;

    // validate RCPn:FirmwareType first
    if (fields.rcpn.length && fields.firmwareType) {
      if (!deviceTypeDefinition) {
        // no match for RCPn input in available RCPn types
        message = 'Invalid Device';
      } else {
        const match = deviceTypeDefinition.firmwareTypes.includes(fields.firmwareType);
        if (match < 0) {
          message = 'RCPn does not match Firmware Type';
        } else {
          message = null;
        }
      }
    } else {
      isValid = false;
    }

    if (fields.firmwareType === FirmwareType.ICM && !fields.host_rcpn) {
      isValid = false; // generic invalid, no error string
      // message = 'ICM Firmware needs a Host Inverter RCPn'
    } else if (!fields.rcpn) {
      isValid = false;
    } else if (!fields.firmwareVersion) {
      isValid = false;
    } else if (!fields.hardwareVersion) {
      isValid = false;
    }

    setValidation({
      isValid,
      message,
    });
  }

  function handleFieldChange(event) {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  }

  function handleHardwareVersionChange(event) {
    setFields({
      ...fields,
      hardwareVersion: event.target.value,
      firmwareVersion: '',
      firmwareType: '',
    });
  }

  function handleFirmwareTypeChange(value) {
    setFields({
      ...fields,
      firmwareVersion: '',
      firmwareType: value,
    });
  }

  function handleHostRcpnChange(id) {
    setFields({
      ...fields,
      host_rcpn: id,
    });
  }

  async function handleFormSubmit() {
    let payload = {
      rcpn: fields.rcpn,
      hw_version: parseInt(fields.hardwareVersion),
      hex_file: fields.firmwareVersion,
      firmware_type: fields.firmwareType,
      deliver_via_commserv: fields.deliver_via_commserv,
    };

    if (fields.host_rcpn) {
      payload.host_rcpn = fields.host_rcpn;
    }

    let model = new JobModel(payload);

    try {
      const response = await model.create(apiClient);
      // will navigate to new job
      onCreated(response.job_id);
    } catch (error) {
      alert('Error creating Firmware Job', error.message);
    }
  }

  return (
    <form noValidate autoComplete="off" className={classes.form}>
      <Box mb={3}>
        <TextField
          className={classes.textField}
          label="Target RCPn"
          name="rcpn"
          value={fields.rcpn}
          onChange={handleFieldChange}
          disabled={Boolean(deviceId)}
          required
        />
      </Box>
      <Box mb={3}>
        <TextField
          className={classes.textField}
          label="Hardware Version"
          name="hardwareVersion"
          value={fields.hardwareVersion}
          onChange={handleHardwareVersionChange}
          type="number"
          disabled={Boolean(hardwareVersion)}
          required
        />
      </Box>
      <Box mb={3}>
        <FirmwareVersionSelect
          firmwareVersionValue={fields.firmwareVersion}
          firmwareTypeValue={fields.firmwareType}
          hardwareVersionValue={fields.hardwareVersion}
          rcpnValue={fields.rcpn}
          onChange={handleFieldChange}
          onFirmwareTypeChange={handleFirmwareTypeChange}
        />
      </Box>
      <Box mb={5}>
        {showInverterSelection && (
          <Box mb={2}>
            <Box mb={1}>
              <Typography variant="subtitle2" gutterBottom>
                Select the Host Inverter RCPn
              </Typography>
            </Box>
            <Box>
              {inverterCollection.models.map((inverterModel) => {
                const id = inverterModel.id();
                const isSelected = Boolean(id === fields.host_rcpn);
                return (
                  <Button
                    className={classes.inverterButton}
                    key={id}
                    value={id}
                    color={isSelected ? 'secondary' : 'default'}
                    variant="outlined"
                    onClick={() => {
                      handleHostRcpnChange(id);
                    }}
                    size="small"
                  >
                    {id}
                  </Button>
                );
              })}
            </Box>
          </Box>
        )}
        <TextField
          className={classes.textField}
          label={`Host Inverter RCPn${
            fields.firmwareType === FirmwareType.ICM ? '' : ' (optional)'
          }`}
          name="host_rcpn"
          value={fields.host_rcpn}
          onChange={handleFieldChange}
          required={fields.firmwareType === FirmwareType.ICM}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleFormSubmit}
          fullWidth
          disabled={Boolean(validation.message) || !validation.isValid}
        >
          {/*isFetching &&
           <Box mr={2} component="span" top={2} position="relative">
             <CircularProgress size={16} color="secondary" />
           </Box>
         (*/}
          <UpdateIcon className="space-right-smallest" />
          Update
        </Button>
      </Box>
      {validation.message && (
        <div className="space-top">
          <Typography color="error">{validation.message}</Typography>
        </div>
      )}
      {/*fetchError &&
				<div className="space-top">
					<Typography color="error">
						{fetchError}
					</Typography>
				</div>
			*/}
    </form>
  );
};

JobCreateForm.propTypes = {
  // -- passed props
  deviceId: PropTypes.string.isRequired,
  hardwareVersion: PropTypes.string.isRequired,
  deviceMapModel: PropTypes.instanceOf(DeviceMapModel).isRequired,
  liveDevicesModel: PropTypes.instanceOf(LiveDevicesModel).isRequired,
  onCreated: PropTypes.func.isRequired,
};

JobCreateForm.defaultProps = {
  deviceId: '',
  hardwareVersion: '',
  deviceMapModel: new DeviceMapModel(),
  liveDevicesModel: new LiveDevicesModel(),
};
