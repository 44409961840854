import React, { Context } from 'react';
import { isEmpty } from 'lodash';
import { Config } from '../application-config';
import { URLCreator, URLTarget } from './urlCreator';

enum Method {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface Request extends URLTarget {
  data?: Object;
  headers?: Record<string, string>;
}

export class APIClient {
  readonly urlCreator: URLCreator;

  constructor(config: Config) {
    this.urlCreator = new URLCreator(config);
  }

  //TODO: What is the type here?
  private handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  private buildServerQuery(method: Method, request: Request) {
    let queryString = '';
    let body = null;
    let headers = request.headers || {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    // creates a query string only for the appropriate request methods
    // adds a body for other methods
    if (!isEmpty(request.data)) {
      if (method === Method.GET || method === Method.DELETE) {
        queryString = `?${createQueryString(request.data)}`;
      } else {
        body = JSON.stringify(request.data);
      }
    }

    let query: RequestInit = {
      method: method,
      headers: headers,
      body: body,
      credentials: 'include',
    };
    let fullURL = this.urlCreator.createUrl(request) + queryString;

    return this.callServer(fullURL, query);
  }

  private callServer(queryString: string, request: RequestInit) {
    return new Promise((resolve, reject) => {
      fetch(queryString, request)
        .then(this.handleErrors)
        .then((response) => {
          response
            .json()
            .then((json: any) => {
              if (json.hasOwnProperty('success') && json.success === false) {
                throw Error('success: false');
              }
              resolve(json);
            })
            .catch((error: any) => {
              if (error.toString() === 'SyntaxError: Unexpected end of JSON input') {
                resolve({});
              } else {
                reject(error);
              }
            });
        })
        .catch((error) => reject(error));
    });
  }

  get = (request: Request) => this.buildServerQuery(Method.GET, request);
  put = (request: Request) => this.buildServerQuery(Method.PUT, request);
  post = (request: Request) => this.buildServerQuery(Method.POST, request);
  patch = (request: Request) => this.buildServerQuery(Method.PATCH, request);
  delete = (request: Request) => this.buildServerQuery(Method.DELETE, request);
}

/**
 * @see https://github.com/github/fetch/issues/256#issuecomment-266820548
 */
const createQueryString = (params: any) => {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => esc(k) + '=' + esc(params[k]))
    .join('&');
};

const uninitializedClient = new APIClient({
  proxyTarget: "APIClient hasn't been initialized",
  domainName: "Domain name for PWRTools hasn't been initialized",
  enableCampaignsFeature: false,
});

export const APIClientContext: Context<APIClient> = React.createContext(uninitializedClient);
