import React from 'react';
import { Button } from '@material-ui/core';
import { Action } from 'types/Campaign';

interface Props {
  title: string;
  template: Action;
  onClick: (template: Action) => void;
  disabled: boolean;
}

export const CampaignPlanActionTemplateButton = (props: Props) => {
  const { title, template, disabled, onClick } = props;

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={() => onClick(template)}
      size="small"
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
