import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  DialogTitle,
  Dialog,
  FormControlLabel,
  Switch,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SimpleTableHead, MonoTableCell } from 'components';
import { JobEventCollection, JobEventModel } from 'models';
import JobEventTypeDefinitions from 'data/job-event-types';
import { JobEventType } from 'types/Job';
import { formatTimestamp, pluralize } from 'utils';

const headRows = [
  { id: 'icon', numeric: false, alignLeft: true, label: '', style: { width: 60 } },
  { id: 'type', numeric: false, alignLeft: true, label: 'Event' },
  { id: 'message', numeric: false, alignLeft: true, label: 'Message' },
  { id: 'response', numeric: false, alignLeft: true, label: 'Response' },
];

const useStyles = makeStyles((theme) => ({
  monospace: {
    fontFamily: 'Monospace',
  },
}));

const EventTableRow = (props) => {
  const { model, onOpenDialog } = props;
  const classes = useStyles();
  const display = JobEventTypeDefinitions[model.get('Type')];
  let Icon = null;

  if (display) {
    Icon = display.icon;
  }

  return (
    <TableRow tabIndex={-1}>
      <TableCell>
        {Icon ? <Icon fontSize="small" style={{ color: display.color }} /> : null}
      </TableCell>
      <TableCell>
        <div>{model.get('Type')}</div>
        <Typography variant="caption" component="div" className={classes.monospace}>
          {formatTimestamp(model.get('Time'))}
        </Typography>
      </TableCell>
      {model.get('Type') === JobEventType.PROGRESS ? (
        <MonoTableCell>{model.progress.programPct}</MonoTableCell>
      ) : (
        <TableCell>
          {model.messages.map((message, index) => (
            <Typography key={index} variant="body2" component="span">
              {message}
            </Typography>
          ))}
        </TableCell>
      )}
      <TableCell>
        <Button size="small" color="primary" variant="outlined" onClick={() => onOpenDialog(model)}>
          JSON
        </Button>
      </TableCell>
    </TableRow>
  );
};

EventTableRow.propTypes = {
  model: PropTypes.instanceOf(JobEventModel).isRequired,
  onOpenDialog: PropTypes.func.isRequired,
};

// --

export const JobEventList = (props) => {
  const { collection, isFetching, isPollingEvents, eventsPollDelay, fetchJobEvents } = props;
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [showProgressEvents, setShowProgressEvents] = React.useState(false);
  const [currentEventModel, setCurrentEventModel] = React.useState(null);

  function handleOpenDialog(model) {
    setCurrentEventModel(model);
    setDialogIsOpen(true);
  }

  function handleCloseDialog() {
    setDialogIsOpen(false);
    setCurrentEventModel(null);
  }

  let filteredEventCollection = collection;

  if (!showProgressEvents) {
    let isFirstProgressEvent = true;
    filteredEventCollection = filteredEventCollection.filter((model) => {
      if (model.get('Type') === JobEventType.PROGRESS) {
        if (isFirstProgressEvent) {
          isFirstProgressEvent = false;
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  }

  return (
    <Fragment>
      <Box mb={2} display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={showProgressEvents}
              onChange={() => setShowProgressEvents(!showProgressEvents)}
              value="showProgressEvents"
              color="secondary"
            />
          }
          label={<Typography variant="body2">Show all progress events</Typography>}
        />
        <div className="flex-grow" />
        <Box textAlign="right">
          {isPollingEvents && (
            <Box>
              <Typography variant="caption">
                Auto-refreshing every {pluralize(eventsPollDelay / 1000, 'second')}
              </Typography>
            </Box>
          )}
          <Box>
            <Tooltip title="Refresh events" placement="top">
              <div>
                <IconButton color="primary" onClick={fetchJobEvents} disabled={isFetching}>
                  <RefreshIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Paper>
        <Table aria-labelledby="tableTitle" size="small">
          <SimpleTableHead headRows={headRows} />
          <TableBody>
            {filteredEventCollection.models.map((model, index) => (
              <EventTableRow
                key={`${index}_${model.get('Time')}`}
                model={model}
                onOpenDialog={handleOpenDialog}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog open={dialogIsOpen} onClose={handleCloseDialog}>
        <Fragment>
          {currentEventModel && (
            <Fragment>
              <DialogTitle>Event JSON</DialogTitle>
              <Box
                p={3}
                mx={3}
                mb={3}
                bgcolor="background.default"
                style={{ overflow: 'auto' }}
                component="pre"
              >
                {JSON.stringify(currentEventModel.fields, null, 4)}
              </Box>
            </Fragment>
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};

JobEventList.propTypes = {
  // -- passed props
  collection: PropTypes.instanceOf(JobEventCollection).isRequired,
  isPollingEvents: PropTypes.bool,
  eventsPollDelay: PropTypes.number,
  fetchJobEvents: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  fetchError: PropTypes.string,
};
