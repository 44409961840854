import jsSHA from 'jssha';

const ambiguousCharacters = 'B8G6I1l0OQDS5Z2/+=';
const replacements = ['P', 'Y', 'K', 'A'];
export const defaultRegistrationCode = 'Invalid Serial';

const serialNumberRegex = /^X(VT07|VT11|11402)-[0-9]{5}$/;
const serialNumberRegex7602 = /^X7602-[0-9]{4,5}$/;
const pwrManagerRegex = /^LM1260-[0-9]{7}$/;

export function generateRegistrationCode(serialNumber: string): string {
  if (!serialNumberRegex.test(serialNumber) && !serialNumberRegex7602.test(serialNumber) && !pwrManagerRegex.test(serialNumber)) {
    return defaultRegistrationCode;
  }
  const hasher = new jsSHA('SHA-1', 'TEXT', {
    encoding: 'UTF8',
    hmacKey: { value: serialNumber, format: 'TEXT' },
  });
  hasher.update(serialNumber);
  const b64Message = hasher.getHash('B64');

  var i = 0;
  var output = (b64Message.slice(0, 4) + '-' + b64Message.slice(4, 8)).toUpperCase();

  for (var letter of ambiguousCharacters) {
    output = output.replaceAll(letter, replacements[i % 4]);
    i++;
  }

  return output;
}
