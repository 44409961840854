import { DeviceType } from 'types/Device';

const RCPN_LENGTH = 12;

// @todo handle RGM?
export const deviceTypeByRcpn = (rcpn: string): DeviceType => {
  if (!rcpn || rcpn.length !== RCPN_LENGTH) {
    console.error('deviceTypeByRcpn() given invalid RCPn');
    return DeviceType.UNKNOWN;
  }

  const middleBlock = rcpn.substring(4, 8);

  switch (middleBlock) {
    case '0000':
      return DeviceType.ICM;
    case '0003':
    case '0013':
    case '0023':
    case '0033':
      return DeviceType.PVL;
    case '0007':
      return DeviceType.INVERTER;
    case '0008':
      return DeviceType.BATTERY;
    case '0012':
    case '0022':
    case '0032':
      return DeviceType.BEACON;
    case '000E':
      return DeviceType.GENERATOR;
    default:
      return DeviceType.UNKNOWN;
  }
};
