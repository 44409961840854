import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Select, Button, MenuItem, FormControlLabel, Switch } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ChartControlsToolbar = props => {
  const {
    className,
    selectedData,
    dataList,
    dataMap,
    showTooltip,
    showLabels,
    onSelectedDataChange,
    onShowTooltipChange,
    onShowLabelsChange,
    onReset,
    ...rest
  } = props;
  const classes = useStyles();
  const showSelectField = Boolean(dataList && dataMap);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {showSelectField && (
        <Select
          value={selectedData}
          onChange={onSelectedDataChange}
          renderValue={selected => {
            return `${selected.length} Metrics`;
          }}
          MenuProps={{
            getContentAnchorEl: null,
          }}
          multiple
          displayEmpty
        >
          {dataList.map(dataItem => {
            const name = dataMap[dataItem].name || dataItem;
            return (
              <MenuItem key={dataItem} value={dataItem}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      )}
      <div className="flex-grow" />
      {onShowTooltipChange && (
        <FormControlLabel
          control={<Switch size="small" checked={showTooltip} onChange={onShowTooltipChange} />}
          label="Tooltip"
        />
      )}
      {onShowLabelsChange && (
        <FormControlLabel
          control={<Switch size="small" checked={showLabels} onChange={onShowLabelsChange} />}
          label="Labels"
        />
      )}
      {onReset && (
        <Button size="small" onClick={onReset}>
          Reset
        </Button>
      )}
    </div>
  );
};

ChartControlsToolbar.propTypes = {
  className: PropTypes.string,
  selectedData: PropTypes.array,
  dataList: PropTypes.array,
  dataMap: PropTypes.object,
  showTooltip: PropTypes.bool,
  showLabels: PropTypes.bool,
  onSelectedDataChange: PropTypes.func,
  onShowTooltipChange: PropTypes.func,
  onShowLabelsChange: PropTypes.func,
  onReset: PropTypes.func,
};

ChartControlsToolbar.defaultProps = {
  // --
};
