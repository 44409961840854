import { Model } from './Model';
import { JobEventModel } from './JobEventModel';
import { endpoints } from 'api';

// @temp for live testing
// const defaultFields = {
// 	rcpn: '00010007033C',
// 	hw_version: 152,
// 	hex_file: 'dev/hexes/urc/152/URC_12455_152_2P.hex'
// }

const defaultFields = {
  job_id: null,
  created_at: null, // unix timestamp
  updated_at: null, // unix timestamp
  job_type: null, // string of type data/job-types
  rcpn: null, // string
  host_rcpn: null, // string
  firmware_type: null, // string data/firmware-types
  hw_version: null, // int
  hex_file: null, // string
  progress: 0, // number
  last_event_payload: null, // JobEvent
  // @todo LastStatus will be updated to last_status in backend update
  LastStatus: null, // string of type data/firmware-job-event-type
};

export class JobModel extends Model {
  static cancelConfirmText = 'Are you sure you would like to cancel this Firmware Job?';
  static retryConfirmText = 'Are you sure you would like to retry this Firmware Job?';

  constructor(fields = defaultFields) {
    super(fields);

    this.endpoint = endpoints.device.job;
  }

  id() {
    return this.get('job_id') || this.get('id');
  }

  get retryModel() {
    return new this.constructor({
      rcpn: this.get('rcpn'),
      hw_version: this.get('hw_version'),
      hex_file: this.get('hex_file'),
      host_rcpn: this.get('host_rcpn'),
      firmware_type: this.get('firmware_type'),
    });
  }

  // --

  get lastEventModel() {
    return new JobEventModel({
      Type: this.get('LastStatus'),
      Payload: this.get('last_event_payload'),
    });
  }

  // -- api calls

  async create(apiClient) {
    const data = this.fields;
    try {
      const response = await apiClient.post({
        path: endpoints.firmware.job.create,
        data: data,
      });
      if (response.success) {
        return response;
      } else {
        throw Error('Could not create firmware job');
      }
    } catch (error) {
      throw error;
    }
  }

  async fetch(apiClient) {
    if (!this.id()) {
      console.error('JobModel needs an id to fetch');
    }

    try {
      const response = await apiClient.get({
        path: this.endpoint,
        ids: this.id(),
      });
      if (response.jobs && response.jobs.length) {
        return new this.constructor(response.jobs[0]);
      } else {
        throw Error('Job not found');
      }
    } catch (error) {
      throw error;
    }
  }

  async cancel(apiClient) {
    if (!this.id()) {
      console.error('JobModel needs an id to cancel');
    }

    const data = {};
    if (this.get('host_rcpn') !== undefined) {
      data.host_rcpn = this.get('host_rcpn');
    }

    try {
      const response = await apiClient.delete({
        path: endpoints.firmware.job.cancel,
        ids: this.id(),
        data: data,
      });
      if (response.success) {
        return true;
      } else {
        throw Error('Could not cancel job');
      }
    } catch (error) {
      throw error;
    }
  }
}
