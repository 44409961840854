/**
 * Just a regular TableCell with a monospace font
 */

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';

export const MonoTableCell = ({ children, fontSize, ...rest }) => {
  return (
    <TableCell {...rest}>
      <Box component="span" fontFamily="Monospace" fontSize={fontSize}>
        {children}
      </Box>
    </TableCell>
  );
};

MonoTableCell.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MonoTableCell.defaultProps = {
  // fontSize: 13
  fontSize: 'body1.fontSize',
};
