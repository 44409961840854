import moment from 'moment';

/**
 * Date formatting using the moment/moment-timezone libraries
 * @see https://momentjs.com/docs/
 * @see https://momentjs.com/timezone/docs
 */

export const TIMEZONE_OFFSET_FORMAT = 'UTCZ';
export const TIMESTAMP_FORMAT_TO_MINUTES = 'YYYY-MM-DD HH:mm';
export const TIMESTAMP_FORMAT_TO_SECONDS = 'YYYY-MM-DD HH:mm:ss';
export const TIMESTAMP_FORMAT_TO_SECONDS_WITH_TIMEZONE = `YYYY-MM-DD HH:mm:ss UTCZ`;

export function formatTimestamp(
  timestamp,
  timezone,
  format = TIMESTAMP_FORMAT_TO_SECONDS_WITH_TIMEZONE
) {
  if (!timestamp) {
    return '';
  }

  if (timezone) {
    return moment.tz(timestamp, timezone).format(format);
  } else {
    return moment(timestamp).format(format);
  }
}

export function formatTimeago(timestamp, timezone) {
  if (!timestamp) {
    return '';
  }

  if (timezone) {
    return moment.tz(timestamp, timezone).fromNow();
  } else {
    return moment(timestamp).fromNow();
  }
}

export function getTimezoneOffset(timezone) {
  if (timezone) {
    return formatTimestamp(new Date(), timezone, TIMEZONE_OFFSET_FORMAT);
  } else {
    return formatTimestamp(new Date(), null, TIMEZONE_OFFSET_FORMAT);
  }
}
