import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Input, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { KEY_ENTER } from 'utils/keyboard';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: 65,
  },
}));

export const NumberInput = props => {
  const { className, value, label, inputProps, buttonText, validate, onUpdate, ...rest } = props;
  const [input, setInput] = React.useState(value);
  const classes = useStyles();
  const isValid = validate(input);

  function handleKeyPress(event) {
    if (event.key !== KEY_ENTER || !isValid) {
      return;
    }

    onUpdate(input);
  }

  // @todo known Chrome bug with Number input increase/decrease UI firing
  // onChange event twice
  // @see https://github.com/mui-org/material-ui/issues/17172

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box mr={4}>
        <Input
          className={classes.input}
          type="number"
          placeholder={label}
          value={input}
          onChange={event => {
            setInput(event.target.value);
          }}
          onKeyPress={handleKeyPress}
          inputProps={inputProps}
        />
      </Box>
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={() => onUpdate(input)}
        disabled={!isValid}
      >
        {buttonText}
      </Button>
    </div>
  );
};

NumberInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  inputProps: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
};

NumberInput.defaultProps = {
  value: 1,
  label: 'Enter number',
  buttonText: 'Apply',
};
