import { Action, isActionOf } from 'typesafe-actions';
import { ActionsObservable, Epic, combineEpics } from 'redux-observable';
import { filter, mergeMap, catchError, map } from 'rxjs/operators';
import { from } from 'rxjs';
import type { Campaign, CampaignStatus } from 'types/Campaign';
import type { RootAction } from 'state/rootAction';
import { fetchCampaign, fetchCampaignStatus } from './actions';
import { campaign as testCampaign } from 'api/test/campaign';
import { campaignStatus as testCampaignStatus } from 'api/test/campaign-status';

// @todo fetch real Campaign by campaignId
const apiFetchCampaign = async (campaignId: string): Promise<Campaign> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(testCampaign);
    }, 750);
  });
};

// @todo fetch real Campaign by campaignId
const apiFetchCampaignStatus = async (campaignId: string): Promise<CampaignStatus> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(testCampaignStatus);
    }, 750);
  });
};

// @todo setup RootState in place of any
const fetchCampaignEpic: Epic<RootAction, RootAction, any> = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    filter(isActionOf(fetchCampaign.request)),
    mergeMap(({ payload: campaignId }) => {
      return from(apiFetchCampaign(campaignId)).pipe(
        map(fetchCampaign.success),
        catchError(async (e) => fetchCampaign.failure(e))
      );
    })
  );
};

// @todo setup RootState in place of any
const fetchCampaignStatusEpic: Epic<RootAction, RootAction, any> = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    filter(isActionOf(fetchCampaignStatus.request)),
    mergeMap(({ payload: campaignId }) => {
      return from(apiFetchCampaignStatus(campaignId)).pipe(
        map(fetchCampaignStatus.success),
        catchError(async (e) => fetchCampaignStatus.failure(e))
      );
    })
  );
};

export default combineEpics(fetchCampaignEpic, fetchCampaignStatusEpic);
