/**
 * Simple mappings for Device Model field keys to their presentation Strings
 */

export const ID = { id: 'ID' };
export const NAME = { name: 'Name' };
export const MANUFACTURER = { manufacturer: 'Manufacturer' };
export const VERSION = { version: 'Version' };
export const W_RTG = { w_rtg: 'Rated Power (W)' };
export const A_RTG = { a_rtg: 'Rated Current (A)' };
export const VA_RTG = { va_rtg: 'Rated Reactive Power (VAR)' };
export const AH_RTG = { ah_rtg: 'Rated Charge Capacity (Ah)' };
export const WH_RTG = { wh_rtg: 'Rated Energy Capacity (Wh)' };
export const W_CHA_MAX = { w_cha_max: 'Max Charge Rate (W)' };
export const W_DISCHA_MAX = { w_discha_max: 'Max Discharge Rate (W)' };
export const SOC_MAX = { soc_max: 'Max SoC (%)' };
export const SOC_MIN = { soc_min: 'Min SoC (%)' };
export const SOC_RSV_MAX = { soc_rsv_max: 'Max Reserve SoC (%)' };
export const SOC_RSV_MIN = { soc_rsv_min: 'Min Reserve SoC (%)' };
