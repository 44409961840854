import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import { JobEventModel } from 'models';
import JobEventTypeDefinitions from 'data/job-event-types';
import { JobEventType } from 'types/Job';
import { formatTimestamp, pluralize } from 'utils';

function EventMessages({ model }) {
  const messages = model.messages;

  return (
    <React.Fragment>
      {messages.map((message, index) => (
        <Typography key={index} variant="body2" gutterBottom component="p">
          {message}
        </Typography>
      ))}
    </React.Fragment>
  );
}

function EventCardActions({ model, onRetry, onCancel, disableActions }) {
  const [showResponse, toggleShowResponse] = React.useState(false);

  return (
    <React.Fragment>
      <CardActions disableSpacing>
        {onRetry && (
          <Button color="primary" size="small" onClick={onRetry} disabled={disableActions}>
            Retry
          </Button>
        )}
        {onCancel && (
          <Button size="small" onClick={onCancel} disabled={disableActions}>
            Cancel
          </Button>
        )}
        <Button
          size="small"
          onClick={() => toggleShowResponse(!showResponse)}
          style={{ marginLeft: 'auto' }}
          disableRipple
        >
          {`${showResponse ? 'json' : 'json'} `}
          {showResponse ? <ArrowUp /> : <ArrowDown />}
        </Button>
      </CardActions>
      <Collapse in={showResponse} timeout="auto" unmountOnExit>
        <CardContent>
          <Box p={3} bgcolor="background.default" style={{ overflow: 'auto' }} component="pre">
            {JSON.stringify(model.fields, null, 4)}
          </Box>
        </CardContent>
      </Collapse>
    </React.Fragment>
  );
}

function EventCardHeader({ model }) {
  let display = JobEventTypeDefinitions[model.get('Type')];

  if (!display) {
    display = JobEventTypeDefinitions[JobEventType.UNKNOWN];
  }

  const Icon = display.icon;

  return (
    <CardHeader
      avatar={
        <Avatar style={{ backgroundColor: display.color }}>
          <Icon />
        </Avatar>
      }
      title={display.name}
      subheader={formatTimestamp(model.get('Time'))}
    />
  );
}

// --

function StartTypeCard({ model, onCancel, disableActions }) {
  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <EventMessages model={model} />
      </CardContent>
      <EventCardActions onCancel={onCancel} model={model} disableActions={disableActions} />
    </Fragment>
  );
}

function ProgressTypeCard({ model, onCancel, disableActions }) {
  const timeLeft = model.get('Payload')['fwHandler'].timeLeft;
  const chunkPct = model.get('Payload')['fwHandler'].chunkPct;
  const totalPct = model.get('Payload')['fwHandler'].programPct;

  let timeLeftMinutes = Math.floor(timeLeft / 60);
  let timeLeftString = timeLeftMinutes === 0 ? `<1 min` : pluralize(timeLeftMinutes, 'min');

  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <Typography variant="overline" gutterBottom component="p">
          Chunk Pct: {chunkPct}
        </Typography>
        <LinearProgress color="primary" variant="determinate" value={chunkPct} />
        <Box mt={2}>
          <Typography variant="overline" gutterBottom component="p">
            Total Pct: {totalPct}
          </Typography>
          <LinearProgress color="secondary" variant="determinate" value={totalPct} />
        </Box>
        <Box my={2}>
          <Typography variant="caption" gutterBottom component="p">
            Time remaining: {timeLeftString}
          </Typography>
        </Box>
      </CardContent>
      <EventCardActions onCancel={onCancel} model={model} disableActions={disableActions} />
    </Fragment>
  );
}

function DefaultTypeCard({ model }) {
  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <EventMessages model={model} />
      </CardContent>
      <EventCardActions model={model} />
    </Fragment>
  );
}

function ErrorTypeCard({ model, onRetry, disableActions }) {
  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <EventMessages model={model} />
      </CardContent>
      <EventCardActions onRetry={onRetry} model={model} disableActions={disableActions} />
    </Fragment>
  );
}

function QueueTypeCard({ model, onCancel, disableActions }) {
  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <EventMessages model={model} />
      </CardContent>
      <EventCardActions onCancel={onCancel} model={model} disableActions={disableActions} />
    </Fragment>
  );
}

function CreateTypeCard({ model, onCancel }) {
  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <EventMessages model={model} />
      </CardContent>
      <EventCardActions model={model} />
    </Fragment>
  );
}

function CancelTypeCard({ model, onRetry, disableActions }) {
  return (
    <Fragment>
      <EventCardHeader model={model} />
      <CardContent>
        <EventMessages model={model} />
      </CardContent>
      <EventCardActions onRetry={onRetry} model={model} disableActions={disableActions} />
    </Fragment>
  );
}

// --

function getEventComponent(eventType) {
  switch (eventType) {
    case JobEventType.START:
      return StartTypeCard;
    case JobEventType.PROGRESS:
      return ProgressTypeCard;
    case JobEventType.ERROR:
      return ErrorTypeCard;
    case JobEventType.QUEUE:
      return QueueTypeCard;
    case JobEventType.CREATE:
      return CreateTypeCard;
    case JobEventType.CANCEL:
      return CancelTypeCard;
    case JobEventType.META:
    case JobEventType.DEVICE_INFO:
    case JobEventType.SETPOINTS_RETRIEVED:
    case JobEventType.SETPOINTS_PARTIAL:
    case JobEventType.SETPOINTS_METADATA:
    case JobEventType.SUCCESS:
    default:
      return DefaultTypeCard;
  }
}

// --

export const JobEventCard = (props) => {
  const { showCard, cardHeader, ...rest } = props;

  if (!props.model) {
    return null;
  }

  const EventComponent = getEventComponent(props.model.get('Type'));

  return showCard ? (
    <Card>
      {cardHeader && cardHeader}
      <EventComponent {...rest} />
    </Card>
  ) : (
    <EventComponent {...rest} />
  );
};

JobEventCard.propTypes = {
  // -- passed props
  model: PropTypes.instanceOf(JobEventModel).isRequired,
  onRetry: PropTypes.func,
  onCancel: PropTypes.func,
  showCard: PropTypes.bool.isRequired,
  cardHeader: PropTypes.node,
  disableActions: PropTypes.bool,
};

JobEventCard.defaultProps = {
  showCard: true,
};
