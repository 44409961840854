const createProxyEndpoints = () => ({
  login: '/login',
  session: '/session',
  logout: '/logout',
  sessionPhoto: '/session/photo',
});

const createApiEndpoints = (apiBase) => ({
  firmware: {
    versions: `${apiBase}/firmware/versions`,
    job: {
      create: `${apiBase}/firmware/update`,
      events: `${apiBase}/firmware/update/:id/events`,
      cancel: `${apiBase}/firmware/update/:id`,
    },
  },
  device: {
    // @todo change to deviceJobs or just jobs
    jobs: `${apiBase}/jobs/device`,
    job: `${apiBase}/jobs/device/:id`,
  },
  devices: {
    events: `${apiBase}/devices/:id/events`,
    updates: `${apiBase}/devices/:id/updates`,
    dcbData: `${apiBase}/devices/:id/dcb-data`,
    createGetSetpointsJob: `${apiBase}/devices/:id/spt`, // GET
    createPutSetpointsJob: `${apiBase}/devices/:id/spt`, // PUT
  },
  sunspecModels: `${apiBase}/models`,
  sunspecModel: {
    definition: `${apiBase}/models/:id`,
    values: `${apiBase}/sites/:siteId/devices/:deviceId/models/:sunspecModelId`,
  },
  gridProfiles: `${apiBase}/grid-profiles`,
  user: {
    id: `${apiBase}/users/id`,
    contact: `${apiBase}/users/contact`,
  },
  sites: `${apiBase}/sites`,
  site: {
    data: `${apiBase}/sites/:id`,
    mode: `${apiBase}/sites/:id/mode`,
    liveDevices: `${apiBase}/sites/:id/devices`,
    deviceMap: `${apiBase}/sites/:id/devicemap`,
    metrics: `${apiBase}/sites/:id/metrics`,
    liveDeviceSummary: `${apiBase}/sites/:siteId/devices/:deviceId/summary`,
    liveDeviceCommonModel: `${apiBase}/sites/:siteId/devices/:deviceId/models/1`,
    enableDevice: `${apiBase}/sites/:siteId/devices/:deviceId/enable`,
    disableDevice: `${apiBase}/sites/:siteId/devices/:deviceId/disable`,
    clearDeviceList: `${apiBase}/sites/:siteId/devices/:deviceId/wipeall`, //PUT
    modelId: `${apiBase}/sites/:siteId/devices/:deviceId/models/:modelId`,
    getGridProfile: `${apiBase}/sites/:siteId/devices/:deviceId/grid-profile`,
    setGridProfile: `${apiBase}/sites/:siteId/devices/:deviceId/grid-profile`, // PATCH
    complianceReports: `${apiBase}/sites/:siteId/devices/:deviceId/compliance-reports`,
    generateComplianceReport: `${apiBase}/sites/:siteId/devices/:deviceId/gen-compliance-report`, // PUT
    getComplianceReport: `${apiBase}/sites/:siteId/devices/:deviceId/compliance-reports/:fileName`,
  },
});

export const endpoints = {
  proxy: createProxyEndpoints(),
  ...createApiEndpoints('/api'),
};
