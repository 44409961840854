// import blue from '@material-ui/core/colors/blue'
// import green from '@material-ui/core/colors/green'
import { Model } from './Model';
import { JobEventType } from 'types/Job';

const defaultFields = {
  Type: null, // string - "meta", "start", "progress", "success", "error"
  Source: null, // string - always empty string?
  Time: null, // timestamp - unix timestamp
  Payload: null, // object - MetaTypePayload, StartTypePayload, ProgressTypePayload, SetpointsRetrievedTypePayload, SuccessTypePayload, ErrorTypePayload
};

export class JobEventModel extends Model {
  constructor(fields = defaultFields) {
    super(fields);
  }

  id() {
    return this.get('Time'); // there is no id on events so the timestamp will do
  }

  // --

  get setpoints() {
    return this.get('Payload')?.target_info?.spt?.points || [];
  }

  get hwVersion() {
    return this.get('Payload')?.target_info?.hw_version || null;
  }

  get swVersion() {
    return this.get('Payload')?.target_info?.sw_version || null;
  }

  /**
   * event messages may be typed Array or String
   * this will parse the message and return all messages, whatever the type, in an array
   */
  get messages() {
    let messageArray = [];
    const payload = this.get('Payload');
    if (payload && payload['message']) {
      const messages = payload['message'];

      if (Array.isArray(messages)) {
        messageArray = messages;
      } else {
        messageArray.push(messages);
      }
    }

    return messageArray;
  }

  get progress() {
    if (!this.get('Type') === JobEventType.PROGRESS) {
      return null;
    }

    return this.get('Payload')['fwHandler'];
  }

  // get progressColor() {
  // 	const colorValue = Math.floor(this.get('progress') / 10) * 100
  // 	return colorValue > 900 ? green['A400'] : blue[colorValue]
  // }
}

// -- data structures for Firmware Job Events

// everything below is for reference only, it's not being utilized yet
// everything below is for reference only, it's not being utilized yet
// everything below is for reference only, it's not being utilized yet

export const MetaTypePayload = {
  job_id: null, // string,
  message: null, // string || array
};

export const StartTypePayload = {
  job_id: null, // string,
  firmware: {
    chunks: null, // int
    compactBytes: null, // int
    crc: null, // int
    hexfile: null, // string
    processor: null, // string
    rawbytes: null, // int
    targetType: null, // string
  },
  requested_target: {
    hw_version: null, // int
    rcpn: null, // string
  },
};

export const ProgressTypePayload = {
  job_id: null, // string
  fwHandler: null, // object - FWHandler
};

export const SetPointsRetrievedTypePayload = {
  job_id: null, // string
  target_info: null, // object - TargetInfo
};

export const SuccessTypePayload = {
  job_id: null, // string
  message: null, // string || array
};

export const ErrorTypePayload = {
  job_id: null, // string
  message: null, // string || array
};

export const FWHandler = {
  // "how much of a chunck has been transmitted"
  chunkPct: null, // number
  // "probably something to do with how much of the chunk has been retransmitted"
  fidelity: null, // number
  // "overall how much of the program has been transmitted"
  programPct: null, // number
  // "windows progress bar estimate of the remaining work in seconds"
  timeLeft: null, // number
  // "under the hood time that is set aside to wait between transmissions of a unit"
  unitDelay: null, // number
};

export const TargetInfo = {
  hw_version: null, // number
  rcpn: null, // string
  spt: null, // object - TargetInfoSetpoints
};

export const TargetInfoSetpoints = {
  collected_at: null, // unix timestamp
  points: null, // array - TargetInfoSetPoint
};

export const TargetInfoSetPoint = {
  default: null, // ?
  index: null, // number
  label: null, // string
  max: null, // ?
  min: null, // ?
  sig: null, // ?
  table: null, // number
  value: null, // number
};
