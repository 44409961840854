import queryString from 'query-string';
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Box, Select, MenuItem, Typography } from '@material-ui/core';
import { APIClientContext, endpoints } from 'api';
import { SunspecTable, Alert, OverlayLoader } from 'components';
import { DeviceType } from 'types/Device';
import { SunspecModelListItem, SunspecModelListResponse } from 'types/SunspecModel';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: 400,
  },
  select: {
    fontSize: 18,
    fontWeight: 500,
  },
}));

enum UrlQuery {
  MODEL = 'model',
}

interface Props {
  siteId: string;
  deviceId: string;
  deviceType: DeviceType;
}

export const SunspecViewer = (props: Props) => {
  const { siteId, deviceId, deviceType } = props;
  const history = useHistory();
  const location = useLocation();
  const urlQuery: Record<string, any> = queryString.parse(location.search);
  const initialModelQuery: number | null = urlQuery[UrlQuery.MODEL]
    ? parseInt(urlQuery[UrlQuery.MODEL], 10)
    : null;

  const classes = useStyles();
  const apiClient = useContext(APIClientContext);
  const [error, setError] = useState<boolean>(false);
  const [sunspecModelList, setSunspecModelList] = useState<SunspecModelListItem[]>([]);
  const [sunspecModelId, setSunspecModelId] = useState<number | null>(initialModelQuery);
  const currentSunspecModel = sunspecModelList.find((model) => model.id === sunspecModelId);

  useEffect(() => {
    async function fetchSunspecModelList() {
      try {
        const response: SunspecModelListResponse = (await apiClient.get({
          path: endpoints.sunspecModels,
          data: {
            device_type: deviceType,
          },
        })) as SunspecModelListResponse;

        const modelList = response.models.sort((a, b) => a.id - b.id);

        setSunspecModelList(modelList);
        if (!sunspecModelId) {
          setSunspecModelId(modelList[0].id);
        }
        setError(false);
      } catch (error) {
        console.error(error);
        setError(true);
      }
    }

    fetchSunspecModelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, deviceType]);

  const handleSunspecModelIdChange = (event: React.ChangeEvent<any>) => {
    const modelId = event.target.value;
    const urlQueryString = queryString.stringify({ model: modelId });
    history.replace({ search: urlQueryString });
    setSunspecModelId(modelId);
  };

  return (
    <Box className={classes.container}>
      <OverlayLoader showBackground={false} showSpinner />
      <Box mt={5} mb={3}>
        {!error && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Select
                className={classes.select}
                value={sunspecModelId || ''}
                onChange={handleSunspecModelIdChange}
              >
                {sunspecModelList.map((model: SunspecModelListItem) => {
                  const { label, id } = model;
                  return (
                    <MenuItem value={id} key={`model_${id}`}>
                      {label} ({id})
                    </MenuItem>
                  );
                })}
              </Select>
              {currentSunspecModel && (
                <Box mt={1}>
                  <Typography variant="subtitle2">{currentSunspecModel.description}</Typography>
                </Box>
              )}
            </Box>
            <Typography variant="subtitle2">
              Sunspec Models are currently <strong>read-only</strong> in PWRtools
            </Typography>
          </Box>
        )}
      </Box>
      {error ? (
        // @ts-ignore
        <Alert variant="error" message="Error loading Sunspec Models" />
      ) : (
        <SunspecTable siteId={siteId} deviceId={deviceId} sunspecModelId={sunspecModelId} />
      )}
    </Box>
  );
};
