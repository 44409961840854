import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const ScrollReset = () => {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return null;
};
