import moment from 'moment';
import { Model } from './Model';
import { endpoints } from 'api';
import { ApiQueryGranularity } from 'types/ApiQuery';
import { METRIC_BUCKET_ALL, METRIC_SOURCE_ALL } from 'data/site-metric-types';

// granularity
// frequency
// offset (non-ui)
// limit (non-ui?)
// start
// end
// metric (all|energy|power)
// sort (non-ui?)
// filter (inverter_energy_in)
// source (all|inverter|battery|consumption|generation)

export const DEFAULT_QUERY = {
  start: null,
  end: null,
  offset: 0,
  granularity: ApiQueryGranularity.DAYS,
  interval: 1,
  source: METRIC_SOURCE_ALL,
  metric: METRIC_BUCKET_ALL,
};

export class SiteMetricsModel extends Model {
  constructor(fields = {}) {
    const defaultFields = {
      id: null, // string (the Site ID)
      limit: null, // number = limit
      offset: null, // number = offset
      total: null, // number of total results
      // -- metadata
      energy_over_time: null, // array of metrics responses
    };

    super(Object.assign(defaultFields, fields));
  }

  // if the queried time range is wider than the available metrics,
  // a series of results will be returned that only contain "nominal_created_at".
  // we want to find the first result that has actual metric keys available
  // so we will iterate through the "energy_over_time" array and return that result's keys
  get firstAvailableDataKeys() {
    if (!this.get('energy_over_time')) {
      return [];
    }

    const firstAvailableResult = this.get('energy_over_time').find((result) => {
      const keys = Object.keys(result);
      if (keys.length > 1) {
        return true;
      } else {
        return false;
      }
    });

    return firstAvailableResult ? Object.keys(firstAvailableResult) : [];
  }

  // -- api calls

  async fetch(apiClient, options) {
    if (!this.id()) {
      console.error('SiteMetricsModel needs an id to fetch data');
    }

    let data = Object.assign({}, DEFAULT_QUERY, options);

    // convert start and end to correct unix timestamp format
    if (data.start) {
      data.start = moment(data.start).unix();
    } else {
      // if there is no start date, set default to 2 days before now
      // data.start = getUnixTime(subDays(new Date(), 2))
      throw new Error('System Metrics query needs a start date');
    }

    if (data.end) {
      data.end = moment(data.end).unix();
    } else {
      // if there is no end date, set default to right now
      // data.end = getUnixTime(new Date())
      throw new Error('System Metrics query needs an end date');
    }

    try {
      const response = await apiClient.get({
        path: endpoints.site.metrics,
        ids: this.id(),
        data,
      });
      if (response.success === false) {
        throw Error('Could not get data');
      } else {
        this.set({ ...response });
        return new this.constructor({ ...this.fields });
      }
    } catch (error) {
      throw error;
    }
  }
}
