import React, { Context } from 'react';

export interface Config {
  readonly proxyTarget: string;
  readonly domainName: string;
  readonly enableCampaignsFeature: boolean;
}

export default async function loadConfiguration(): Promise<Config> {
  const response = await fetch('/pwrtoolsConfig.json');
  return await response.json();
}

const uninitializedConfig: Config = {
  proxyTarget: '',
  domainName: '',
  enableCampaignsFeature: false,
};

export const ConfigContext: Context<Config> = React.createContext(uninitializedConfig);
