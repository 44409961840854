import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import type { SetpointWriteData } from './Setpoint';

// --
// Campaigns
// --

export interface Campaign {
  name: string;
  id?: string; // uuid added by server
  createdBy?: string; // user email added by server
  createdDate?: Date; // timestamp added by server
  campaignStartDate?: Date;
  schedulingProfile: SchedulingProfile[];
  plans: Plan[];
}

export interface CampaignCreateRequest {
  name: string;
  campaignStartDate?: Date | MaterialUiPickersDate; // @todo temporary until i figure out the correct typing
  schedulingProfile: SchedulingProfile[];
  plans: Plan[];
}

export interface CampaignsFetchResponse {
  campaigns: Campaign[];
}

// @note new scheduling profiles to be added, MVP only supports IMMEDIATE
export enum SchedulingProfile {
  IMMEDIATE = 'IMMEDIATE',
}

// --
// Plans
// --

export interface Plan {
  systemId: string;
  actions: Action[];
}

// --
// Actions
// --

export interface Action {
  actionType: ActionType;
  rcpn: string;
  data?: object;
}

export enum ActionType {
  DELIVER_FIRMWARE = 'DELIVER_FIRMWARE',
  DISABLE_DEVICE = 'DISABLE_DEVICE',
  ENABLE_DEVICE = 'ENABLE_DEVICE',
  WRITE_SETPOINTS = 'WRITE_SETPOINTS',
}

export interface ActionDeliverFirmwareData {
  hexFilePath: string;
}
export interface ActionDeliverFirmware extends Action {
  actionType: ActionType.DELIVER_FIRMWARE;
  data: ActionDeliverFirmwareData;
}

export interface ActionDisableDevice extends Action {
  actionType: ActionType.DISABLE_DEVICE;
}

export interface ActionEnableDevice extends Action {
  actionType: ActionType.ENABLE_DEVICE;
}

export interface ActionWriteSetpoints extends Action {
  actionType: ActionType.WRITE_SETPOINTS;
  data: SetpointWriteData;
}

// --
// Templates for JSON Editor
// --

export enum ActionTemplatePlaceholder {
  RCPN = '{rcpn}',
  HEX_FILE_PATH = '{hexFilePath}',
}

export const actionDeliverFirmwareTemplate: ActionDeliverFirmware = {
  actionType: ActionType.DELIVER_FIRMWARE,
  rcpn: ActionTemplatePlaceholder.RCPN,
  data: {
    hexFilePath: ActionTemplatePlaceholder.HEX_FILE_PATH,
  },
};

export const actionDisableDeviceTemplate: ActionDisableDevice = {
  actionType: ActionType.DISABLE_DEVICE,
  rcpn: ActionTemplatePlaceholder.RCPN,
};

export const actionEnableDeviceTemplate: ActionEnableDevice = {
  actionType: ActionType.ENABLE_DEVICE,
  rcpn: ActionTemplatePlaceholder.RCPN,
};

export const actionWriteSetpointsTemplate: ActionWriteSetpoints = {
  actionType: ActionType.WRITE_SETPOINTS,
  rcpn: ActionTemplatePlaceholder.RCPN,
  data: {
    points: [
      {
        table: 0,
        index: 0,
        value: 0,
      },
    ],
    table_default_commands: [
      {
        table: 0,
        mask: [0],
      },
    ],
  },
};

// --
// Campaign Status
// --

export interface CampaignStatus {
  campaignId: string;
  campaignState: CampaignState;
  startedDate?: Date;
  endedDate?: Date;
  message?: object; // TBD
  planStatus: PlanStatus[];
}

// @note some of these are TBD
export enum CampaignState {
  UNSCHEDULED = 'UNSCHEDULED',
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  ROLLING_BACK = 'ROLLING_BACK',
  CANCELED = 'CANCELED',
  ABORTED = 'ABORTED',
  SCRUBBED = 'SCRUBBED',
}

// --
// Plan Status
// --

export interface PlanStatus {
  systemId: string;
  startedDate?: Date;
  endedDate?: Date;
  actionStatus: ActionStatus[];
}

// --
// Action Status
// --

interface ActionStatusMessage {
  success?: string;
  error?: string;
  data?: ActionDeliverFirmwareData | SetpointWriteData;
}
export interface ActionStatus {
  rcpn: string;
  actionType: ActionType;
  actionState: ActionState;
  startedDate?: Date;
  endedDate?: Date;
  message?: ActionStatusMessage;
}

export enum ActionState {
  EMPTY_ACTION_STATE = 0,
  WAITING = 1,
  RUNNING = 2,
  COMPLETED = 3,
  FAILED = 4,
  ROLLING_BACK = 5,
  SUCCESSFUL_ROLLBACK = 6,
  UNSUCCESSFUL_ROLLBACK = 7,
  CANCELED = 8,
}
