import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import DeviceTypeDefinitions from 'data/device-types';
import { DeviceType } from 'types/Device';

const useStyles = makeStyles((theme) => ({
  root: {
    //--
  },
  icon: {
    fontSize: 21,
  },
}));

export const DeviceAvatar = ({ type, className, ...rest }) => {
  const classes = useStyles();
  const deviceTypeData = DeviceTypeDefinitions[type];

  if (!deviceTypeData) {
    return null;
  }

  const { icon: Icon, color, name } = deviceTypeData;

  return (
    <Avatar
      {...rest}
      className={clsx(classes.root, className)}
      alt={name}
      style={{ backgroundColor: color }}
    >
      <Icon className={classes.icon} />
    </Avatar>
  );
};

DeviceAvatar.propTypes = {
  type: PropTypes.oneOf(Object.values(DeviceType)),
  className: PropTypes.string,
};
