// @todo setup Typescript

import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
// import { BehaviorSubject } from 'rxjs';
// import { switchMap } from 'rxjs/operators';
import rootReducer from './rootReducer';
import rootEpic from './rootEpic';

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [epicMiddleware, thunkMiddleware],
});

// @see https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
epicMiddleware.run(rootEpic);

// @todo this was taken from PWRfleet project - figure out exactly how it works
// const epic$ = new BehaviorSubject(rootEpic);
// // @ts-ignore
// const hotReloadingEpic = (...args) =>
//   epic$.pipe(
//     // @ts-ignore
//     switchMap((epic) => epic(...args))
//   );

// // @ts-ignore
// epicMiddleware.run(hotReloadingEpic);

export default store;
