import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Label } from 'components';
import {
  map as deviceStatesMap,
  DEVICE_STATE_DEFAULT,
  DEVICE_STATE_DISABLED,
} from 'data/device-states';
import { DeviceType } from 'types/Device';

export const DeviceStateLabel = (props) => {
  const { state, deviceType, ...rest } = props;
  const stateData = deviceStatesMap[state] || deviceStatesMap[DEVICE_STATE_DEFAULT];
  let disclaimer = null;

  // special case for beacon 'disabled' state to
  // provide disclaimer
  if (state === DEVICE_STATE_DISABLED && deviceType === DeviceType.BEACON) {
    disclaimer = (
      <Fragment>
        <Tooltip title="Disabled Beacon state represents a disabled TOU Scheduler" placement="top">
          <HelpIcon style={{ fontSize: 12 }} className="space-left-smallest" />
        </Tooltip>
      </Fragment>
    );
  }

  return (
    <Label color={stateData.color} {...rest}>
      {state}
      {disclaimer}
    </Label>
  );
};

DeviceStateLabel.propTypes = {
  state: PropTypes.string,
  deviceType: PropTypes.oneOf(Object.values(DeviceType)),
};
