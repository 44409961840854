import { ApiQueryType, ApiQueryGranularity } from 'types/ApiQuery';

interface Definition {
  name: string;
}

export const ApiQueryTypeDefinitions: Record<ApiQueryType, Definition> = {
  [ApiQueryType.TIMEFRAME]: {
    name: 'Timeframe',
  },
  [ApiQueryType.GRANULARITY]: {
    name: 'Granularity',
  },
  [ApiQueryType.INTERVAL]: {
    name: 'Interval',
  },
};

export const ApiQueryGranularityDefinitions: Record<ApiQueryGranularity, Definition> = {
  [ApiQueryGranularity.YEARS]: {
    name: 'Years',
  },
  [ApiQueryGranularity.WEEKS]: {
    name: 'Weeks',
  },
  [ApiQueryGranularity.DAYS]: {
    name: 'Days',
  },
  [ApiQueryGranularity.HOURS]: {
    name: 'Hours',
  },
  [ApiQueryGranularity.MINUTES]: {
    name: 'Minutes',
  },
  [ApiQueryGranularity.SECONDS]: {
    name: 'Seconds',
  },
};

export const apiQueryGranularityList = [
  ApiQueryGranularity.DAYS,
  ApiQueryGranularity.HOURS,
  ApiQueryGranularity.MINUTES,
];
