import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// @note enable this for Google Analytics
// const NODE_ENV = process.env.NODE_ENV
// const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

export const Page = (props) => {
  const { title, children, ...rest } = props;

  // @note enable this for Google Analytics
  // React.useEffect(() => {
  // 	if (NODE_ENV !== 'production') {
  // 		return
  // 	}
  //
  // 	if (window.gtag) {
  // 		window.gtag('config', GA_MEASUREMENT_ID, {
  // 			page_path: router.location.pathname,
  // 			page_name: title
  // 		})
  // 	}
  // }, [title, router])

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
};
