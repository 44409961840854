import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import JobTypeDefinitions from 'data/job-types';
import { JobType } from 'types/Job';

const useStyles = makeStyles((theme) => ({
  root: {
    //--
  },
}));

export const JobAvatar = ({ type, className, ...rest }) => {
  const classes = useStyles();
  const jobTypeData = JobTypeDefinitions[type];

  if (!jobTypeData) {
    return null;
  }

  const { icon: Icon, color, name } = jobTypeData;

  return (
    <Avatar
      {...rest}
      className={clsx(classes.root, className)}
      alt={name}
      style={{ backgroundColor: color }}
    >
      <Icon />
    </Avatar>
  );
};

JobAvatar.propTypes = {
  type: PropTypes.oneOf(Object.values(JobType)),
  className: PropTypes.string,
};
