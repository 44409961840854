import { Model } from './Model';

// for display in OtherData tables. converted to hex via utils/formulas valueToHexString
export const otherDataList = [
  'o0',
  'o1',
  'o2',
  'o3',
  'o4',
  'o5',
  'o6',
  'o7',
  'o8',
  'o9',
  'oA',
  'oB',
  'oC',
  'oD',
  'oE',
  'oF',
  'oG',
];

export class DeviceEventModel extends Model {
  static fieldMap = {
    current: 'Current',
    device_rcpn: 'Device RCPn',
    energy: 'Energy',
    o0: 'o0',
    o1: 'o1',
    o2: 'o2',
    o3: 'o3',
    o4: 'o4',
    o5: 'o5',
    o6: 'o6',
    o7: 'o7',
    o8: 'o8',
    o9: 'o9',
    oA: 'oA',
    oB: 'oB',
    oC: 'oC',
    oD: 'oD',
    oE: 'oE',
    oF: 'oF',
    oG: 'oG',
    owner: 'Owner',
    power: 'Power',
    voltage: 'Voltage',
    rebus_bits: 'Rebus Bits',
    state: 'State',
    state_name: 'State Name',
    temperature: 'Temperature',
    time: 'Time',
  };

  constructor(fields) {
    const defaultFields = {
      current: null, // 0
      device_rcpn: null, // "0001000806DA"
      energy: null, // 1697
      o0: null, // 0
      o1: null, // 2073
      o2: null, // 12301
      o3: null, // 0
      o4: null, // 0
      o5: null, // 1000
      o6: null, // 21519
      o7: null, // 22385
      o8: null, // 0
      o9: null, // 2
      oA: null, // 4
      oB: null, // 0
      oC: null, // 3276
      oD: null, // 1217
      oE: null, // 12610
      oF: null, // 12740
      oG: null, // 10680
      owner: null, // "email@gmail.com"
      voltage: null, // 3700
      power: null, // 0
      rebus_bits: null, // 7
      state: null, // 32816
      state_name: null, //"daily event"
      temperature: null, // 172
      time: null, // 1568923727
    };

    super(Object.assign({}, defaultFields, fields));
  }
}
