/**
 * @todo should probably use siteMode values instead of
 * names here but don't want to interfere with the usage
 * throughout the app just yet.
 *
 * @see data/site-modes.ts
 */

export enum SiteMode {
  SAFETY_SHUTDOWN = 'Safety Shutdown',
  GRID_TIE = 'Grid Tie',
  SELF_SUPPLY = 'Self Supply',
  CLEAN_BACKUP = 'Clean Backup',
  PRIORITY_BACKUP = 'Priority Backup',
  REMOTE_ARBITRAGE = 'Remote Arbitrage',
  SELL = 'Sell',
}
