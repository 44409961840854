/**
 * This is the first example of a typescript-friendly redux state module
 * It incorporates new patterns and libraries such as:
 * - typesafe-actions
 * - redux-observable
 * - rxjs
 *
 * These patterns are currently used in the PWRfleet project and have been employed
 * here as an example of a more powerful / strictly typed state module.
 *
 * This plays well with our "old" pattern redux modules and we can update
 * each of them incrementally
 */

import produce from 'immer';
import { createReducer, ActionType, getType } from 'typesafe-actions';
import type { Campaign } from 'types/Campaign';
import * as actions from './actions';

export interface CampaignsState {
  items: Campaign[];
  isFetching: boolean;
  error: Error | null;
}

const INITIAL_STATE: CampaignsState = {
  items: [],
  isFetching: false,
  error: null,
};

export type CampaignsActions = ActionType<typeof actions>;

export default createReducer<CampaignsState, CampaignsActions>(INITIAL_STATE, {
  [getType(actions.fetchCampaigns.request)]: produce(
    (state: CampaignsState, action: ReturnType<typeof actions.fetchCampaigns.request>) => {
      state.isFetching = true;
    }
  ),
  [getType(actions.fetchCampaigns.success)]: produce(
    (state: CampaignsState, action: ReturnType<typeof actions.fetchCampaigns.success>) => {
      state.isFetching = false;
      state.items = action.payload.campaigns;
    }
  ),
  [getType(actions.fetchCampaigns.failure)]: produce(
    (state: CampaignsState, action: ReturnType<typeof actions.fetchCampaigns.failure>) => {
      state.isFetching = false;
      state.error = action.payload;
    }
  ),
});
