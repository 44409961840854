import { colors } from '@material-ui/core';

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const BLUE = '#7dbad7'; // Brand Blue
export const LIGHT_BLUE = '#dcf1f9'; // Brand Light Blue
export const GREEN = '#00954d'; // Brand Green
export const BRIGHT_BLUE = colors.blue['A400'];
export const LIGHT_BRIGHT_GREEN = colors.green['A100'];
export const BRIGHT_GREEN = colors.green['A700'];
export const DARK_BLUE = '#1e67a7';
export const BLUEGREY = colors.blueGrey[500];
export const LIGHT_GREY = colors.grey[100];
export const GREY = colors.grey[400];
export const DARK_GREY = colors.grey[600];
export const BRIGHT_RED = colors.red[500];
export const LIGHT_RED = colors.red[50];
export const PURPLE = colors.purple[400];
export const DARK_PURPLE = colors.purple[700];
export const BRIGHT_PURPLE = colors.purple['A700'];
export const YELLOW = colors.yellow[400];
export const LIGHT_YELLOW = colors.yellow[50];
export const BRIGHT_TEAL = colors.teal['A400'];
export const BRIGHT_ORANGE = colors.deepOrange['A400'];
export const LIGHT_ORANGE = colors.deepOrange[50];
export const ORANGE = colors.deepOrange[300];

export const COLOR_INDEX = [
  colors.lightBlue['A400'],
  colors.teal['A400'],
  colors.pink['A400'],
  colors.purple['A400'],
  colors.cyan['A400'],
  colors.deepOrange['A400'],
  colors.lightGreen['A400'],
  colors.red['A100'],
  colors.indigo['A100'],
  colors.blue['A100'],
  colors.pink['A100'],
  colors.deepOrange['A100'],
  colors.green['A100'],
  colors.purple['A100'],
];

export const PURPLE_INDEX = [
  colors.purple['A400'],
  colors.purple['A200'],
  colors.purple['A100'],
  colors.purple['A700'],
  colors.deepPurple['A400'],
  colors.deepPurple['A200'],
  colors.deepPurple['A100'],
  colors.deepPurple['A700'],
  colors.indigo['A400'],
  colors.indigo['A200'],
  colors.indigo['A100'],
  colors.indigo['A700'],
];

export const BLUE_INDEX = [
  colors.lightBlue['A400'],
  colors.lightBlue['A200'],
  colors.lightBlue['A100'],
  colors.lightBlue['A700'],
  colors.cyan['A400'],
  colors.cyan['A200'],
  colors.cyan['A100'],
  colors.cyan['A700'],
  colors.blue['A400'],
  colors.blue['A200'],
  colors.blue['A100'],
  colors.blue['A700'],
];

export const RED_INDEX = [
  colors.pink['A400'],
  colors.pink['A200'],
  colors.pink['A100'],
  colors.pink['A700'],
  colors.red['A400'],
  colors.red['A200'],
  colors.red['A100'],
  colors.red['A700'],
  colors.deepOrange['A400'],
  colors.deepOrange['A200'],
  colors.deepOrange['A100'],
  colors.deepOrange['A700'],
];

export const ORANGE_INDEX = [
  colors.orange['A400'],
  colors.orange['A200'],
  colors.orange['A100'],
  colors.orange['A700'],
  colors.deepOrange['A400'],
  colors.deepOrange['A200'],
  colors.deepOrange['A100'],
  colors.deepOrange['A700'],
  colors.amber['A400'],
  colors.amber['A200'],
  colors.amber['A100'],
  colors.amber['A700'],
];

export const GREEN_INDEX = [
  colors.teal['A400'],
  colors.teal['A200'],
  colors.teal['A100'],
  colors.teal['A700'],
  colors.green['A400'],
  colors.green['A200'],
  colors.green['A100'],
  colors.green['A700'],
  colors.lightGreen['A400'],
  colors.lightGreen['A200'],
  colors.lightGreen['A100'],
  colors.lightGreen['A700'],
];

export const YELLOW_INDEX = [
  colors.yellow['A400'],
  colors.yellow['A200'],
  colors.yellow['A100'],
  colors.yellow['A700'],
  colors.amber['A400'],
  colors.amber['A200'],
  colors.amber['A100'],
  colors.amber['A700'],
  colors.orange['A400'],
  colors.orange['A200'],
  colors.orange['A100'],
  colors.orange['A700'],
];

export default {
  black: BLACK,
  white: WHITE,
  primary: {
    contrastText: WHITE,
    dark: colors.blue[900],
    main: colors.blue[800],
    light: colors.blue[100],
  },
  secondary: {
    contrastText: WHITE,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400'],
  },
  error: {
    contrastText: WHITE,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: WHITE,
  },
  divider: colors.grey[200],
};

// export default {
// 	black: BLACK,
// 	white: WHITE,
// 	background: {
// 		default: '#F4F6F8',
// 		paper: WHITE
// 	},
// 	primary: {
// 		main: DARK_BLUE,
// 		contrastText: WHITE
// 		// light: will be calculated from palette.primary.main,
// 		// dark: will be calculated from palette.primary.main,
// 	},
// 	secondary: {
// 		main: BRIGHT_BLUE,
// 		contrastText: WHITE
// 	},
// 	error: {
// 		main: BRIGHT_RED
// 	}
// }
