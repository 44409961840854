import { Model } from './Model';

export class UserModel extends Model {
  constructor(fields = {}) {
    const defaultFields = {
      generac_user_id: null,
      email: null,
      first_name: null,
      last_name: null,
      name: null,
      google_id: null,
      microsoft_id: null,
    };

    super(Object.assign(defaultFields, fields));
  }

  get fullName() {
    if (this.get('first_name')) {
      return `${this.get('first_name')} ${this.get('last_name')}`;
    } else {
      return this.get('name');
    }
  }
}
