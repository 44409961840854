export enum DataUnit {
  BITFIELD,
  CATEGORY,
  CHARGE_AMP_HOURS,
  CHARGE_STATE,
  CURRENT_AMPS,
  ENERGY_KILOWATT_HOURS,
  ENERGY_WATT_HOURS,
  OTHER_DATA,
  POWER_WATTS,
  TEMPERATURE_CELSIUS,
  TIME,
  VOLTAGE,
}
