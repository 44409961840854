import { createAsyncAction } from 'typesafe-actions';
import type { Campaign, CampaignStatus } from 'types/Campaign';

export const fetchCampaign = createAsyncAction(
  'FETCH_CAMPAIGN_REQUEST',
  'FETCH_CAMPAIGN_SUCCESS',
  'FETCH_CAMPAIGN_FAILURE'
)<string, Campaign, Error>();

export const fetchCampaignStatus = createAsyncAction(
  'FETCH_CAMPAIGN_STATUS_REQUEST',
  'FETCH_CAMPAIGN_STATUS_SUCCESS',
  'FETCH_CAMPAIGN_STATU_FAILURE'
)<string, CampaignStatus, Error>();
