/**
 * Collection Base
 *
 * @see http://k33g.github.io/2014/07/05/ES6-IN-ACTION-WITH-MODELS.html
 */

import { sortBy } from 'lodash';
import { Model } from './Model';

export class Collection {
  constructor(data, model) {
    this.className = this.constructor.name;
    this.model = model || Model;

    if (data) {
      this.createModels(data);
    } else {
      this.models = [];
    }
  }

  add(models) {
    if (models instanceof Array) {
      this.models.concat(models);
    } else {
      this.models.push(models);
    }

    return this;
  }

  addModel(newModel) {
    return new this.constructor([newModel, ...this.models]);
  }

  updateModel(updatedModel) {
    const models = this.models.map(model => {
      return model.id() === updatedModel.id() ? updatedModel : model;
    });

    return new this.constructor(models);
  }

  updateOrAddModel(newModel) {
    if (this.findById(newModel.id())) {
      return this.updateModel(newModel);
    } else {
      return this.addModel(newModel);
    }
  }

  updateModelAtIndex(index, updatedModel) {
    const models = this.models.map((model, i) => {
      return index === i ? updatedModel : model;
    });

    return new this.constructor(models);
  }

  removeModel(id) {
    const model = this.findById(id);
    const index = this.models.indexOf(model);

    if (index > -1) {
      this.models.splice(index, 1);
    }

    return new this.constructor(this.models);
  }

  size() {
    return this.models.length;
  }

  createModels(models) {
    this.models = [];

    if (models && models.length) {
      models.forEach(data => {
        if (data.className) {
          this.add(data);
        } else {
          this.add(new this.model(data));
        }
      });
    }
  }

  /**
   * Returns new Collection with models filtered based on filterMethod
   *
   * @param {Function} filterMethod
   * @returns {Collection}
   */
  filter(filterMethod) {
    const filteredModels = this.models.filter(filterMethod);
    return new this.constructor(filteredModels, this.model);
  }

  // gets

  /**
   * Returns array of all model ids
   *
   * @returns {Array}
   */
  getIds() {
    if (!this.size()) {
      return [];
    } else {
      return this.models.map(model => model.id());
    }
  }

  getFirst() {
    if (!this.size()) {
      return null;
    } else {
      return this.models[0];
    }
  }

  getAt(index) {
    if (!this.size()) {
      return null;
    } else {
      if (!this.models[index]) {
        return null;
      } else {
        return this.models[index];
      }
    }
  }

  /**
   * Returns new Collection with models filtered based on filterMethod
   *
   * @param {Function} filterMethod
   * @returns {Collection}
   */
  sortBy(sortMethod) {
    const sortedModels = sortBy(this.models, sortMethod);
    return new this.constructor(sortedModels, this.model);
  }

  /**
   * @param {String} | id
   * @return {Classifier}
   */
  findById(id) {
    return this.models.find(model => model.id() === id) || null;
  }
}
