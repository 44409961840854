/**
 * This component will check the user's role/access against
 * the './role-config.js' and redirect to 404 if user does not
 * have the required privileges to access given route.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { testRoleAccess } from 'role-config';

const ProtectedRoute = props => {
  const { roles: allowedRoles, component: Component, ...rest } = props;
  const session = useSelector(state => state.session);
  const { userRole } = session;
  const hasAccess = testRoleAccess(props.path, allowedRoles, userRole);

  return (
    <Route
      {...rest}
      render={routeProps =>
        hasAccess ? <Component {...routeProps} /> : <Redirect to="/errors/error-404" />
      }
    />
  );
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  roles: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

ProtectedRoute.defaultProps = {
  roles: [],
};

export default ProtectedRoute;
