/**
 * @todo this chart could be even Smarter
 */

import { uniq, isNil } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Bar } from 'react-chartjs-2';
import { stringToColor } from 'utils';
import { COLOR_INDEX } from 'theme';
import { ChartStyle, ChartAxisType } from 'types/Chart';
import { TIMESTAMP_FORMAT_TO_SECONDS_WITH_TIMEZONE } from 'utils';
/* eslint-disable-next-line no-unused-vars */
import ChartPluginZoom from 'chartjs-plugin-zoom';
/* eslint-disable-next-line no-unused-vars */
import ChartPluginDatalabels from 'chartjs-plugin-datalabels';

const Y_AXIS_ID_PREFIX = 'y-axis';

const useStyles = makeStyles((theme) => ({
  root: {
    // --
    overflow: 'visible',
  },
  inner: {
    minWidth: 400,
  },
  chart: {
    height: 450,
    position: 'relative',
  },
}));

export const SmartChart = (props) => {
  const {
    showDataLabels,
    showTooltip,
    className,
    datasets,
    labels,
    displayLegend,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  let setIndexer = {};

  const preparedDataSets = datasets.map((dataset, index) => {
    const {
      data: dataProp,
      prepareData,
      chartStyle,
      name,
      type,
      formatValue,
      symbol,
      chartAxisType,
    } = dataset;

    let color = index < COLOR_INDEX.length ? COLOR_INDEX[index] : stringToColor(name);

    if (isNil(setIndexer[type])) {
      let indexerLabels = null;
      let axisTitle = type;

      if (chartAxisType === ChartAxisType.CATEGORY) {
        indexerLabels = dataset.yAxisLabels || uniq(dataProp);
        axisTitle = name;
      } else if (symbol) {
        axisTitle += ` (${symbol})`;
      }

      setIndexer[type] = {
        type,
        chartStyle,
        formatValue,
        symbol,
        chartAxisType,
        labels: indexerLabels,
        axisTitle,
      };
    }

    return {
      yAxisID: `${Y_AXIS_ID_PREFIX}-${type}`,
      data: prepareData ? prepareData(dataProp) : dataProp,
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: showDataLabels ? 'auto' : false,
        backgroundColor: color,
        color: '#fff',
      },
      type: chartStyle || ChartStyle.LINE,
      label: name,
      backgroundColor: fade(color, 0.5),
      borderColor: color,
      borderWidth: 1,
      pointRadius: 1,
      lineTension: 0,
      barThickness: 'flex',
      symbol,
    };
  });

  const chartData = {
    datasets: preparedDataSets,
    labels,
  };

  // -- set up axes based on the setIndexer
  const yAxisKeys = Object.keys(setIndexer);
  const yAxesFormatted = yAxisKeys.map((key, index) => {
    const { chartAxisType, axisTitle, labels } = setIndexer[key];
    const yAxisPosition = yAxisKeys.length > 1 ? (index === 0 ? 'left' : 'right') : 'right';

    return {
      id: `${Y_AXIS_ID_PREFIX}-${key}`,
      labels,
      type: chartAxisType || ChartAxisType.LINEAR,
      position: yAxisPosition,
      scaleLabel: {
        display: true,
        labelString: axisTitle,
      },
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        color: theme.palette.divider,
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
        zeroLineColor: theme.palette.divider,
      },
      ticks: {
        padding: 10,
        fontColor: theme.palette.text.secondary,
      },
    };
  });

  const options = {
    plugins: {
      zoom: {
        pan: {
          enabled: false,
          mode: 'x',
        },
        zoom: {
          enabled: true,
          drag: true,
          mode: 'x',
        },
      },
      datalabels: {
        formatter: (value, context) => {
          let formattedValue = value;
          if (typeof value == 'number') {
            formattedValue = Math.round(value * 1e3) / 1e3;
          }
          if (context.dataset.symbol) {
            return `${formattedValue} ${context.dataset.symbol}`;
          } else {
            return formattedValue;
          }
        },
        clip: false,
        clamp: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: displayLegend,
      position: 'bottom',
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 25,
        bottom: 10,
      },
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            tooltipFormat: TIMESTAMP_FORMAT_TO_SECONDS_WITH_TIMEZONE,
            displayFormats: {
              day: 'M/D',
              hour: 'M/D HH:00',
              minute: 'M/D HH:mm',
              second: 'M/D HH:mm:ss',
            },
          },
          ticks: {
            padding: 10,
            fontColor: theme.palette.text.secondary,
            maxRotation: 15,
            maxTicksLimit: 8,
            beginAtZero: true,
          },
        },
      ],
      yAxes: yAxesFormatted,
    },
    tooltips: {
      enabled: showTooltip,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: (tooltipItem) => {
          return tooltipItem[0].label;
        },
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];

          let label = dataset.label || '';
          let symbol = dataset.symbol || '';
          let value = tooltipItem.yLabel;
          if (typeof value == 'number') {
            value = Math.round(value * 1e2) / 1e2;
          }

          if (label) {
            label += ': ';
          }

          if (symbol) {
            value += ` ${symbol}`;
          }

          return (label += value);
        },
      },
    },
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.inner}>
        <div className={classes.chart}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

SmartChart.propTypes = {
  className: PropTypes.string,
  datasets: PropTypes.array.isRequired,
  labels: PropTypes.array,
  showDataLabels: PropTypes.bool.isRequired,
  showTooltip: PropTypes.bool.isRequired,
  displayLegend: PropTypes.bool.isRequired,
};

SmartChart.defaultProps = {
  showDataLabels: false,
  showTooltip: true,
  displayLegend: true,
};
