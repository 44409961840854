import { pickBy, identity } from 'lodash';
import { endpoints } from 'api';
import { JobModel, JobCollection } from 'models';

export const QUERY_JOB_RCPN = 'rcpn';
export const QUERY_JOB_TYPE = 'jobType';
export const QUERY_JOB_STATUS = 'status';

const QUERY_JOB_LIST_LIMIT = 1000;

const initialState = {
  collection: new JobCollection(),
  isFetching: false,
  fetchError: null,
};

export const MODULE_NAME = 'jobs';

const RESET = 'JOBS_RESET';
const REQUESTED = 'JOBS_REQUESTED';
const FETCHED = 'JOBS_FETCHED';
const CREATED_MODEL = 'JOB_CREATED_MODEL';
const DESTROYED_MODEL = 'JOB_DESTROYED_MODEL';
const FAILED = 'JOBS_FAILED';

// receive can take FETCHED, CREATED_MODEL, DESTROYED, REPLACED actions
const receive = (action, data) => ({ type: action, payload: data });
const reset = () => ({ type: RESET });
const request = () => ({ type: REQUESTED });
const fail = (error) => ({ type: FAILED, payload: error });

// --

const fetchAll = (apiClient, queryData = {}) => {
  return (dispatch, getState) => {
    dispatch(request());

    // this will remove all empty/null/undefineds from queryData
    const cleanQueryData = pickBy(queryData, identity);
    const data = Object.assign({}, cleanQueryData, {
      // @todo this is an arbitrary limit until we develop pagination
      limit: QUERY_JOB_LIST_LIMIT,
      // @todo offset / pagination
    });

    apiClient
      .get({
        path: endpoints.device.jobs,
        data,
      })
      .then((response) => {
        if (!response || !response.jobs) {
          throw Error('Could not load jobs.');
        }

        dispatch(receive(FETCHED, response.jobs));
      })
      .catch((error) => {
        /**
         * @todo there is a currently an error with the jobs endpoint that returns
         * irregular JSON { "success": false }{"jobs":null} when no jobType or status is added to the query.
         * will return temporary error message in this case.
         */
        // dispatch(fail(error.message || error))
        dispatch(
          fail('There has been an error fetching Jobs. Try filtering by a Job Type or Last Status.')
        );
      });
  };
};

const ACTION_HANDLERS = {
  [RESET]: (state, action) => initialState,
  [REQUESTED]: (state, action) => ({
    ...state,
    isFetching: true,
    fetchError: null,
  }),
  [FETCHED]: (state, action) => ({
    ...state,
    collection: new JobCollection(action.payload),
    isFetching: false,
    fetchError: null,
  }),
  [CREATED_MODEL]: (state, action) => ({
    ...state,
    collection: state.collection.addModel(new JobModel({ ...action.payload })),
    fetchError: null,
    isFetching: false,
  }),
  [DESTROYED_MODEL]: (state, action) => ({
    ...state,
    collection: state.collection.removeModel(action.payload),
    fetchError: null,
    isFetching: false,
  }),
  [FAILED]: (state, action) => ({
    ...state,
    fetchError: action.payload,
    isFetching: false,
  }),
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export const actions = {
  reset,
  fetchAll,
};
