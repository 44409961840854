import { ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import StartIcon from '@material-ui/icons/PowerSettingsNew';
import CreateIcon from '@material-ui/icons/AddCircle';
import QueueIcon from '@material-ui/icons/Queue';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessIcon from '@material-ui/icons/Check';
import MetaIcon from '@material-ui/icons/Code';
import DownloadIcon from '@material-ui/icons/GetApp';
import SyncIcon from '@material-ui/icons/Sync';
import ProgressIcon from '@material-ui/icons/MoreHoriz';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  BRIGHT_RED,
  BRIGHT_GREEN,
  DARK_BLUE,
  BLUE,
  PURPLE,
  BRIGHT_PURPLE,
  BRIGHT_TEAL,
  BLUEGREY,
  GREY,
} from 'theme';
import { JobEventType } from 'types/Job';

interface Definition {
  name: string;
  icon: ComponentType<SvgIconProps>;
  color: string;
}

const JobEventTypeDefinitions: Record<JobEventType, Definition> = {
  [JobEventType.META]: {
    name: 'Meta',
    icon: MetaIcon,
    color: GREY,
  },
  [JobEventType.START]: {
    name: 'Start',
    icon: StartIcon,
    color: BLUEGREY,
  },
  [JobEventType.PROGRESS]: {
    name: 'Progress',
    icon: ProgressIcon,
    color: DARK_BLUE,
  },
  [JobEventType.DEVICE_INFO]: {
    name: 'Device Info',
    icon: InfoIcon,
    color: GREY,
  },
  [JobEventType.SETPOINTS_RETRIEVED]: {
    name: 'Setpoints: Retrieved',
    icon: DownloadIcon,
    color: BRIGHT_TEAL,
  },
  [JobEventType.SETPOINTS_PARTIAL]: {
    name: 'Setpoints: Partial',
    icon: SyncIcon,
    color: BRIGHT_PURPLE,
  },
  [JobEventType.SETPOINTS_METADATA]: {
    name: 'Setpoints: Metadata',
    icon: DownloadIcon,
    color: BRIGHT_TEAL,
  },
  [JobEventType.SUCCESS]: {
    name: 'Success',
    icon: SuccessIcon,
    color: BRIGHT_GREEN,
  },
  [JobEventType.ERROR]: {
    name: 'Error',
    icon: ErrorIcon,
    color: BRIGHT_RED,
  },
  [JobEventType.QUEUE]: {
    name: 'Queue',
    icon: QueueIcon,
    color: PURPLE,
  },
  [JobEventType.CREATE]: {
    name: 'Create',
    icon: CreateIcon,
    color: BLUE,
  },
  [JobEventType.CANCEL]: {
    name: 'Cancel',
    icon: CancelIcon,
    color: BRIGHT_RED,
  },
  [JobEventType.UNKNOWN]: {
    name: 'Unknown Event Type',
    icon: HelpIcon,
    color: GREY,
  },
};

export default JobEventTypeDefinitions;

export const jobEventTypesList = [
  JobEventType.META,
  JobEventType.START,
  JobEventType.PROGRESS,
  JobEventType.DEVICE_INFO,
  JobEventType.SETPOINTS_RETRIEVED,
  JobEventType.SETPOINTS_PARTIAL,
  JobEventType.SETPOINTS_METADATA,
  JobEventType.SUCCESS,
  JobEventType.ERROR,
  JobEventType.QUEUE,
  JobEventType.CREATE,
  JobEventType.CANCEL,
];
