import { Model } from './Model';
import { Collection } from './Collection';
import {
  LiveBeaconModel,
  LiveInverterModel,
  LiveICMModel,
  LiveRGMModel,
  LiveBatteryModel,
  LivePVLModel,
  LiveGeneratorModel,
} from './LiveDeviceModel';
import { DeviceType } from 'types/Device';

export class LiveDeviceCollection extends Collection {
  // in the case of multiple devices
  // return the most recently updated device model
  getFreshest() {
    if (this.size() === 0) {
      return null;
    }
    if (this.size() > 1) {
      // need to inverse to have highest number (most recent date) at index 0
      return this.sortBy((m) => m.get('last_heard') * -1).getAt(0);
    } else {
      return this.getAt(0);
    }
  }
}

// --

export class LiveDevicesModel extends Model {
  constructor(fields = []) {
    super({
      beacons: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.BEACON),
        LiveBeaconModel
      ),
      inverters: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.INVERTER),
        LiveInverterModel
      ),
      icms: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.ICM),
        LiveICMModel
      ),
      rgms: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.RGM),
        LiveRGMModel
      ),
      batteries: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.BATTERY),
        LiveBatteryModel
      ),
      pvls: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.PVL),
        LivePVLModel
      ),
      generators: new LiveDeviceCollection(
        fields.filter((m) => m.type === DeviceType.GENERATOR),
        LiveGeneratorModel
      ),
    });
  }

  get deviceModels() {
    return [
      ...this.get('beacons').models,
      ...this.get('inverters').models,
      ...this.get('icms').models,
      ...this.get('batteries').models,
      ...this.get('pvls').models,
      ...this.get('rgms').models,
      ...this.get('generators').models,
    ];
  }

  get freshestInverter() {
    return this.get('inverters').getFreshest();
  }

  get siteMode() {
    const freshInverter = this.freshestInverter;

    if (freshInverter) {
      return freshInverter.get('sysmode');
    }

    return null;
  }

  get inverterLastHeard() {
    const freshInverter = this.freshestInverter;

    if (freshInverter) {
      return freshInverter.get('last_heard');
    }

    return null;
  }

  get batteryPower() {
    const batteryModel = this.get('batteries').getAt(0);
    return batteryModel ? batteryModel.get('power') : '-';
  }

  get inverterPower() {
    const inverterModel = this.get('inverters').getAt(0);
    return inverterModel ? inverterModel.get('power') : '-';
  }

  get generatorPower() {
    const generatorModel = this.get('generators').getAt(0);
    return generatorModel ? generatorModel.get('power') : '-';
  }

  get stateOfCharge() {
    const batteryModel = this.get('batteries').getAt(0);
    return batteryModel ? batteryModel.get('soc') : '-';
  }
}
