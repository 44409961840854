import React, { Fragment, Suspense, useState, lazy, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { ToolsNav, ToolsAppBar } from 'components';
import ProtectedRoute from 'ProtectedRoute';
import { ROLE_ADMIN } from 'role-config';
import { ConfigContext } from 'application-config';

// this is used for code-splitting to optimize loading of React routes
// @see https://reactjs.org/docs/code-splitting.html#reactlazy
const SitesDashboard = lazy(() => import('views/SitesDashboard'));
const SiteDetails = lazy(() => import('views/SiteDetails'));
const DeviceDetails = lazy(() => import('views/DeviceDetails'));
const JobsDashboard = lazy(() => import('views/JobsDashboard'));
const CampaignsDashboard = lazy(() => import('views/CampaignsDashboard'));
const CampaignCreate = lazy(() => import('views/CampaignCreate'));
const CampaignDetails = lazy(() => import('views/CampaignDetails'));
const JobDetails = lazy(() => import('views/JobDetails'));

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));

export const Tools = () => {
  const classes = useStyles();
  const location = useLocation();
  const appConfig = useContext(ConfigContext);
  const session = useSelector((state) => state.session);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  // stops all unauthenticated sessions from entering Tools App Layuot
  // and redirects to Authentication Layout with the intended state path
  // and query params (location.search)
  if (!session.isLoggedIn) {
    return (
      <Redirect
        to={{
          search: location.search,
          state: location.pathname,
          pathname: '/auth/login',
        }}
      />
    );
  }

  // [role-access]
  // ProtectedRoutes check for user's role before allowing route
  return (
    <Fragment>
      <ToolsAppBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <ToolsNav onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
      <div className={classes.container}>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              <Route path="/systems" exact component={() => <Redirect to="/systems/dashboard" />} />
              <Route path="/systems/dashboard" exact component={SitesDashboard} />
              <Redirect from="/sites/:id/:tab?" exact to="/systems/:id/:tab?" />
              <Route path="/systems/:id/:tab?" exact component={SiteDetails} />
              <Redirect
                from="/sites/:siteId/devices/:deviceId/:tab?"
                exact
                to="/systems/:siteId/devices/:deviceId/:tab?"
              />
              <Route
                path="/systems/:siteId/devices/:deviceId/:tab?"
                exact
                component={DeviceDetails}
              />
              <ProtectedRoute
                path="/dfu"
                exact
                roles={[ROLE_ADMIN]}
                component={() => <Redirect to="/dfu/jobs" />}
              />
              {appConfig.enableCampaignsFeature && (
                <ProtectedRoute
                  path="/dfu/campaigns"
                  exact
                  roles={[ROLE_ADMIN]}
                  component={CampaignsDashboard}
                />
              )}
              {appConfig.enableCampaignsFeature && (
                <ProtectedRoute
                  path="/dfu/campaigns/new"
                  exact
                  roles={[ROLE_ADMIN]}
                  component={CampaignCreate}
                />
              )}
              {appConfig.enableCampaignsFeature && (
                <ProtectedRoute
                  path="/dfu/campaigns/:id/:tab?"
                  exact
                  roles={[ROLE_ADMIN]}
                  component={CampaignDetails}
                />
              )}
              <ProtectedRoute
                path="/dfu/jobs"
                exact
                roles={[ROLE_ADMIN]}
                component={JobsDashboard}
              />
              <ProtectedRoute
                path="/dfu/jobs/:id/:tab?"
                exact
                roles={[ROLE_ADMIN]}
                component={JobDetails}
              />
              <Redirect to="/errors/error-404" />
            </Switch>
          </Suspense>
        </main>
      </div>
    </Fragment>
  );
};

Tools.propTypes = {
  route: PropTypes.object,
};
