import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, Typography, Button, SvgIcon } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Page } from 'components';
import { actions as sessionActions } from 'state/modules/session';
import gradients from 'utils/gradients';
import { APIClientContext, endpoints } from 'api';
import { ReactComponent as MicrosoftIcon } from 'assets/logo_microsoft.svg';
import { ConfigContext } from 'application-config';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  logoIcon: {
    marginRight: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  hiddenLoginButton: {
    display: 'none',
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClient = useContext(APIClientContext);
  const config = useContext(ConfigContext);
  const { isLoggedIn, isFetching } = useSelector((state) => state.session);

  // a redirect to /auth/login from the Tools layout will contain a ?state=
  // query param. we will send this to the proxy login endpoint to redirect
  // to this state after login
  const { state: redirectPath, search: searchQuery } = useLocation();

  let stateQuery = '';
  if (redirectPath) {
    stateQuery = `&${queryString.stringify({ state: `${redirectPath}${searchQuery}` })}`;
  }
  const redirectHost = `?redirect=${config.domainName}`;
  const loginLink = apiClient.urlCreator.createUrl({
    path: endpoints.proxy.login + redirectHost + stateQuery,
    ids: {},
  });

  /**
   * this controls the "restart session" action from the
   * /auth/login/restart redirect from Tools Layout
   */
  useEffect(() => {
    // check if session exists on proxy server
    dispatch(sessionActions.getSession(apiClient));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    return <Redirect to={{ search: searchQuery, pathname: redirectPath }} />;
  }

  // if currently fetching a /session to check if user can enter app,
  // show a loading state and prevent login button from rendering
  if (isFetching) {
    return (
      <Page className={classes.root} title="Login">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <RefreshIcon className={classes.icon} />
            <Typography gutterBottom variant="h4">
              Validating session...
            </Typography>
          </CardContent>
        </Card>
      </Page>
    );
  }

  return (
    <Page className={classes.root} title="Login">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <LockIcon className={classes.icon} />
          <Typography gutterBottom variant="h3">
            PWRtools
          </Typography>
          <div>
            <Box my={3}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                href={loginLink}
                size="large"
              >
                <SvgIcon className={classes.logoIcon} component={MicrosoftIcon} />
                Sign in with Microsoft
              </Button>
            </Box>
          </div>
          {/*(fetchError) &&
						<Fragment>
							<Divider className={classes.divider} />
							<Typography variant="body2" color="error">
								{fetchError}
							</Typography>
						</Fragment>
					*/}
        </CardContent>
      </Card>
    </Page>
  );
};

Login.propTypes = {
  // --
};

export default Login;
