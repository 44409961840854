/**
 * @see https://material-ui.com/components/tables/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody } from '@material-ui/core';
import { SimpleTableHead } from './SimpleTableHead';
import { SimpleTableRow } from './SimpleTableRow';

// used separately and needs own export
export { SimpleTableHead } from './SimpleTableHead';

export const SimpleTable = ({ headRows, rows, size, width, ...rest }) => {
  return (
    <Table size={size} {...rest} style={{ width: width, tableLayout: 'auto' }}>
      <SimpleTableHead headRows={headRows} />
      <TableBody>
        {rows.map((row, index) => (
          <SimpleTableRow key={`row_${index}`} cells={row} />
        ))}
      </TableBody>
    </Table>
  );
};

SimpleTable.propTypes = {
  headRows: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  size: PropTypes.string.isRequired,
  width: PropTypes.string,
};

SimpleTable.defaultProps = {
  headRows: [],
  rows: [],
  size: 'medium',
  width: 'auto',
};
