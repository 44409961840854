import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
  },
  monospace: {
    fontFamily: 'Monospace',
  },
}));

export const DeviceInfoTableCell = props => {
  const { siteId, deviceId, serialNumber, deviceName } = props;
  const classes = useStyles();
  const history = useHistory();
  const deviceRouteId = deviceId || serialNumber;

  const handleDeviceRoute = () => {
    history.push({
      pathname: `/systems/${siteId}/devices/${deviceRouteId}/summary`,
    });
  };

  return (
    <Fragment>
      <div>
        <Link className={classes.link} onClick={handleDeviceRoute}>
          {deviceName}
        </Link>
      </div>
      {deviceId && (
        <Typography variant="caption" component="div" className={classes.monospace}>
          {`RCPn: ${deviceId}`}
        </Typography>
      )}
      {serialNumber && (
        <Typography variant="caption" component="div" className={classes.monospace}>
          {`Serial: ${serialNumber}`}
        </Typography>
      )}
    </Fragment>
  );
};

DeviceInfoTableCell.propTypes = {
  deviceId: PropTypes.string,
  serialNumber: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
};
