import { ChartStyle, ChartAxisType } from 'types/Chart';
import { DataUnit } from 'types/DataUnit';
import { valueToHexString, kilowattSecondsToHours, formatTimestamp } from 'utils';

interface Definition {
  type: string;
  symbol?: string;
  chartStyle?: ChartStyle;
  chartAxisType?: ChartAxisType;
  prepareData?(data: number[]): number[];
  formatValue?(value: number): number | string | null;
}

interface ExcelDefinition extends Definition {
  excelFormula(cell: number | string): number | string;
  excelFormat: string;
}

const DataUnitDefinitions: Record<DataUnit, Definition | ExcelDefinition> = {
  [DataUnit.BITFIELD]: {
    type: 'Bitfield16',
    formatValue: (value) => {
      return valueToHexString(value);
    },
  },
  [DataUnit.CATEGORY]: {
    type: 'Category',
    chartAxisType: ChartAxisType.CATEGORY,
  },
  [DataUnit.CHARGE_AMP_HOURS]: {
    type: 'Charge',
    symbol: 'Ah',
    formatValue: (value) => {
      return Math.round(value * 1e2) / 1e2;
    },
  },
  [DataUnit.CHARGE_STATE]: {
    type: 'Charge State',
    symbol: '%',
    formatValue: (value) => {
      return Math.round(value * 1e2) / 1e2;
    },
  },
  [DataUnit.CURRENT_AMPS]: {
    type: 'Current',
    symbol: 'A',
    formatValue: (value) => {
      // @note Device Events/Updates Current needs to be divided by 100
      // return fixToDecimal((value / 100), 2)
      // @note Device DCB Module Data is true current
      return value;
    },
  },
  [DataUnit.ENERGY_KILOWATT_HOURS]: {
    type: 'Energy',
    symbol: 'kWh',
    chartStyle: ChartStyle.BAR,
    prepareData: (data) => {
      return data.map((value, index) => {
        let nextValue = value;
        if (index < data.length - 1) {
          nextValue = data[index + 1];
        }
        return nextValue - value;
      });
    },
    formatValue: (value) => {
      return kilowattSecondsToHours(value, 1e2);
    },
    excelFormula: (cell) => {
      return `${cell}/3.6e6`;
    },
    excelFormat: '#,##0.000',
  },
  [DataUnit.ENERGY_WATT_HOURS]: {
    type: 'Energy',
    symbol: 'Wh',
    formatValue: (value) => {
      return value;
    },
  },
  [DataUnit.OTHER_DATA]: {
    type: 'OtherData',
    formatValue: (value) => {
      return valueToHexString(value);
    },
  },
  [DataUnit.POWER_WATTS]: {
    type: 'Power',
    symbol: 'W',
    formatValue: (value) => {
      return Math.round(value * 1e2) / 1e2;
    },
  },
  [DataUnit.TEMPERATURE_CELSIUS]: {
    type: 'Temperature',
    symbol: 'C',
    formatValue: (value) => {
      // @note Device Events/Updates Temperature needs to be divided by 10
      // return fixToDecimal((value / 10), 1)
      // @note Device DCB Module Data is true temp
      return value;
    },
  },
  [DataUnit.TIME]: {
    type: 'Time',
    symbol: 'UTC',
    formatValue: (value) => {
      return formatTimestamp(value * 1000);
    },
    excelFormula: (cell) => {
      return `${cell}/86400+date(1970,1,1)`;
    },
    excelFormat: 'mm-dd-yyyy hh:mm:ss',
  },
  [DataUnit.VOLTAGE]: {
    type: 'Voltage',
    symbol: 'V',
    formatValue: (value) => {
      // @note Device Events/Updates Voltage needs to be divided by 10
      // return fixToDecimal((value / 10), 1)
      // @note Device DCB Module Data is true voltage
      return value;
    },
  },
};

export default DataUnitDefinitions;
