import React from 'react';
import { RouteConfig } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import { Error, Public, Tools } from 'layouts';
import Login from 'views/Login';
import LoginUnauthorized from 'views/LoginUnauthorized';
import Logout from 'views/Logout';
import Error401 from 'views/Error401';
import Error404 from 'views/Error404';
import Error500 from 'views/Error500';

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/systems" />, // @todo create a main dashboard
  },
  {
    path: '/sites',
    exact: true,
    component: () => <Redirect to="/systems" />, // redirecting old "site" route to new one -> "systems"
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
  },
  {
    path: '/auth',
    component: Public,
    routes: [
      {
        path: '/auth/login',
        component: Login,
      },
      {
        path: '/auth/unauthorized',
        exact: true,
        component: LoginUnauthorized,
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/errors',
    component: Error,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: Error401,
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: Error404,
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: Error500,
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '*',
    component: Tools,
  },
];

export default routes;
