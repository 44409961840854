import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { SimpleTable } from 'components';
import SetpointValuesTooltip from './SetpointValuesTooltip';
import SetpointDefaultValuesTooltip from './SetpointDefaultValuesTooltip';
import { BRIGHT_RED } from 'theme';

const useStyles = makeStyles(theme => ({
  tableInner: {
    minWidth: 750,
  },
  errorIcon: {
    color: BRIGHT_RED,
    marginTop: '5px',
  },
}));

const headCols = [
  { id: 'index', numeric: true, alignLeft: true, label: 'Index' },
  { id: 'label', numeric: false, alignLeft: true, label: 'Label' },
  { id: 'error', numeric: false, alignLeft: true, label: '' },
  { id: 'values', numeric: true, alignLeft: true, label: 'Value Updates' },
  { id: 'default', numeric: true, alignLeft: true, label: 'Default' },
];

function SetpointEditsTable(props) {
  const { setpoints, type } = props;
  const classes = useStyles();

  const rows = setpoints.map(spt => {
    const errorContent = !spt.isInBounds ? (
      <Tooltip
        placement="top"
        title={`Value must be between ${spt.get('min')} and ${spt.get('max')}`}
      >
        <ErrorIcon fontSize="small" className={classes.errorIcon} />
      </Tooltip>
    ) : null;

    return [
      { content: spt.get('index') },
      { content: spt.get('label') },
      { content: errorContent },
      { content: <SetpointValuesTooltip setpoint={spt} type={type} /> },
      { content: <SetpointDefaultValuesTooltip defaultValue={spt.get('default')} /> },
    ];
  });

  return (
    <Fragment>
      <PerfectScrollbar>
        <div className={classes.tableInner}>
          <SimpleTable size="small" headRows={headCols} rows={rows} />
        </div>
      </PerfectScrollbar>
    </Fragment>
  );
}

SetpointEditsTable.propTypes = {
  // -- passed props
  setpoints: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['edit', 'mask']).isRequired,
};

SetpointEditsTable.defaultProps = {
  setpoints: [],
  type: 'edit',
};

export default SetpointEditsTable;
