/**
 * @see https://material-ui.com/components/tables/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';

export const SimpleTableHead = ({ headRows }) => {
  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            style={row.style ? row.style : null}
            key={row.id}
            align={row.alignLeft === false ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SimpleTableHead.propTypes = {
  headRows: PropTypes.array.isRequired,
};
