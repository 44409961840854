export enum ChartType {
  SITE_METRICS,
  DEVICE_UPDATES,
  DEVICE_EVENTS,
  DCB_DATA,
}

// @see https://github.com/reactchartjs/react-chartjs-2
export enum ChartStyle {
  BAR = 'bar',
  LINE = 'line',
}

// @see https://github.com/reactchartjs/react-chartjs-2
export enum ChartAxisType {
  CATEGORY = 'category',
  LINEAR = 'linear',
}
