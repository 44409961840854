import { UserModel } from 'models';
import { endpoints } from 'api';

const initialState = {
  isLoggedIn: false,
  userRole: null, // @see role-config.js
  user: new UserModel(),
  isFetching: false,
  fetchError: null,
};

const RESET = 'SESSION_RESET';
const REQUESTED = 'SESSION_REQUESTED';
const CREATED = 'SESSION_CREATED';
const FAILED = 'SESSION_FAILED';

// receive can take FETCHED, CREATED, DESTROYED, REPLACED actions
const receive = (action, fields) => ({ type: action, payload: fields });
// const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED });
const fail = (error) => ({ type: FAILED, payload: error });

// --

const getSession = (apiClient) => {
  return (dispatch, getState) => {
    dispatch(request());

    // console.log('[session] getSession')

    apiClient
      .get({ path: endpoints.proxy.session })
      .then((response) => {
        // console.log('[session] getSession response', response)
        dispatch(
          receive(CREATED, {
            isLoggedIn: true,
            userRole: response.role,
            // --
            user: new UserModel({
              // role: null,
              generac_user_id: null,
              email: response.email,
              first_name: null,
              last_name: null,
              name: response.name,
              microsoft_id: null,
            }),
          })
        );
      })
      .catch((error) => {
        // console.log('[session] getSession error')
        // console.error(error)
        dispatch(fail(error.message));
      });
  };
};

const ACTION_HANDLERS = {
  [RESET]: (state, action) => initialState,
  [REQUESTED]: (state, action) => ({
    ...state,
    isFetching: true,
    fetchError: null,
  }),
  [CREATED]: (state, { payload }) => ({
    ...state,
    ...payload,
    isFetching: false,
    fetchError: null,
  }),
  [FAILED]: (state, { payload }) => ({
    ...state,
    fetchError: payload,
    isFetching: false,
  }),
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export const actions = {
  getSession,
};
