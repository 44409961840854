import { Model } from './Model';

export class ContactModel extends Model {
  constructor(fields = {}) {
    const defaultFields = {
      email: null,
      fname: null,
      lname: null,
      address_1: null,
      address_2: null,
      address_3: null,
      city: null,
      zip_or_postcode: null,
      country: null,
      timezone: null,
      phone: null,
      state_province_county: null,
      company: null,
    };

    super(Object.assign(defaultFields, fields));

    this.fieldMap = {
      email: 'Email',
      fname: 'First Name',
      lname: 'Last Name',
      address_1: 'Address',
      address_2: 'Address Line 2',
      address_3: 'Address Line 3',
      city: 'City',
      zip_or_postcode: 'Zipcode/Postcode',
      country: 'Country',
      timezone: 'Timezone',
      phone: 'Phone',
      state_province_county: 'State/Province/County',
      company: 'Company',
    };
  }
}
