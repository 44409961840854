import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, Typography, Button } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { Page } from 'components';
import gradients from 'utils/gradients';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: '100%',
    overflow: 'unset',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundImage: gradients.grey,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  hiddenLoginButton: {
    display: 'none',
  },
}));

const LoginUnauthorized = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Unauthorized Login">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <BlockIcon className={classes.icon} />
          <Typography gutterBottom variant="h3">
            User is Unauthorized
          </Typography>
          <div>
            <Box my={3}>
              <Typography>Talk to your manager to get access.</Typography>
            </Box>
            <Button color="primary" variant="contained" component={RouterLink} to="/auth/login">
              Back to Sign In
            </Button>
          </div>
        </CardContent>
      </Card>
    </Page>
  );
};

LoginUnauthorized.propTypes = {
  // --
};

export default LoginUnauthorized;
