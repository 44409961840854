import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import {
  Typography,
  Button,
  Box,
  Divider,
  Badge,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { BadgeTabs, BadgeTab } from 'components';
import SetpointHeader from './SetpointHeader';
import SetpointTable from './SetpointTable';
import SetpointReviewer from './SetpointReviewer';
import { DeviceMapModel, LiveDevicesModel, DeviceSetpointCollection } from 'models';
import { BRIGHT_BLUE } from 'theme';

const useStyles = makeStyles(theme => ({
  badge: {
    top: -10,
    right: -10,
    backgroundColor: deepOrange[100],
  },
  nonDefaultDot: {
    color: deepOrange[100],
  },
}));

export const SetpointEditor = props => {
  const { deviceMapModel, liveDevicesModel, setpointCollection, isEditing, onCreatePutJob } = props;

  const classes = useStyles();

  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const [valuesFilterEnabled, setValuesFilter] = useState(false);
  const [tableResets, setTableResets] = useState([
    // {
    // 	tableNumber: 0,
    // 	resetIndexes: []
    // }
  ]);
  const [editCollection, setEditCollection] = useState(new DeviceSetpointCollection());
  const [isReviewing, setIsReviewing] = useState(false);

  // --

  const nonDefaultValuesMap = setpointCollection.nonDefaultValuesMap;

  function handleSetpointEdit(event, setpointModel) {
    // creating a new temporary model to access
    // formatting for signed/unsigned/hex vals
    let editedModel = setpointModel.clone();
    editedModel[event.target.name] = event.target.value;
    // check against the setpointCollection
    // if the 'value' in the editedModel === 'value' in the original
    // remove it from the edits collection
    const originalModel = setpointCollection.findById(editedModel.id());
    if (editedModel.get('value') === originalModel.get('value')) {
      // remove from edits collection
      setEditCollection(editCollection.removeModel(editedModel.id()));
    } else {
      // add model to collection
      setEditCollection(editCollection.updateOrAddModel(editedModel));
    }
  }

  function handleDiscardEdit(setpointModel) {
    setEditCollection(editCollection.removeModel(setpointModel.id()));
  }

  function handleTableResetChange(tableNumber) {
    // check for existing table reset data in tableResets
    const tableResetExists = tableResets.find(tr => tr.tableNumber === tableNumber);

    if (tableResetExists) {
      // clear the table from the tableResets data
      setTableResets(tableResets.filter(tr => tr.tableNumber !== tableNumber));
    } else {
      // add this table to tableResets data
      // @note Index 0 is always skipped because it is uneditable
      // @note Only Setpoints that do not have a currently pending edit will be added for reset
      const tableSetpointsCollection = setpointCollection.filter(model => {
        if (model.get('table') !== tableNumber || model.get('index') === 0) {
          return false;
        }

        // check if the setpoint has a pending Edit
        // if it does, do not add it to the reset indexes array
        const editModel = editCollection.findById(model.id());

        if (editModel) {
          return false;
        }

        return true;
      });
      const indexes = tableSetpointsCollection.models.map(setpoint => setpoint.get('index'));

      setTableResets([
        ...tableResets,
        {
          tableNumber,
          indexes,
        },
      ]);
    }
  }

  function handleSetpointResetChange(setpointModel, isSelected) {
    const tableNumber = setpointModel.get('table');
    const index = setpointModel.get('index');
    const tableResetsCopy = [...tableResets];
    const tableReset = tableResetsCopy.find(tr => tr.tableNumber === tableNumber);

    if (!tableReset) {
      return;
    }

    let tableIndexesCopy = [...tableReset.indexes];
    let setpointIndex = tableIndexesCopy.indexOf(index);

    // if the setpoint has been selected for reset, add it to the reset indexes array
    // if the setpoint is unselected, remove it
    if (isSelected) {
      // check if this setpoint index already exists in the array
      if (setpointIndex > -1) {
        return;
      } else {
        tableIndexesCopy.push(index);
      }
    } else {
      tableIndexesCopy = tableIndexesCopy.filter(i => i !== index);
    }

    const tableResetIndex = tableResetsCopy.indexOf(tableReset);
    tableResetsCopy.splice(tableResetIndex, 1);

    // check if tableIndexesCopy is now empty and remove the entire
    // table's tableReset from the tableResets array
    if (!tableIndexesCopy.length) {
      setTableResets([...tableResetsCopy]);
    } else {
      setTableResets([
        ...tableResetsCopy,
        {
          tableNumber,
          indexes: tableIndexesCopy,
        },
      ]);
    }
  }

  // --

  // filtering setpoints to selected Table tab
  const tableOptions = setpointCollection.tableOptions;
  const currentTableNumber = tableOptions[currentTableIndex];
  const currentTableResets = tableResets.find(tr => tr.tableNumber === currentTableNumber);
  const currentTableResetIndexes = currentTableResets ? currentTableResets.indexes : null;
  const canReviewEdits = editCollection.size() > 0 || !isEmpty(tableResets);

  let filteredSetpointCollection = setpointCollection.filter(
    model => model.get('table') === currentTableNumber
  );
  if (valuesFilterEnabled) {
    filteredSetpointCollection = filteredSetpointCollection.filter(model => {
      if (!model.isDefault) {
        return true;
      }
      return false;
    });
  }

  if (isReviewing) {
    return (
      <SetpointReviewer
        originalSetpointCollection={setpointCollection}
        editedSetpointCollection={editCollection}
        tableResets={tableResets}
        deviceMapModel={deviceMapModel}
        liveDevicesModel={liveDevicesModel}
        onCancelReview={() => {
          setIsReviewing(false);
        }}
        onCreatePutJob={onCreatePutJob}
      />
    );
  }

  return (
    <Fragment>
      {isEditing && (
        <SetpointHeader
          title="Editing Setpoints"
          subtitle="Changes will not be applied until after Reviewing and clicking Set"
          icon={<EditIcon />}
          iconColor={BRIGHT_BLUE}
          action={
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setIsReviewing(true)}
              disabled={!canReviewEdits}
            >
              Review Updates
              <ArrowForwardIcon className="space-left-smallest" />
            </Button>
          }
        />
      )}
      <Box mb={1}>
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <DotIcon className={classes.nonDefaultDot} />
            <Typography variant="body2">Non-default value count</Typography>
            {!isEditing && (
              <Fragment>
                <div className="flex-grow" />
                <FormControlLabel
                  control={
                    <Switch
                      checked={valuesFilterEnabled}
                      onChange={() => setValuesFilter(!valuesFilterEnabled)}
                      value="valueDiff"
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography variant="body2">
                      Limit results to Setpoints with non-default values
                    </Typography>
                  }
                />
              </Fragment>
            )}
          </Box>
        </Box>
        <Box mb={3}>
          <BadgeTabs
            variant="scrollable"
            value={currentTableIndex}
            onChange={(event, newValue) => setCurrentTableIndex(newValue)}
          >
            {setpointCollection.tableOptions.map(tableOption => {
              const badgeCount = nonDefaultValuesMap[tableOption];

              return (
                <BadgeTab
                  wrapped
                  key={`tab_${tableOption}`}
                  label={
                    <Badge
                      badgeContent={badgeCount}
                      classes={{ badge: classes.badge }}
                      invisible={Boolean(!badgeCount)}
                    >
                      Table {tableOption}
                    </Badge>
                  }
                />
              );
            })}
          </BadgeTabs>
        </Box>
      </Box>
      <Divider />
      <SetpointTable
        tableNumber={currentTableNumber}
        isEditing={isEditing}
        resetIndexes={currentTableResetIndexes}
        setpointCollection={filteredSetpointCollection}
        editCollection={editCollection}
        onSetpointEdit={handleSetpointEdit}
        onSetpointDiscardEdit={handleDiscardEdit}
        onTableResetChange={handleTableResetChange}
        onSetpointResetChange={handleSetpointResetChange}
      />
    </Fragment>
  );
};

SetpointEditor.propTypes = {
  // -- passed props
  deviceMapModel: PropTypes.instanceOf(DeviceMapModel).isRequired,
  liveDevicesModel: PropTypes.instanceOf(LiveDevicesModel).isRequired,
  setpointCollection: PropTypes.instanceOf(DeviceSetpointCollection).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onCreatePutJob: PropTypes.func,
};

SetpointEditor.defaultProps = {
  deviceMapModel: new DeviceMapModel(),
  liveDevicesModel: new LiveDevicesModel(),
  isEditing: false,
};
